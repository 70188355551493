import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useLazyEmployeeAuditQuery } from "../../api/loginApi";
import duration from "dayjs/plugin/duration";
import { useNavigate} from "react-router-dom";
import { getToken } from "../../services/Token";



const EmployeeTracking = () => {
  const [currentdate, setCurrentDate] = useState(dayjs());
  const navigate = useNavigate();
  const empId = getToken("Id");
  const [
    employeeAudit,
    {
      data: employeeAuditData
    },
  ] = useLazyEmployeeAuditQuery({});

  useEffect(() => {
    const formatted = currentdate.toISOString();
    employeeAudit(formatted);
  }, []);
  const handleDateChange = (date) => {
    const dateTime = date;
    setCurrentDate(dateTime);
    const formatted = dateTime.toISOString();
    employeeAudit(formatted);
  };
  dayjs.extend(duration);
  const formatDate = (dateString) => {
    const date = dayjs(dateString);
    const updatedDate = date.add(dayjs.duration({ hours: 5, minutes: 30 }));

    const options = {
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    };

    return updatedDate.toDate().toLocaleString("en-US", options);
  };
  useEffect(()=>{
    localStorage.setItem('breadCrumb',false);
  },[])

  return (
    <section className="mt-28 w-11/12 mx-auto">
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          label="Date"
          value={currentdate}
          onChange={(date) => handleDateChange(date)}
          maxDate={dayjs()}
        />
      </LocalizationProvider>
      <div className="flex space-x-2 my-4">
  <div className="flex-1 min-w-0">
    <table className="w-full divide-y divide-gray-200 border">
      <thead className="bg-gray-50">
        <tr>
          <th className="px-4 py-3 text-left font-bold text-blue-500 uppercase tracking-wider">
            SIGNED IN ({employeeAuditData?.data?.signedin?.length})
          </th>
          <th className="px-4 py-3 text-left font-bold text-blue-500 uppercase tracking-wider">
            Signed In Time
          </th>
        </tr>
      </thead>
      <tbody className="bg-white divide-y divide-gray-200">
        {employeeAuditData?.data?.signedin?.length > 0 ? (
          employeeAuditData.data.signedin.map((item) => (
            <tr key={item?.employeeId}>
              {empId !== item?.employeeId ?
              <button  onClick={()=>{
               navigate(`/edit/${item?.employeeId}`);
               localStorage.setItem('breadCrumb',true);
              }}>
              <td className="px-4 py-2 whitespace-nowrap text-black font-semibold align-top">
                {item.name ?? "--"}
              </td>
              </button>: <td className="px-4 py-2 whitespace-nowrap text-black font-semibold align-top">
                {item.name +"(ADMIN)" ?? "--"}
              </td>}
              <td className="px-4 py-2 whitespace-nowrap text-gray-500 font-semibold align-top">
                {formatDate(item.signintime) ?? "--"}
              </td>

            </tr>
          ))
        ) : (
          <tr>
            <td
              colSpan="2"
              className="px-4 py-2 text-center text-black font-semibold align-top"
            >
              0 employees
            </td>
          </tr>
        )}
      </tbody>
    </table>
  </div>
  <div className="flex-1 min-w-0">
    <table className="w-full divide-y divide-gray-200 border">
      <thead className="bg-gray-50">
        <tr>
          <th className="px-4 py-3 text-left font-bold text-blue-500 uppercase tracking-wider">
            NOT YET IN ({employeeAuditData?.data?.notyetin?.length})
          </th>
        </tr>
      </thead>
      <tbody className="bg-white divide-y divide-gray-200">
        {employeeAuditData?.data?.notyetin?.length > 0 ? (
          employeeAuditData?.data?.notyetin?.map((item) => (
            <tr key={item?.employeeId}>
              <button  onClick={()=>{
               navigate(`/edit/${item?.employeeId}`);
               localStorage.setItem('breadCrumb',true);
              }}>
              <td className="px-4 py-2 whitespace-nowrap text-black font-medium align-top">
                {item.name}
              </td>
              </button>
            </tr>
          ))
        ) : (
          <tr>
            <td
              colSpan="2"
              className="px-4 py-2 text-center text-black font-semibold align-top"
            >
              0 employees
            </td>
          </tr>
        )}
      </tbody>
    </table>
  </div>
</div>

    </section>
  );
};

export default EmployeeTracking;
