import api from "../services/index";

const employeeLeaves = api.injectEndpoints({
  endpoints: (builder) => ({
    getEmployeeLeaves: builder.query({
      query: (params) => ({
        url: `/api/employeeLeave/getEmployeeLeaves`,
        method: "get",
        params,
      }),
    }),
    getAllEmployeeLeaves: builder.query({
      query: (params) => ({
        url: `/api/employeeLeave/getAllEmployeeLeaves`,
        method: "get",
        params,
      }),
    }),
    getEmployeeLeaveInfo : builder.query({
      query: (params) => ({
        url: `/api/leaveTransaction/getEmployeeLeaveInfo`,
        method: "get",
        params,
      })
    })
  }),
});

export default employeeLeaves;
export const { useGetEmployeeLeavesQuery, useGetAllEmployeeLeavesQuery, useLazyGetEmployeeLeaveInfoQuery } = employeeLeaves;
