import React, { useCallback, useRef, useState, useEffect } from "react";
import avatar from "../assets/avatar-default.svg";
import Button from "@mui/material/Button";
import {
  TextField,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  Alert,
  Snackbar,
} from "@mui/material";
import { useDropzone } from "react-dropzone";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import axios from "axios";
import { serviceUrl, genericHeaders } from "../utils/apiConfig";
import { upload_file_to_s3 } from "../utils";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "../css/countrycodedropdown.css";

function CustomPhoneInput({
  name,
  value,
  onPhoneInputChange,
  customPlaceholder,
}) {
  return (
    <PhoneInput
      containerClass="w-full"
      inputClass="border border-gray-300 rounded-2 p-2 focus:outline-none focus:border-blue-500 w-[381px] h-[41px]"
      // country={"in"}
      value={value}
      onChange={(value) => onPhoneInputChange(value, name)}
      placeholder={customPlaceholder}
    />
  );
}

const AddEmployee = () => {
  const form = {
    get_id: "",
    companyEmail: "",
    employeeId: "",
    workLocation: "",
    isAcceptTermsandConditions: true,
    profilePhoto: {
      fileName: "",
      name: "",
      resourceName: "",
      uploadDate: "",
      comment: "",
      extension: "",
    },
    department: "",
    currentDesignation: "",
    holidayCalenderUrl: "",
    personalDetails: {
      firstName: "",
      lastName: "",
      gender: "",
      maritalStatus: "",
      primaryContactNo: "",
      emergencyContactNo: "",
      email: "",
      nationality: "",
      address: "",
      city: "",
      state: "",
      country: "",
      zipCode: "",
      additionalInformation: {
        additionalProp1: {},
        additionalProp2: {},
        additionalProp3: {},
      },
      dob: "",
    },
    workExperience:  [
      {
        "companyName": "",
        "designation": "",
        "startDate": "",
        "endDate": "",
        "documents": [
          {
            "fileName": "",
            "name": "",
            "resourceName": "",
            "uploadDate": "",
            "comment": "",
            "extension": ""
          }
        ]
      }
    ],
    academicDetails: {
      schoolName: "",
      result: "",
      fromDate: "",
      toDate: "",
      documents: [
        {
          fileName: "",
          name: "",
          resourceName: "",
          uploadDate: "",
          comment: "",
          extension: "",
        },
      ],
      additionalInfo: {
        additionalProp1: {},
        additionalProp2: {},
        additionalProp3: {},
      },
    },
    offerLetter: {
      fileName: "",
      name: "",
      resourceName: "",
      uploadDate: "",
      comment: "",
      extension: "",
    },
    managerIds: [],
    paySlips: [
      {
        fileName: "",
        name: "",
        resourceName: "",
        uploadDate: "",
        comment: "",
        extension: "",
      },
    ],
    documents: [
      {
        fileName: "",
        name: "",
        resourceName: "",
        uploadDate: "",
        comment: "",
        extension: "",
      },
    ],
    bankDetails: [
      {
        bankName: "",
        isActive: true,
        accountNo: "",
        branchName: "",
        ifsccode: "",
      },
    ],
    additionalInfo: {
      additionalProp1: {},
      additionalProp2: {},
      additionalProp3: {},
    },
    employeeType: "",
    holidayCalenderName: "",
    managersMap: {
      additionalProp1: "",
      additionalProp2: "",
      additionalProp3: "",
    },
  };
  const avatarRef = useRef(null);
  const [image, setImage] = useState(null);
  const [formData, setFormData] = useState(form);
  const [workDocuments, setWorkDocuments] = useState([]);
  const [academicDocuments, setAcademicDocuments] = useState([]);
  const [offerLetter, setOfferLetter] = useState([]);
  const [locations, setLocations] = useState([]);
  const [empId, setempId] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [managers, setManagers] = useState([]);
  const [designations, setDesignations] = useState({});
  const [designationsData, setDesignationsData] = useState(null);
  const [holidayCalender, setHolidayCalender] = useState();
  const [snack, setSnack] = useState(false);
  const [employeeTypes, setEmployeeTypes] = useState([]);
  const [workDocsUpload, setWorkDocsUpload] = useState([]);
  const [offerLetterUpload, setOfferLetterUpload] = useState([]);
  const [profileImage, setProfileImage] = useState(form.profilePhoto);
  const [error,setError] = useState({
    email : false,
  })

  const getLocationData = async () => {
    try {
      let response = await axios.get(
        `${serviceUrl}/api/workLocation/getAllWorkLocations`,
        { headers: genericHeaders() }
      );
      if (response.status === 200) {
        let newData = response.data;
        newData = newData.filter( (item) => item.isActive === true)
        setLocations(newData);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const getDepartmentData = async () => {
    try {
      let response = await axios.get(`${serviceUrl}/api/department/getAll`, {
        headers: genericHeaders(),
      });
      if (response.status === 200) {
        let newData = response.data;
        newData = newData.filter( (item) => item.isActive === true)
        setDepartments(newData);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const getLatestEmpId = async () => {
    try {
      let response = await axios.get(`${serviceUrl}/api/employee/getLatestId`, {
        headers: genericHeaders(),
      });
      if (response.status === 200) {
        // Need to refine the logic
        setempId([response.data]);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const getManagersData = async () => {
    try {
      let response = await axios.get(
        `${serviceUrl}/api/employee/getEmployeesList`,
        { headers: genericHeaders() }
      );
      if (response.status === 200) {
        setManagers(response.data);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const getDesignationData = async () => {
    try {
      let designations = await axios.get(
        `${serviceUrl}/api/designation/getAllDesignations`,
        { headers: genericHeaders() }
      );
      if (designations.status === 200) {
        setDesignations(designations.data);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const getHolidayCalender = async () => {
    try {
      let response = await axios.get(
        `${serviceUrl}/api/holidayCalender/getAllHolidayCalenderMap`,
        { headers: genericHeaders() }
      );
      if (response.status === 200) {
        setHolidayCalender(response.data);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const getEmployeeCategory = async () => {
    try {
      let response = await axios.get(
        `${serviceUrl}/api/employeeType/getAllEmployeeTypes`,
        { headers: genericHeaders() }
      );
      if (response.status === 200) {
        let newData = response.data;
        newData = newData.filter( (item) => item.isActive === true)
        setEmployeeTypes(newData);
      }
    } catch (err) {
      console.error(err);
    }
  };
  useEffect(() => {
    getLatestEmpId();
    getLocationData();
    getManagersData();
    getDepartmentData();
    getDesignationData();
    getHolidayCalender();
    getEmployeeCategory();
  }, []);
  const handle_file_upload = async (new_file, type) => {
    const file_to_upload = new_file;
    let public_url;
    let document;
    const maxSizeInBytes = 10 * 1024 * 1024; // max 10mb

    if (file_to_upload?.size > maxSizeInBytes) {
      return
    }
    
    try {
      const s3path = await upload_file_to_s3(new_file);
      public_url = s3path.location;
    } catch (err) {
      console.error(err);
    }
    if (type === "work") {
      document = {
        fileName: file_to_upload.name,
        name: "WorkDocuments", // You can set the name as needed
        resourceName: public_url, // You can set the file url as needed
        uploadDate: new Date().toISOString(),
        comment: "",
        extension: file_to_upload.name.split(".").pop(), // Extract file extension
      };
    } else if (type === "offer") {
      document = {
        fileName: file_to_upload.name,
        name: "OfferLetter", // You can set the name as needed
        resourceName: public_url, // You can set the file url as needed
        uploadDate: new Date().toISOString(),
        comment: "",
        extension: file_to_upload.name.split(".").pop(),
      };
    } else if (type === "profile") {
      document = {
        fileName: file_to_upload.name,
        name: "Profile Photo", // You can set the name as needed
        resourceName: public_url, // You can set the file url as needed
        uploadDate: new Date().toISOString(),
        comment: "",
        extension: file_to_upload.name.split(".").pop(),
      };
    }
    return document;
  };
  const onDrop1 = useCallback((files) => {
    files.forEach(async (file) => {
      let newData = await handle_file_upload(file, "work");
      setWorkDocsUpload((data) => [...data, newData]);
      setWorkDocuments((prevFile) => [...prevFile, file]);
    });
  }, []);
  const onDrop2 = useCallback((files) => {
    files.forEach((file) => {
      setAcademicDocuments((prevFile) => [...prevFile, file]);
    });
  }, []);
  const onDrop3 = useCallback((files) => {
    files.forEach(async (file) => {
      let newData = await handle_file_upload(file, "offer");
      setOfferLetterUpload((data) => [...data, newData]);
      setOfferLetter((prevFile) => [...prevFile, file]);
    });
  }, []);

  const { getRootProps, getInputProps } = useDropzone({ onDrop: onDrop1 });
  const { getRootProps: getRootProps2, getInputProps: getInputProps2 } =
    useDropzone({ onDrop: onDrop2 });
  const { getRootProps: getRootProps3, getInputProps: getInputProps3 } =
    useDropzone({ onDrop: onDrop3, maxFiles: 1 });

  const handleAvatar = () => {
    avatarRef.current.click();
  };

  const handleProfileChange = async (evt) => {
    const file = evt.target.files[0];
    setImage(file);
    let profile = await handle_file_upload(file, "profile");
    setProfileImage(profile);
  };
  const handleFileDelete = (index, name) => {
    switch (name) {
      case "work":
        let workArr = [...workDocuments];
        let workDocsArr = [...workDocsUpload];
        let removedElement = workArr.splice(index, 1);
        let matchedIndex = workDocsArr.findIndex(
          (item) => removedElement.name === item.fileName
        );
        workDocsArr.splice(matchedIndex, 1);
        setWorkDocsUpload(workDocsArr);
        setWorkDocuments(workArr);
        break;
      case "academic":
        let academicArr = [...academicDocuments];
        academicArr.splice(index, 1);
        setAcademicDocuments(academicArr);
        break;
      case "offer":
        let offerArr = [...offerLetter];
        let offerLetterArr = [...offerLetterUpload];
        let element = offerArr.splice(index, 1);
        let matched = offerLetterArr.findIndex(
          (item) => element.name === item.fileName
        );
        offerLetterArr.splice(matched, 1);
        setOfferLetterUpload(offerLetterArr);
        setOfferLetter(offerArr);
        break;
    }
  };

  const handleAccountChange = (evt) => {
    let data = { ...formData };
    if (evt.target.name === "department") {
      data[evt.target.name] = evt.target.value;
      setFormData(data);
      setDesignationsData(evt.target.value);
    } else if (evt.target.name === "managerIds") {
      data[evt.target.name] = [evt.target.value];
      setFormData(data);
    } else {
      data[evt.target.name] = evt.target.value;
      setFormData(data);
    }
  };

  const handlePhoneInputChange = (value, name) => {
    let data = { ...formData };
    data.personalDetails[name] = value;
    setFormData(data);
  };

  const handlePersonalData = (evt) => {
    let data = { ...formData };
    if (evt.target.name === "dob") {
      const date = new Date(evt.target.value).toISOString();
      data.personalDetails[evt.target.name] = date;
      setFormData(data);
    } else {
      data.personalDetails[evt.target.name] = evt.target.value;
      setFormData(data);
    }
  };

  const handleWorkData = (evt) => {
    let data = { ...formData };
    if (evt.target.name === "startDate" || evt.target.name === "endDate") {
      const date = new Date(evt.target.value).toISOString();
      data.workExperience[0][evt.target.name] = date;
      setFormData(data);
    } else {
      data.workExperience[0][evt.target.name] = evt.target.value;
      setFormData(data);
    }
  };

  const handleAcademicData = (evt) => {
    let data = { ...formData };
    if (evt.target.name === "fromDate" || evt.target.name === "toDate") {
      const date = new Date(evt.target.value).toISOString();
      data.academicDetails[evt.target.name] = date;
      setFormData(data);
    } else {
      data.academicDetails[evt.target.name] = evt.target.value;
      setFormData(data);
    }
  };

  const handleBankData = (evt) => {
    let data = { ...formData };
    data.bankDetails[0][evt.target.name] = evt.target.value;
    setFormData(data);
  };

  const handleSnackOpen = () => {
    setSnack(true);
  };
  const handleSnackClose = () => {
    setSnack(false);
  };

  const handleSubmit = async () => {
    let data = { ...formData };
    data.workExperience[0].documents = workDocsUpload;
    data.offerLetter = offerLetterUpload[0];
    data.profilePhoto = profileImage;
    try {
      let url = `${serviceUrl}/api/employee/create`;
      let response = await axios.post(url, data, { headers: genericHeaders() });
      if (response.status === 201) {
        handleSnackOpen();
        setFormData(form);
        setWorkDocsUpload([]);
        setWorkDocuments([]);
        setOfferLetter([]);
        setOfferLetterUpload([]);
        setProfileImage(form.profilePhoto);
      }
    } catch (err) {
      console.error(err);
    }
  };
  const handleEmail = (evt) => {
    const email = evt.target.value;
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    let isValid = emailRegex.test(email);
    if(isValid === false){
      setError({email : true,});
    }else {
      setError({email : false,});
    }
  }
  return (
    <div className="p-5 bg-slate-200">
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        open={snack}
        autoHideDuration={2000}
        onClose={handleSnackClose}
      >
        <Alert severity="success" variant="filled" onClose={handleSnackClose}>
          Employee Added Successfully
        </Alert>
      </Snackbar>
      <h2 className="text-3xl m-3">Add Employee</h2>
      <div className="bg-white rounded-3xl">
        <div className="border-b-4 mx-10">
          <div className="w-1/4 py-5 space-y-5 flex flex-col items-center justify-center">
            <input
              type="file"
              id="pro-img"
              ref={avatarRef}
              onChange={handleProfileChange}
              className="hidden"
            />
            {image ? (
              <img
                src={URL.createObjectURL(image)}
                className="h-36 w-36 border-4 border-black rounded-full"
              />
            ) : (
              <img
                src={avatar}
                className="h-36 w-36 border-4 border-black rounded-full"
              />
            )}
            <Button onClick={handleAvatar}>edit</Button>
          </div>
        </div>

        <div className="m-10 border-b-4">
          <h3 className="text-2xl my-5">Account Information</h3>
          <div className="grid grid-cols-2 gap-4 mb-10">
            <FormControl>
              <InputLabel htmlFor="Employee ID">Employee ID</InputLabel>
              <Select
                id="Employee ID"
                label="Employee ID"
                name="employeeId"
                onChange={handleAccountChange}
                value={formData.employeeId}
                required
              >
                {empId.map((item) => {
                  return <MenuItem value={item}>{item}</MenuItem>;
                })}
              </Select>
            </FormControl>
            <FormControl>
              <InputLabel htmlFor="workLocation">Work Location</InputLabel>
              <Select
                id="workLocation"
                label="Work Location"
                name="workLocation"
                onChange={handleAccountChange}
                value={formData.workLocation}
                required
              >
                {locations.map((item) => {
                  return <MenuItem value={item._id}>{item.location}</MenuItem>;
                })}
              </Select>
            </FormControl>
            <FormControl>
              <InputLabel htmlFor="Reporting Manager">
                Reporting Manager
              </InputLabel>
              <Select
                id="Reporting Manager"
                label="Reporting Manager"
                name="managerIds"
                onChange={handleAccountChange}
                value={formData.managerIds[0]}
                required
              >
                {managers.map((item) => {
                  return <MenuItem value={item.id}>{item.name}</MenuItem>;
                })}
              </Select>
            </FormControl>
            <FormControl>
              <InputLabel htmlFor="department">Department</InputLabel>
              <Select
                id="department"
                label="Department"
                name="department"
                onChange={handleAccountChange}
                value={formData.department}
                required
              >
                {departments.map((item) => {
                  return (
                    <MenuItem value={item._id}>{item.departmentName}</MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <FormControl>
              <InputLabel htmlFor="currentDesignation">
                Current Designation
              </InputLabel>
              <Select
                id="currentDesignation"
                label="Current Designation"
                name="currentDesignation"
                onChange={handleAccountChange}
                value={formData.currentDesignation}
                MenuProps={{ PaperProps: { style: { maxHeight: "20rem" } } }}
                required
              >
                {Object.entries(designations[designationsData] || {}).map(
                  ([label, id]) => {
                    return <MenuItem value={id}>{label}</MenuItem>;
                  }
                )}
              </Select>
            </FormControl>
            <FormControl>
              <InputLabel htmlFor="employeeType">Employee Type</InputLabel>
              <Select
                id="employeeType"
                label="Employee Type"
                name="employeeType"
                value={formData.employeeType}
                onChange={handleAccountChange}
                required
              >
                {employeeTypes.map((item) => {
                  return (
                    <MenuItem value={item._id}>
                      {item.employeeCategory}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <FormControl>
              <InputLabel htmlFor="holidayCalenderName">
                Holiday Calender
              </InputLabel>
              <Select
                id="holidayCalenderName"
                label="Employee Type"
                name="holidayCalenderName"
                value={formData.holidayCalenderName}
                onChange={handleAccountChange}
                required
              >
                {Object.entries(holidayCalender || {}).map(([label, id]) => {
                  return <MenuItem value={id}>{label}</MenuItem>;
                })}
              </Select>
            </FormControl>
          </div>
        </div>

        <div className="m-10 border-b-4">
          <h3 className="text-2xl my-5">Personal Information</h3>
          <div className="grid grid-cols-2 gap-4 mb-10">
            <TextField
              label="First Name"
              name="firstName"
              variant="outlined"
              onChange={handlePersonalData}
              value={formData.personalDetails.firstName}
              required
            />
            <TextField
              label="Last Name"
              name="lastName"
              variant="outlined"
              onChange={handlePersonalData}
              value={formData.personalDetails.lastName}
              required
            />
            <FormControl>
              <InputLabel htmlFor="gender">Gender</InputLabel>
              <Select
                id="gender"
                label="gender"
                name="gender"
                onChange={handlePersonalData}
                value={formData.personalDetails.gender}
                required
              >
                <MenuItem value={"Male"}>Male</MenuItem>
                <MenuItem value={"Female"}>Female</MenuItem>
                <MenuItem value={"Not to Disclose"}>Not to disclose</MenuItem>
              </Select>
            </FormControl>
            <TextField
              label="Date Of Birth"
              name="dob"
              variant="outlined"
              type="date"
              InputLabelProps={{ shrink: true }}
              value={formData.personalDetails.dob.split("T")[0]}
              onChange={handlePersonalData}
              required
            />

            <div className="w-full">
              <CustomPhoneInput
                name="primaryContactNo"
                value={formData.personalDetails.primaryContactNo}
                onPhoneInputChange={handlePhoneInputChange}
                customPlaceholder="Primary Contact Number *"
              />
            </div>

            <div className="w-full">
              <CustomPhoneInput
                name="emergencyContactNo"
                value={formData.personalDetails.emergencyContactNo}
                onPhoneInputChange={handlePhoneInputChange}
                customPlaceholder="Emergency Contact Number"
              />
            </div>
            <TextField
              label="Email"
              name="email"
              variant="outlined"
              value={formData.personalDetails.email}
              onChange={handlePersonalData}
              error={error.email}
              onBlur={handleEmail}
              helperText={error.email ? 'Invalid email address' : ''}
              required
            />
            <FormControl>
              <InputLabel htmlFor="maritalStatus">Marital Status</InputLabel>
              <Select
                id="maritalStatus"
                label="Marital Status"
                name="maritalStatus"
                value={formData.personalDetails.maritalStatus}
                onChange={handlePersonalData}
              >
                <MenuItem value={"Single"}>Single</MenuItem>
                <MenuItem value={"Married"}>Married</MenuItem>
                <MenuItem value={"Widowed"}>Widowed</MenuItem>
                <MenuItem value={"Separated"}>Separated</MenuItem>
                <MenuItem value={"Divorced"}>Divorced</MenuItem>
              </Select>
            </FormControl>
            <TextField
              label="Nationality"
              name="nationality"
              variant="outlined"
              value={formData.personalDetails.nationality}
              onChange={handlePersonalData}
            />
            <TextField
              label="Address"
              name="address"
              variant="outlined"
              value={formData.personalDetails.address}
              onChange={handlePersonalData}
            />
            <TextField
              label="City"
              name="city"
              variant="outlined"
              value={formData.personalDetails.city}
              onChange={handlePersonalData}
            />
            <TextField
              label="State"
              name="state"
              variant="outlined"
              value={formData.personalDetails.state}
              onChange={handlePersonalData}
            />
            <TextField
              label="Country"
              name="country"
              variant="outlined"
              value={formData.personalDetails.country}
              onChange={handlePersonalData}
            />
            <TextField
              label="Zip Code"
              name="zipCode"
              variant="outlined"
              value={formData.personalDetails.zipCode}
              onChange={handlePersonalData}
            />
          </div>
        </div>

        <div className="m-10 border-b-4">
          <h3 className="text-2xl my-5">Work Experience</h3>
          <div className="grid grid-cols-2 gap-4 mb-10">
            <TextField
              label="Company Name"
              name="companyName"
              variant="outlined"
              value={formData.workExperience[0].companyName}
              onChange={handleWorkData}
            />
            <TextField
              label="Designation"
              name="designation"
              variant="outlined"
              value={formData.workExperience[0].designation}
              onChange={handleWorkData}
            />
            <TextField
              label="Start Date"
              name="startDate"
              variant="outlined"
              onChange={handleWorkData}
              type="date"
              InputLabelProps={{ shrink: true }}
              value={formData.workExperience[0].startDate.split("T")[0]}
              required
            />
            <TextField
              label="End Date"
              name="endDate"
              variant="outlined"
              onChange={handleWorkData}
              type="date"
              InputLabelProps={{ shrink: true }}
              value={formData.workExperience[0].endDate.split("T")[0]}
              required
            />
          </div>
          <section className="w-full py-7 border-4 border-dashed flex flex-col items-center justify-center mb-10">
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              <AddIcon
                sx={{ fontSize: "5rem", display: "block", margin: "0 auto" }}
              />
              <p className="text-2xl">Upload Documents</p>
            </div>
            <aside className="flex flex-row flex-wrap fl mt-4">
              <ul className="text-2xl">
                {workDocuments.map((file, i) => (
                  <li key={file.path}>
                    <InsertDriveFileIcon />
                    {file.name}
                    <DeleteIcon
                      onClick={() => handleFileDelete(i, "work")}
                      sx={{ margin: "0 0 0 1rem" }}
                    />
                  </li>
                ))}
              </ul>
            </aside>
          </section>
        </div>

        <div className="m-10 border-b-4">
          <h3 className="text-2xl my-5">Academic Details</h3>
          <div className="grid grid-cols-2 gap-4 mb-10">
            <TextField
              label="College Name"
              name="schoolName"
              variant="outlined"
              value={formData.academicDetails.schoolName}
              onChange={handleAcademicData}
            />
            <TextField
              label="Result"
              name="result"
              variant="outlined"
              value={formData.academicDetails.result}
              onChange={handleAcademicData}
            />
            <TextField
              label="From Date"
              name="fromDate"
              variant="outlined"
              onChange={handleAcademicData}
              type="date"
              InputLabelProps={{ shrink: true }}
              value={formData.academicDetails.fromDate.split("T")[0]}
              required
            />
            <TextField
              label="To Date"
              name="toDate"
              variant="outlined"
              onChange={handleAcademicData}
              type="date"
              InputLabelProps={{ shrink: true }}
              value={formData.academicDetails.toDate.split("T")[0]}
              required
            />
          </div>
          {/* <section className="w-full py-7 border-4 border-dashed flex flex-col items-center justify-center mb-10">
            <div {...getRootProps2()}>
              <input {...getInputProps2()} />
              <AddIcon  sx={{fontSize: "5rem", display:"block", margin: "0 auto"}}/>
              <p className="text-2xl">Upload Documents</p>
            </div>
            <aside className="flex flex-row flex-wrap fl mt-4">
              <ul className="text-2xl">
                {
                  academicDocuments.map((file,i) => (
                    <li key={file.path}>
                      <InsertDriveFileIcon/>
                      {file.name}
                      <DeleteIcon onClick={() => handleFileDelete(i,"academic")}  sx={{ margin:"0 0 0 1rem" }}/>
                    </li>
                  ))
                }
              </ul>
            </aside>
          </section> */}
        </div>

        <div className="m-10 border-b-4">
          <div>
            <h3 className="text-2xl my-5">Previous OfferLetter</h3>
            <section className="w-full py-7 border-4 border-dashed flex flex-col items-center justify-center mb-10">
              <div {...getRootProps3()}>
                <input {...getInputProps3()} />
                <AddIcon
                  sx={{ fontSize: "5rem", display: "block", margin: "0 auto" }}
                />
                <p className="text-2xl">Upload Documents</p>
              </div>
              <aside className="flex flex-row flex-wrap fl mt-4">
                <ul className="text-2xl">
                  {offerLetter.map((file, i) => (
                    <li key={file.path}>
                      <InsertDriveFileIcon />
                      {file.name}
                      <DeleteIcon
                        onClick={() => handleFileDelete(i, "offer")}
                        sx={{ margin: "0 0 0 1rem" }}
                      />
                    </li>
                  ))}
                </ul>
              </aside>
            </section>
          </div>
        </div>

        <div className="m-10 pb-1">
          <h3 className="text-2xl my-5">Bank Details</h3>
          <div className="grid grid-cols-2 gap-4 mb-10">
            <TextField
              label="Bank Name"
              name="bankName"
              variant="outlined"
              onChange={handleBankData}
              value={formData.bankDetails[0].bankName}
              required
            />
            <TextField
              label="Branch Name"
              name="branchName"
              variant="outlined"
              onChange={handleBankData}
              value={formData.bankDetails[0].branchName}
              required
            />
            <TextField
              label="Account Number"
              name="accountNo"
              variant="outlined"
              onChange={handleBankData}
              value={formData.bankDetails[0].accountNo}
              required
            />
            <TextField
              label="Account IFSC code"
              name="ifsccode"
              variant="outlined"
              onChange={handleBankData}
              value={formData.bankDetails[0].ifsccode}
              required
            />
          </div>
        </div>

        <div className="flex justify-end mx-5 p-5">
          <Button
            onClick={handleSubmit}
            size="large"
            variant="contained"
            disabled={
              !formData.employeeId ||
              !formData.workLocation ||
              !formData.department ||
              !formData.currentDesignation ||
              !formData.personalDetails.firstName ||
              !formData.personalDetails.lastName ||
              !formData.personalDetails.primaryContactNo ||
              !formData.personalDetails.email ||
              !formData.bankDetails[0].bankName ||
              !formData.bankDetails[0].branchName ||
              !formData.bankDetails[0].accountNo ||
              !formData.bankDetails[0].ifsccode
            }
          >
            Submit
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AddEmployee;
