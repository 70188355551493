import * as React from "react";
import { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import { DataGrid  , GridDeleteIcon, GridToolbarContainer, GridToolbarExport} from "@mui/x-data-grid";
import axios from "axios";
import { Alert, Dialog, DialogContent, DialogTitle, Snackbar } from "@mui/material";
import { useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { genericHeaders, serviceUrl } from "../utils/apiConfig";
import { getToken } from "../services/Token";
import { useDispatch, useSelector } from "react-redux";
import CancelICon from "../Icons/x-circle-cancel.svg";
import Tooltip from '@mui/material/Tooltip';
import { updateEditEmployeeClicked, updateAddEmployeeClicked } from "../store/reducers/addEmployeeSlice"
import { useGetAllEmployeesDownloadQuery } from "../api/addEmployeeApi";
import { saveAs } from 'file-saver';
import Loader from './customComponents/customLoader'
import { toast } from "react-toastify";

export default function ManageEmployee() {
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [employee, setEmployee] = useState({});
  const navigate = useNavigate();
  const [id, setId] = useState(null);
  const [locations, setLocations] = useState([]); 
  const [departments, setDepartments] = useState([]);
  const [designations, setDesignations] = useState([]);
  const [snack, setSnack] = useState({
    open: false,
    error: false,
    message : ""
  });
  const [designationsData, setDesignationsData] = useState([]);
  const [workDesignations, setWorkDesignations] = useState([]);
  const [intialData, setIntialData] = useState([]);
  const [gridLoader,setGridLoader] = useState(true);
  const [activefilters,setActiveFilters] = useState({deletedEmployeesFilter:false});
  const [allEmployeeInfo, setAllEmployeeInfo] = useState({
    allemployees: [],
    currentemployees: [],
    terminatedemployees: [],
  });
  const [dataSuccess,setDataSuccess] = useState({
    designations : false,
    departments : false,
    locations : false
  });
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: 25 });
  const [downloadDialogOpen,setDownloadDialogOpen] = useState(false);
  const [downloadLoader,setDownloadLoader] = useState(false);
  const addEmployeeClicked = useSelector((state) => state.addEmployee.addEmployeeClicked)
  const editEmployee = useSelector((state) => state.addEmployee.editEmployee);
  const employeeDetails = useSelector((state) => state.addEmployee.employeeDetails);
  const dispatch = useDispatch();

  const getData = async () => {
    try {
      let response = await axios.get(
        `${serviceUrl}/api/employee/getAllEmployeesData`,
        { headers: genericHeaders() }
      );
      if(response.status === 200){
        setIntialData(response.data);
        setGridLoader(false);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const getAllEmployeeInfo = async () => {
    try {
      let response = await axios.get(
        `${serviceUrl}/api/employee/allemployeesinfo`,
        { headers: genericHeaders() }
      );
      if(response.status === 200){
        setAllEmployeeInfo(response.data);
        setIntialData(response.data?.currentemployees);
        //setGridLoader(false);
      }
    } catch (err) {
      setGridLoader(false);
      console.error(err);
    }
  };

  async function getUrl(employeeData) {
    setDownloadLoader(true);
    let payload;
    if(employeeData?.length > 0){
      payload = employeeData
    }
    try {
      let response = await axios.post(`${serviceUrl}/api/employee/exportdata`, payload,{
        headers: {
          ...genericHeaders(),
          'Content-Type': 'application/json', 
          'Accept': 'text/csv', 
        },  
        responseType: 'blob',

      });
      const filename = 'employeeData.csv'; 
      const blob = new Blob([response.data]);
      saveAs(blob, filename);
       if(response.status === 200){
        setDownloadLoader(false);
       }
      } catch (err) {
        setDownloadLoader(false);
        toast.error('Failed to download');
        console.error('Error downloading file:', err);
    }
  }


  
  useEffect(() => {
    getAllEmployeeInfo();
    //getData();
    getDepartmentData();
    getLocationData();
    getWorkDesignations();
    getDesignationData();
  }, []);



  useEffect(() => {
    if (dataSuccess.designations && dataSuccess.departments && dataSuccess.locations && intialData?.length > 0) {
      let newData = matchData(intialData, departments, locations, designations);
      setData(newData); 
      setGridLoader(false); 
    }
  }, [designations, departments, locations, intialData]);

  const refreshData = () => {
    setGridLoader(true);
    getAllEmployeeInfo();
    //getData();
    getDepartmentData();
    getLocationData();
    getWorkDesignations();
    getDesignationData();
  }

  const getDesignationData = async () => {
    try {
      let response = await axios.get(`${serviceUrl}/api/designation/getAll`, {
        headers: genericHeaders(),
      });
      if (response.status === 200) {
        setDesignations(response.data);
        setDataSuccess( prev => ({...prev,designations:true}));
      }
    } catch (err) {
      console.error(err);
    }
  };
  const getLocationData = async () => {
    try {
      let response = await axios.get(
        `${serviceUrl}/api/workLocation/getAllWorkLocations`,
        { headers: genericHeaders() }
      );
      if (response.status === 200) {
        setLocations(response.data);
        setDataSuccess( prev => ({...prev,locations:true}));
      }
    } catch (err) {
      console.error(err);
    }
  };
  const getDepartmentData = async () => {
    try {
      let response = await axios.get(`${serviceUrl}/api/department/getAll`, {
        headers: genericHeaders(),
      });
      if (response.status === 200) {
        setDepartments(response.data);
        setDataSuccess( prev => ({...prev,departments:true}));
      }
    } catch (err) {
      console.error(err);
    }
  };
  const getWorkDesignations = async () => {
    try {
      let response = await axios.get(
        `${serviceUrl}/api/designation/getAllDesignations`,
        { headers: genericHeaders() }
      );
      if (response.status === 200) {
        setWorkDesignations(response.data);
      }
    } catch (err) {
      console.error(err);
    }
  };
  const handleSnackOpen = (snack,err,msg) => {
    setSnack({
      open: snack,
      error: err,
      message : msg
    });
  };
  const handleSnackClose = () => {
    setSnack({
      open: false,
      error: false,
      message : ""
    });
  };
  function matchData(employees, departmentData,workLocationData,designationData) {
    let item = employees;
    for (let i = 0; i < item.length; i++) {
      const matchedDepartment = departmentData.find(
        (dept) => dept._id === item[i].professionalDetails.department
      );
      if (matchedDepartment) {
        item[i].professionalDetails.department =
          matchedDepartment.departmentName;
      }
      const matchedWorkLocation = workLocationData.find(
        (loc) => loc._id === item[i].professionalDetails.workLocation
      );
      if (matchedWorkLocation) {
        item[i].professionalDetails.workLocation = matchedWorkLocation.location;
      }
      const matchedDesignation = designationData.find(
        (desig) => desig._id === item[i].professionalDetails.currentDesignation
      );
      const matchedManager = employees.find(
        (manager) => manager._id === item[i].professionalDetails.managerId
      );
      if (matchedManager) {
        item[i].professionalDetails.managerId = matchedManager.firstName;
      }
      else
      {
        item[i].professionalDetails.managerId = 'Manager Record Not Found';
      }
      if (matchedDesignation) {
        item[i].professionalDetails.currentDesignation =
          matchedDesignation.designation;
      }
    }
    return item;
  }
  const columnData = [
    { field: "actions", headerName: "Actions", width: 100, sortable: false,align: 'center', },
    { field: "employeeId", headerName: "Employee ID", width: 100 },
    { field: "firstName", headerName: "First Name", width: 200 },
    { field: "lastName", headerName: "Last Name", width: 200 },
    { field: "currentDesignation", headerName: "Current Designation",width: 200},
    { field: "department", headerName: "Department", width: 150 },
    { field: "workLocation", headerName: "Work Location", width: 150 },
    { field: "manager", headerName: "Manager", width: 150, sortable: false },
    { field: "email", headerName: "Email", width: 170,sortable: false },
    { field: "gender", headerName: "Gender",sortable: false },
    { field: "primaryContactNo",headerName: "Primary Contact Number",width: 150,sortable: false},
  ];
  
  const handleEditClick = (params) => {
    localStorage.setItem('breadCrumb',false);
    if (params.field === 'actions') {
      const rowData = params.row;
      navigate(`/edit/${rowData.id}`);
    }
  };

  const handleDeleteClick = async (params) => {
    if (params.field === 'actions') {
      setGridLoader(true);
      try {
        const rowData = params.row;
        let response = await axios.delete(`${serviceUrl}/api/employee/deleteById?employeeId=${rowData.id}`, { headers: genericHeaders() })
        if (response.status === 204) {
          refreshData();
          handleSnackOpen(true, false, "Employee Deleted Successfully");
        }
      } catch (err) {
        setGridLoader(false);
        console.error(err)
      }
    }
  };

  const handleViewClick = (params) => {
    if (params.field === 'actions') {
      const rowData = params.row;
      navigate(`/view/${rowData.id}`)
    }
  }
  const Actions = ({ row }) => (
    <>
    <div className="group">
  <div className="cursor-pointer text-blue-500 mr-2" onClick={() => handleViewClick({ field: 'actions', row })}>
    <VisibilityIcon />
  </div>
  <div className="invisible group-hover:visible absolute bg-gray-400 text-black font-bold p-1 -ml-2 rounded-md">
    View
  </div>
</div>
{!activefilters.deletedEmployeesFilter&&<div className="group">
    <div className="cursor-pointer text-blue-500" onClick={() => handleEditClick({ field: 'actions', row })}>
      <EditIcon/>
    </div>
    <div className="invisible group-hover:visible absolute bg-gray-400 text-black font-bold p-1 -ml-2 rounded-md">
    Edit
  </div>
</div>}
{!activefilters.deletedEmployeesFilter&&<div className="group">
    <div className="cursor-pointer text-blue-500" onClick={() => handleDeleteClick({ field: 'actions', row })}>
      <GridDeleteIcon/>
    </div>
    <div className="invisible group-hover:visible absolute bg-gray-400 text-black font-bold p-1 -ml-2 rounded-md">
    Delete
  </div>
</div>}
    </>
  );

  const columnsWithCustomComponents = columnData.map((column) =>
    column.field === 'actions'
      ? {
          ...column,
          renderCell: (params) => <Actions row={params.row}/>,
        }
      : column
  );

  const Id = getToken('Id');
  const rowData = data.filter(row => row._id !== Id).map((row,index) => {
    return({
    employeeId: row.professionalDetails.employeeId,
    firstName: row.firstName,
    lastName: row.lastName,
    currentDesignation: row.professionalDetails.currentDesignation,
    department: row.professionalDetails.department,
    workLocation: row.professionalDetails.workLocation,
    manager: row.professionalDetails.managerId,
    email: row.professionalDetails.companyEmail || row.personalDetails.email ,
    gender: row.personalDetails.gender,
    primaryContactNo: row.personalDetails.primaryContactNo,
    id: row._id,
  })});

  // function CustomToolbar() {
  //   return (
  //     <GridToolbarContainer>
  //       <GridToolbarExport />
  //     </GridToolbarContainer>
  //   );
  // }

  const handleRowSelectionChange = (newRowSelectionModel) => {
    // Calculate the range for the displayed rows on the current page
    const start = paginationModel.page * paginationModel.pageSize;
    const end = start + paginationModel.pageSize;
    const displayedRows = rowData.slice(start, end).map((row) => row.id);
  
    // Get new selections that are within the displayed rows
    const filteredSelection = newRowSelectionModel.filter((id) => displayedRows.includes(id));
  
    // Merge the new filtered selection with previously selected rows
    const updatedSelection = [
      ...rowSelectionModel.filter((id) => !displayedRows.includes(id)),
      ...filteredSelection,
    ];
  
    setRowSelectionModel(updatedSelection);
  };

  return (
    <>
      <div className="h-screen w-full flex flex-col justify-center items-center">
        <div className="flex mb-1 justify-around w-full">
          <div> </div>
          <h2 className="text-3xl ">Employee Data</h2>
          <Button variant="contained" onClick={() => navigate("/addemployee0")}>
            Add Employee
          </Button>
        </div>
        <div className="w-[90%]">
          <div className=" w-full flex">
            {!activefilters?.deletedEmployeesFilter && (
              <Tooltip title="Download Current Employees Data">
                <Button
                  variant="contained"
                  onClick={() => setDownloadDialogOpen(true)}
                >
                  Download
                </Button>
              </Tooltip>
            )}

            {!gridLoader && (
              <Button
                variant={"contained"}
                onClick={() => {
                  const { deletedEmployeesFilter } = activefilters;
                  if (!deletedEmployeesFilter) {
                    setIntialData(allEmployeeInfo?.terminatedemployees);
                    setActiveFilters({
                      ...activefilters,
                      deletedEmployeesFilter: true,
                    });
                  } else {
                    setIntialData(allEmployeeInfo?.currentemployees);
                    setActiveFilters({
                      ...activefilters,
                      deletedEmployeesFilter: false,
                    });
                  }
                }}
              >
                {!activefilters?.deletedEmployeesFilter ? "Terminated Employees" : "Current Employees"}
              </Button>
            )}
          </div>
        </div>
        <div className="w-[90%] h-[80vh] shadow-xl bg-slate-50">
          <Snackbar
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            open={snack.open}
            autoHideDuration={2000}
            onClose={handleSnackClose}
          >
            <Alert
              severity={snack.error ? "error" : "success"}
              variant="filled"
              onClose={handleSnackClose}
            >
              {snack.message}
            </Alert>
          </Snackbar>
          <DataGrid
            checkboxSelection={!activefilters?.deletedEmployeesFilter}
            keepNonExistentRowsSelected
            onRowSelectionModelChange={handleRowSelectionChange}
            onPaginationModelChange={(newPaginationModel) =>
              setPaginationModel(newPaginationModel)
            }
            rows={rowData}
            columns={columnsWithCustomComponents}
            loading={gridLoader}
            disableColumnMenu
            paginationModel={paginationModel}
            initialState={{
              pagination: { paginationModel: { pageSize: 25 } },
            }}
            pageSizeOptions={[25, 50, 75, 100]}
            rowSelectionModel={rowSelectionModel}
          />
          ;
        </div>
      </div>
      <Dialog
        open={downloadDialogOpen}
        onClose={() => setDownloadDialogOpen(false)}
        fullWidth
      >
        {downloadLoader && <Loader />}
        <DialogTitle className="flex justify-center">
          Download Employee Data
        </DialogTitle>
        <DialogContent className="flex justify-center items-center">
          <Button
            variant="contained"
            onClick={() => getUrl(rowSelectionModel)}
            disabled={rowSelectionModel.length === 0}
            className="mb-2"
          >
            Download Selected Employees
          </Button>
          <Button variant="contained" onClick={() => getUrl([])}>
            Download All Employees
          </Button>
        </DialogContent>
      </Dialog>
    </>
  );
}
