import React, { useEffect } from "react";
import dayjs from "dayjs";
import Badge from "@mui/material/Badge";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { PickersDay } from "@mui/x-date-pickers/PickersDay";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { DayCalendarSkeleton } from "@mui/x-date-pickers/DayCalendarSkeleton";
import {
  ChevronDoubleDownIcon,
  ChevronDoubleUpIcon,
} from "@heroicons/react/24/solid";
import { useFetchAttendanceMutation } from "../../api/loginApi";
import { getToken } from "../../services/Token";

const AttendanceInfo = (props) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [highlightedDays, setHighlightedDays] = React.useState([]);
  const [legendsVisible, setLegendsVisible] = React.useState(false);
  const [fetchAttendance, { data, isSuccess }] = useFetchAttendanceMutation();
  const empIdfromLocalStorage = localStorage.getItem("Id");
  const empIdfromProps = props.employeeId;
  const role = getToken("roles");
  const isManager = role ? role.includes("MANAGER") : false;
  const isAdmin = role ? role.includes("ADMIN") : false;
  const maxDate = dayjs();

  useEffect(() => {
    const currentDate = new Date().toISOString();
    const month = localStorage.getItem("Month");
    if (empIdfromProps) {
        localStorage.setItem("Id", empIdfromProps);
    }
    if (currentDate) {
        localStorage.setItem("Month", currentDate);
    }

    if (empIdfromLocalStorage && month) {
            fetchAttendance({
                employeeId: empIdfromLocalStorage,
                dateTime: month,
            });
    }
}, [empIdfromProps, empIdfromLocalStorage, isAdmin, isManager]);


 useEffect(() => {
    if (data) {
        setHighlightedDays(data);
    }
}, [isSuccess]);


const handleMonthChange = async (date) => {
  setIsLoading(true);
  const dayjsDate = dayjs(date).startOf('month');
  const currentMonth = dayjsDate.format('YYYY-MM-DDTHH:mm:ss.SSS') + 'Z';
  localStorage.setItem("Month", currentMonth);
  if ((isAdmin || isManager) && currentMonth && empIdfromProps) {
      fetchAttendance({
          employeeId: empIdfromLocalStorage,
          dateTime: currentMonth,
      });
  } 
  else {
      if (currentMonth && empIdfromLocalStorage && (!isAdmin || !isManager)) {
          fetchAttendance({
              employeeId: empIdfromLocalStorage,
              dateTime: currentMonth,
          });
      }
  }
  setIsLoading(false);
};


  const ServerDay = (props) => {
    const { day, outsideCurrentMonth, ...other } = props;
  
    // Define the current date and maxDate
    const currentDate = dayjs().startOf('day');
    const isBeforeCurrentDate = dayjs(day).isBefore(currentDate.add(1, 'day'));
    const isPresent = highlightedDays.includes(dayjs(day).format("YYYY-MM-DD"));
    const isSunday = new Date(day).getDay() === 0; // Check if it's Sunday
  
    const getBadge = () => {
      // Always show a badge for Sundays
      if (isSunday) {
        return "O";
      } else if (isPresent && isBeforeCurrentDate) {
        return "P";
      } else {
        return "A";
      }
    };
  
    const badgeContent = getBadge();
    const badgeColor =
      badgeContent === "P"
        ? " text-green-700 font-bold"
        : badgeContent === "A"
        ? " text-red-700 font-bold"
        : " text-blue-700 font-bold";
  
    return (
      <Badge
        key={props.day.toString()}
        overlap="circular"
        badgeContent={
          !outsideCurrentMonth && (isBeforeCurrentDate || isSunday) ? (
            <span className={`rounded-full ${badgeColor}`}>
              {badgeContent}
            </span>
          ) : null
        }
      >
        <PickersDay
          sx={{ pointerEvents: "none" }}
          {...other}
          outsideCurrentMonth={outsideCurrentMonth}
          day={day}
        />
      </Badge>
    );
  };
  
  
  

  return (
    <section className="flex flex-col items-center justify-center">
      <div className="mx-auto w-4/5 rounded-xl bg-white border border-black my-10 overflow-auto ">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateCalendar
            loading={isLoading}
            onMonthChange={handleMonthChange}
            maxDate={maxDate}
            renderLoading={() => <DayCalendarSkeleton />}
            slots={{
              day: ServerDay,
            }}
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              height: "75%",
              maxHeight: "75%",
              overflow: "visible",
              "& .MuiPickersCalendarHeader-root": {
                backgroundColor: "lightblue",
                flex: "0 1 auto",
                height: "auto", // Keep auto to adjust based on content
                padding: "30px 15px",
                margin: 0,
              },
              "& .MuiDayCalendar-header": {
                display: "flex",
                justifyContent: "space-evenly",
                flex: "0 1 auto",
              },
              "& .MuiDayCalendar-weekContainer": {
                display: "flex",
                justifyContent: "space-evenly",
                flex: "1 1 auto", // Allow it to grow
                margin: "10px 0",
                overflow: "hidden",
              },
              "& .MuiDayCalendar-loadingContainer": {
                width: "100%",
                display: "flex",
                justifyContent: "space-evenly",
                flex: "1 1 auto", // Allow it to grow
                margin: "10px 0",
                overflow: "hidden",
              },
              "& .MuiDayCalendarSkeleton-root": {
                width: "100%",
              },
              "& .MuiDayCalendarSkeleton-week": {
                width: "100%",
                display: "flex",
                justifyContent: "space-evenly",
              },
              "& .MuiDayCalendar-weekDayLabel": {
                borderRadius: "10px",
                fontSize: "1.5rem",
                width: "3rem",
                height: "3rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              },
              "& .MuiPickersDay-root": {
                borderRadius: "3px",
                width: "3rem",
                height: "3rem",
                fontSize: "1.5rem",
              },
              "& .MuiPickersYear-yearButton": {
                color: "darkblue",
              },
              "& .MuiPickersSlideTransition-root": {
                height: "400px",
              },
              "& .MuiPickersCalendarHeader-label": {
                fontSize: "1.5rem",
              },
              "& .MuiPickersCalendarHeader-root button": {
                minWidth: "40px",
                minHeight: "40px",
              },
              "& .MuiYearCalendar-root": {
                width: "100%",
                height: "75%",
              },
            }}
            slotProps={{
              day: {
                highlightedDays,
              },
            }}
          />
        </LocalizationProvider>
      </div>

      <div className="mx-auto w-4/5 rounded-xl bg-white border border-black mb-10">
        <div
          className={`w-full h-fit flex justify-between bg-blue-100 ${
            legendsVisible ? "rounded-t-xl" : "rounded-xl"
          } `}
        >
          <h2 className="text-2xl font-semibold p-3 items-start">Legends</h2>
          <div className="my-auto mx-5">
            {legendsVisible ? (
              <ChevronDoubleUpIcon
                className="h-7 text-black cursor-pointer"
                onClick={() => setLegendsVisible(!legendsVisible)}
              />
            ) : (
              <ChevronDoubleDownIcon
                className="h-7 text-black cursor-pointer"
                onClick={() => setLegendsVisible(!legendsVisible)}
              />
            )}
          </div>
        </div>

        <div
          className={`flex flex-wrap justify-evenly space px-10 py-5 overflow-hidden  ${
            legendsVisible ? "max-h-fit" : "hidden"
          }`}
        >
          <div className="flex">
            <h3 className="text-lg font-bold bg-green-100 px-2 text-green-700">
              P
            </h3>
            <p className="text-base my-auto mx-2">Present</p>
          </div>
          <div className="flex">
            <h3 className="text-lg font-bold bg-red-100 px-2 text-red-700">
              A
            </h3>
            <p className="text-base my-auto mx-2">Absent</p>
          </div>
          <div className="flex">
            <h3 className="text-lg font-bold bg-gray-100 px-2">O</h3>
            <p className="text-base my-auto mx-2">Off-day</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AttendanceInfo;
