import React, { useEffect, useState } from "react";
import Avatar from "../assets/avatar-default.svg";
import { genericHeaders, serviceUrl } from "../utils/apiConfig";
import { useCreateProfileQuery } from "../api/profileApi";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import CustomDialog from "./customComponents/customDialogue";
import PayrollLoader from "./customComponents/customLoaderPayroll";
import { Alert, Snackbar } from "@mui/material";  
import { setDisablePayroll, setNavigation } from "../store/reducers/payrollSlice";
import PDFPreviewDialog from "./PDFPreviewDialog";

const ProcessPayroll = () => {
const dispatch = useDispatch()
    const[loading,setLoading] = useState(false);
  const employeeId = useSelector((state) => state.payroll.employeeId);
  const date = useSelector((state) => state.payroll.date);
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [isPdfDialogOpen, setIsPdfDialogOpen] = useState(false);
  const [pdfUrl,setPdfUrl] = useState('')

  const [payrollId, SetPayrollId] = useState();
  const dateFormat = new Date(date);
  const [snackBarMessage,setSnackBarMessage] = useState('');
  const [snackOpen, setSnackOpen] = useState(false);
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const month = monthNames[dateFormat.getUTCMonth()];
  const year = dateFormat.getUTCFullYear();
  const formattedDate = `${month}-${year}`;
  const [additionalFields, setAdditionalFields] = useState({});
  const [payDetails, setPayDetails] = useState([
    { id: 1, name: "NET PAY", totalNetPay: 0 },
    { id: 2, name: "GROSS PAY", totalGrossPay: 0 },
    { id: 3, name: "DEDUCTION", totalDeductionPay: 0 },
  ]);
  const { data: employeeData, isSuccess } = useCreateProfileQuery({
    employeeId: `${employeeId}`,
  });
  const departmentId = employeeData?.professionalDetails?.department;
  const designationId = employeeData?.professionalDetails?.currentDesignation;
  const [getPayload, setGetPayload] = useState({
    employeeId: "",
    dateTime: "",
  });
  const [department, setDepartment] = useState();
  const [designation, setDesignation] = useState();
  const [error,setError] = useState(false)
  const navigate = useNavigate();

useEffect(()=>{
  dispatch(setDisablePayroll(false));
},[])

  const getDataDepartment = async () => {
    try {
        setLoading(true);
      let response = await axios.get(
        `${serviceUrl}/api/department/getRecord?departmentId=${departmentId}`,
        { headers: genericHeaders() }
      );
      const departmentName = response?.data?.departmentName;
      setDepartment(departmentName);

        setLoading(false);
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
  };
  const getDataDesignation = async () => {
    try {
        setLoading(true);
      let response = await axios.get(
        `${serviceUrl}/api/designation/getDesignation?designationId=${designationId}`,
        { headers: genericHeaders() }
      );
      const designationName = response?.data?.designation;
      setDesignation(designationName);

        setLoading(false);
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
  };
  const fetchData = async () => {
    try {
        setLoading(true);
      let response = await axios.post(
        `${serviceUrl}/api/payrollmanagement/fetchpayrollbyempidanddate`,
        { ...getPayload, employeeId: employeeId, dateTime: date },
        { headers: genericHeaders() }
      );
      const payrollProcessId = response?.data?._id;
      SetPayrollId(payrollProcessId);
      let payDetailsModifed = payDetails.map((item) => {
        let newItem = { ...item };
        switch (newItem.id) {
          case 1:
            newItem["totalNetPay"] = response?.data?.totalNetPay;
            break;
          case 2:
            newItem["totalGrossPay"] = response?.data?.totalGrossPay;
            break;
          case 3:
            newItem["totalDeductionPay"] = response?.data?.totalDeduction;
            break;
          default:
            break;
        }
        return newItem;
      });

      if (response?.data?.totalDeductionDetails) {
        let totalDeductionPayDetals = response?.data?.totalDeductionDetails;
        let deductionFields = totalDeductionPayDetals.map((item) => ({
          displayText: item.displayText,
          value: item.value,
        }));
        setAdditionalFields((prevAdditionalFields) => ({
          ...prevAdditionalFields,
          DEDUCTION: deductionFields,
        }));
      }

      if (response?.data?.totalGrossPayDetails) {
        let totalGrossPayDetals = response?.data?.totalGrossPayDetails;
        let grossPayFields = totalGrossPayDetals.map((item) => ({
          displayText: item.displayText,
          value: item.value,
        }));
        setAdditionalFields((prevAdditionalFields) => ({
          ...prevAdditionalFields,
          "GROSS PAY": grossPayFields,
        }));
      }
      setPayDetails(payDetailsModifed);
      if(response.status===200){
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isSuccess) {
      getDataDesignation();
      getDataDepartment();
    }
  }, [isSuccess]);
  useEffect(() => {
    fetchData();
  }, []);

  const handleOpenDialog = () => {
    setDialogOpen(true);
  };

  const handleOpenPdfDialog = () => {
    setIsPdfDialogOpen(true)
  }

  const handleClosePdfDialog = () => {
    setIsPdfDialogOpen(false)
    navigate('/payroll')
  }

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };
  const handleSnackClose = () => {
    setSnackOpen(false);
  };
  const handleYesClick = async () => {
    setError(false)
    setSnackOpen(true);
    setSnackBarMessage("Payroll Updated Successfully");
    handleCloseDialog();
    try {
        setLoading(true);
      let response = await axios.post(
        `${serviceUrl}/api/payrollmanagement/generateprocesspayroll?payrollId=${payrollId}`,
        null,
        { headers: genericHeaders() }
      );
      if(response?.status === 200){
        setError(false)
        await handleOpenPdfDialog()
        setPdfUrl(response?.data)
        setLoading(false);
      }     
    } catch (error) {
      setError(true)
      setSnackBarMessage(error?.response?.data);
      setLoading(false);
    }
  };

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={snackOpen}
        autoHideDuration={3000}
        onClose={handleSnackClose}
      >
        <Alert variant="filled" severity={error ? "error" : "success"} onClose={handleSnackClose}>
          {snackBarMessage}
        </Alert>
      </Snackbar>
    {loading && <PayrollLoader text="Please Wait..."/>}
    <div className="border m-5 px-10 py-5">
      <div className="flex">
        <img
          src={Avatar}
          className="h-12 w-12 border rounded-full my-auto"
          alt="profile"
        />
        <div className="my-auto px-2">
          <h2>{employeeData?.firstName}</h2>
          <p>{employeeData?.professionalDetails?.employeeId}</p>
        </div>
      </div>
      <div className="my-5">
        <table className="border-collapse">
          <tbody>
            <tr>
              <td className="pl-16 pr-10 text-slate-500 border ">
                Join Date :{" "}
                <span className="font-semibold text-black text-lg">
                  {employeeData?.professionalDetails?.dateofJoining
                    ? dayjs(
                        employeeData?.professionalDetails?.dateofJoining
                      ).format("DD/MM/YYYY")
                    : "-"}{" "}
                </span>
              </td>
              <td className="pl-16 pr-10 text-slate-500 border">
                Designation :{" "}
                <span className="font-semibold text-black text-lg">
                  {designation}
                </span>
              </td>
            </tr>
            <tr>
              <td className="pl-16 pr-10 text-slate-500 border">
                Experience :{" "}
                <span className="font-semibold text-black text-lg">
                  {employeeData?.professionalDetails?.totalExperience} Years
                </span>
              </td>
              <td className="pl-16 pr-10 text-slate-500 border">
                Department :{" "}
                <span className="font-semibold text-black text-lg">
                  {department}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="flex space-x-5 justify-end">
        <button
          className="px-5 py-2 rounded text-white bg-blue-600 border border-blue-600 cursor-pointer hover:text-blue-600 hover:bg-white"
          onClick={handleOpenDialog}
        >
          Submit
        </button>
        <button
          className="px-5 py-2 rounded text-white bg-blue-600 border border-blue-600 cursor-pointer hover:text-blue-600 hover:bg-white"
          onClick={() => {
            navigate("/payroll");
            dispatch(setNavigation(true))
          }}
        >
          Back
        </button>
        <CustomDialog
          open={isDialogOpen}
          handleClose={handleCloseDialog}
          handleYesClick={handleYesClick}
        />
      </div>
      <PDFPreviewDialog open={isPdfDialogOpen} handleClose={handleClosePdfDialog} url={pdfUrl} />
      <div className="flex">
        <div className="w-[30%] bg-blue-50 mt-10 py-2 border border-r-0 rounded-l-md h-fit pl-2">
          <p className="text-xl font-semibold">{formattedDate}</p>
        </div>
        <div className="w-[70%] bg-blue-50 border my-5 rounded px-2 py-5">
          <div className="flex flex-col justify-between p-4">
            {payDetails.map((item, index) => (
              <div key={item.id} className="flex flex-col">
                <div className="flex flex-row justify-between border border-b-gray-300 p-4">
                  <div className="flex gap-1">
                    <h1 className="text-gray-700 font-bold">{item.name}</h1>
                    {item.name !== "NET PAY" && (
                      <span className="cursor-pointer"></span>
                    )}
                  </div>
                  <input
                    disabled
                    className="border border-gray-200 rounded-xl p-2"
                    type="text"
                    value={
                      item.id === 1
                        ? item.totalNetPay
                        : item.id === 2
                        ? item.totalGrossPay
                        : item.totalDeductionPay
                    }
                  />
                </div>
                {additionalFields[item.name] &&
                  additionalFields[item.name].length > 0 &&
                  additionalFields[item.name].map((field, index) => (
                    <div
                      key={index}
                      className="flex flex-row justify-between border border-b-gray-300 p-4"
                    >
                      <div>
                        <input
                          disabled
                          className="border border-gray-200 rounded-xl p-2"
                          type="text"
                          value={field.displayText}
                        />
                      </div>
                      <div>
                        <input
                          disabled
                          className="border border-gray-200 rounded-xl p-2"
                          type="text"
                          value={field.value}
                        />
                      </div>
                    </div>
                  ))}
              </div>
            ))}
          </div>
          <div className="my-5 flex justify-between">
            <div className="ml-5 w-[50%]">
              <p className="text-md text-slate-600">
                Payout Month :{" "}
                <span className="text-lg text-black">{formattedDate}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default ProcessPayroll;
