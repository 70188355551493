import { combineReducers } from "@reduxjs/toolkit";
import counterSlice from "./counterSlice";
import postsApi from "../../services/postsApi";
import fileUploadApi from "../../services/fileUploadApi";
import loginApi from "../../api/loginApi";
import addEmployeeSlice from "./addEmployeeSlice";
import addEmployeeApi from "../../api/addEmployeeApi";
import errorMessageSlice from "./errorMessageSlice";
import calendarSlice from "./calendarSlice";
import genrateLetterSlice from "./genrateLetterSlice";
import payrollSlice from "./payrollSlice";
import loginSlice from "./loginSlice";

const rootReducer = combineReducers({
    counter : counterSlice,
    addEmployee:addEmployeeSlice,
    errorMessage:errorMessageSlice,
    calendar:calendarSlice,
    generateLetter:genrateLetterSlice,
    payroll:payrollSlice,
    login:loginSlice,
    [postsApi.reducerPath]: postsApi.reducer,
    [fileUploadApi.reducerPath]: fileUploadApi.reducer,
    [loginApi.reducerPath]: loginApi.reducer,
    [addEmployeeApi.reducerPath]:addEmployeeApi.reducer
})

export default rootReducer

