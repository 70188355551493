import { createSlice } from "@reduxjs/toolkit";

const payrollSlice = createSlice({
  name: "payroll",
  initialState: {
    employeeId: "",
    date: "",
    dateYear: "",
    selectType: null,
    selectMonth: "",
    additionalFields: {},
    navigation:false,
    payrollId:"",
    payDetails: [
      { id: 1, name: "NET PAY", totalNetPay: 0 },
      { id: 2, name: "GROSS PAY", totalGrossPay: 0 },
      { id: 3, name: "DEDUCTION", totalDeductionPay: 0 },
    ],
    employeeType: "currentEmployee",
    error: "",
    responseData: "",
    payload: {
      employeeId: 0,
      totalNetPay: 0,
      totalGrossPay:0,
      totalDeduction: 0,
      totalGrossPayDetails: [],
      totalDeductionDetails: [],
      _id: ""
    },
    getPayload: {
      employeeId: "",
      dateTime: "",
    },
 disablePayroll : false,
    options: [],
    datesSelection:[],
  },
  paySlipData:{},
  employeeAuditData:{},
  reducers: {
    setEmployeeId: (state, action) => {
      state.employeeId = action.payload;
    },
    setDate: (state, action) => {
      state.date = action.payload;
    },
    setDateYear: (state, action) => {
      state.dateYear = action.payload;
    },
    setSelectType: (state, action) => {
      state.selectType = action.payload;
    },
    setEmployeeAudiData:(state,action)=>{
state.employeeAuditData = action.payload
    },
    setSelectMonth: (state, action) => {
      state.selectMonth = action.payload;
    },
    setPayrollId:(state,action)=>{
      state.payrollId = action.payload;
    },
    setAdditionalFields: (state, action) => {
      const {key,value} = action.payload;
      state.additionalFields[key] = value;
    },
    setPayDetails: (state, action) => {
      state.payDetails = action.payload;
    },
    setEmployeeType: (state, action) => {
      state.employeeType = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setDisablePayroll:(state,action)=>{
      state.disablePayroll = action.payload
    },
    setResponseData: (state, action) => {
      state.responseData = action.payload;
    },
    setNavigation:(state,action)=>{
state.navigation = action.payload;
    },
    setPayload: (state, action) => {
      state.payload = {
        ...state.payload,
        ...action.payload,
        totalGrossPayDetails: state.additionalFields["GROSS PAY"] || [],
        totalDeductionDetails: state.additionalFields["DEDUCTION"] || [],
      };
    },
    setPaySlipData:(state,action)=>{
     state.paySlipData = action.payload
    },
    setGetPayload: (state, action) => {
      state.getPayload = action.payload;
    },
    setOptions: (state, action) => {
      state.options = action.payload;
    },
    setDatesSelection:(state,action)=>{
      state.datesSelection = action.payload
    },
    addField: (state, action) => {
      const { item } = action.payload;
      state.additionalFields[item] = [
        ...(state.additionalFields[item] || []),
        { displayText: '', value: "" },
      ];
    },
    updateField: (state, action) => {
      const { fieldName, index, value, type } = action.payload;
      const updatedFields = { ...state.additionalFields };
      const fieldArray = [...updatedFields[fieldName]];
      const updatedFieldObject = { ...fieldArray[index] };
      updatedFieldObject[type] = value;
      fieldArray[index] = updatedFieldObject;
      updatedFields[fieldName] = fieldArray;
      state.additionalFields = updatedFields;
    },
  },
});

export const {
  setEmployeeId,
  setDate,
  setSelectType,
  setSelectMonth,
  setAdditionalFields,
  setPayDetails,
  setEmployeeType,
  setError,
  setResponseData,
  setPayload,
  setGetPayload,
  setOptions,
  setDatesSelection,
  addField,updateField,
  setPayrollId,
  setDisablePayroll,
  setPaySlipData,
  setEmployeeAudiData,
  setNavigation
} = payrollSlice.actions;

export default payrollSlice.reducer;
