import React, { useEffect, useState } from 'react'
import { useGetLeaveTypesQuery } from '../api/getLeaveTypeApi';
import { cloneDeep } from 'lodash';
import { FormControl, InputLabel, Select, MenuItem, TextField, Button, Alert, Snackbar } from '@mui/material';
import { useGetEmployeesListQuery } from '../api/getEmployeeList';
import { useCreateProfileQuery } from '../api/profileApi';
import { useGetEmployeeLeavesQuery, useLazyGetEmployeeLeaveInfoQuery } from '../api/employeeLeaves'
import axios from 'axios';
import { genericHeaders, serviceUrl } from '../utils/apiConfig';
import { getToken } from '../services/Token';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Loader from "../Components/customComponents/customLoader"
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import dayjs from 'dayjs';

const ApplyLeave = () => {
    const form = {
      get_id: "",
      isActive: true,
      employeeId: "",
      fromDate: null,
      toDate: null,
      leaveType: "",
      days: 0,
      leaveReason: "",
      reportingManagerIds: [],
      additionalProps: {
        additionalProp1: {},
        additionalProp2: {},
        additionalProp3: {},
      },
    };
    const [leaveType, setLeaveType] = useState([]);
    const [ccEmployees, setCcEmployees] = useState([]);
    const [employeeList, setEmployeeList] = useState([]);
    const [formData, setFormData] = useState(form);
    const [employee, setEmployee] = useState();
    const [leaveCount, setLeaveCount] = useState("-");
    const [ calendarDate, setCalendarDate ] = useState(new Date().toISOString().split('Z')[0]);
    const [loading, setLoading] = useState(false);
    const [ calendarLoading, SetCalendarLoading ] = useState(false);
    const [ leaves,setLeaves ] = useState([]);
    const [ restrictedHoliday,setRestrictedHoliday] = useState("");
    const [ dialogOpen,setDialogOpen ] = useState(false);
    const [ oldLeaveCategory,setOldLeaveCategory ] = useState("")
    const [ optionalHolidays,setOptionalHolidays ] = useState([]);
    const [snack, setSnack] = useState({
      open: false,
      error: false,
      message: "",
    });
    

    const Id = getToken("Id"); //need to change
    const { data: employeeData, isSuccess: employeeDataSucess } =
      useCreateProfileQuery({ employeeId: `${Id}` });
    const { data: leaveTypeData, isSuccess: leaveTypeSuccess } =
      useGetLeaveTypesQuery();
    const { data: employeeListData, isSuccess: employeeListSuccess } =
      useGetEmployeesListQuery();
    const { data: employeeLeaveData, isSucces: employeeLeaveSuccess } =
      useGetEmployeeLeavesQuery({ employeeId: `${Id}` });
    const [ getEmployeeLeaveInfo, leaveInfo] = useLazyGetEmployeeLeaveInfoQuery();
    useEffect(() => {
      if (leaveTypeData) {
        let newData = cloneDeep(leaveTypeData);
        setLeaveType(newData);
        newData.forEach((item,i) => {
          if (item.isRestrictedHolidayCategory) {
            setRestrictedHoliday(item._id);
          }
        })
        newData.every((item) => {
          if(!item.isRestrictedHolidayCategory){
            setOldLeaveCategory(item._id);
            return false;
          }
          return true;
        })
      }
      if (employeeListData) {
        let newData = cloneDeep(employeeListData);
        newData = newData.filter((data) => data.id !== Id);
        setEmployeeList(newData);
      }
      if (employeeData) {
        let newData = cloneDeep(employeeData);
        setEmployee(newData);
      }
      if (employeeLeaveData) {
        let newData = cloneDeep(employeeLeaveData);
        let count = displayCount(formData, newData.leaves);
        setLeaveCount(count);
      }
    }, [
      leaveTypeSuccess,
      employeeListSuccess,
      employeeDataSucess,
      employeeLeaveSuccess,
      formData,
    ]);
    useEffect(() => {
      if(calendarDate){
       getEmployeeLeaveInfo({ employeeId: `${Id}`,date: `${calendarDate}` })
      }
    },[calendarDate])
    useEffect(() => {
      if(leaveInfo.isSuccess){
        setLeaves(leaveInfo.data);
        SetCalendarLoading(false);
      }
    }, [leaveInfo.data]);
    const getCalendarDetails = async () => {
      try{
        let url = `${serviceUrl}/api/holidayCalender/getHolidays?restrictedHolidayId=${restrictedHoliday}`
        let response = await axios.get(url,{headers: genericHeaders()})
        if(response.status === 200){
          let newData = response.data;
          newData = newData.filter((item) => item.leaveType === "OPTIONAL");
          setOptionalHolidays(newData);
          setLoading(false)
        }
      }catch(error){
        setLoading(false);
        handleSnackOpen(true,true,"Something Went wrong")
        console.error(error)
      }
    }
    const dateDifference = (fromDate, toDate) => {
        const start = dayjs(fromDate);
        const end = dayjs(toDate);
        const totalDays = end.diff(start, "days") + 1;

        const numSundaysMondays = [...Array(totalDays).keys()].filter((index) => {
          const currentDate = start.add(index, "days");
          const dayOfWeek = currentDate.day();
          return dayOfWeek === 0 || dayOfWeek === 1;
        }).length;

        const leaveDays = totalDays - numSundaysMondays;
        return leaveDays;
    };

    const displayCount = (data, leaves) => {
      let matchedData = leaves.find(
        (item) => data.leaveType === item.leaveType
      );
      return matchedData?.currentAvailableLeaves || "0";
    };

    const handleDate = (evt, type) => {
      let data = { ...formData };
      const localDate = dayjs(evt).toISOString();
      if(type === "from") {
        data.fromDate = localDate;
      }else if(type === "to") {
        data.toDate = localDate;
      }
      setFormData(data);
    };

    const dateValidator = (date) => {
      const inputDate = new Date(date);
      const localDate = new Date(inputDate.getTime() + 330 * 60 * 1000);
      return localDate.toISOString();
    }

    const handleForm = (evt) => {
      let data = { ...formData };
      if(evt.target.name === "leaveType" && evt.target.value === restrictedHoliday){
        setLoading(true);
        const calendarId = employee?.professionalDetails?.holidayCalender;
        getCalendarDetails();
        setDialogOpen(true)
      }
      else{
      data[evt.target.name] = evt.target.value;
      }
      setFormData(data);
    };

    const handleCcEmployee = (evt) => {
      setCcEmployees(evt.target.value);
    };
    const handleSnackOpen = (open,error,message) => {
      let snackData = {...snack};
      snackData.open = open;
      snackData.error = error;
      snackData.message = message;
      setSnack(snackData);
    };
    const handleSnackClose = () => {
      setSnack(false);
    }; 
    const handleMonthChange = (evt) => {
      SetCalendarLoading(true);
      let date = dayjs(evt).startOf('day').format("YYYY-MM-DDTHH:mm:ss.SSS");
      setCalendarDate(date);
    }
    
    const handleSubmit = async () => {
      setLoading(true);
      let data = {...formData};
      data.reportingManagerIds = ccEmployees;
      data.employeeId = employee._id;
      data.days = dateDifference(data.fromDate, data.toDate);
      data.fromDate = dayjs(data.fromDate).startOf('day').format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
      data.toDate = dayjs(data.toDate).endOf('day').format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
      if (data.days <= leaveCount) {
        try {
          let response = await axios.post(
            `${serviceUrl}/api/leaveTransaction/submitLeave`,
            data,
            { headers: genericHeaders() }
          );
          if (response.status === 201) {
            setFormData(form);
            setCcEmployees([]);
            setLoading(false);
            handleSnackOpen(true,false,"Leave Applied Successfully");
            setCalendarDate(new Date().toISOString().split('Z')[0]);
          }
        } catch (err) {
          console.error(err);
        }
      }else if(data.days > leaveCount){
        handleSnackOpen(true,true,"You don't have enough Balance for selected Leave Type");
        setLoading(false);
      }
    };
    const fromDateDisabled = (date) => {
      const local = dayjs(date);
      if (formData.toDate) {
        const toDate = dayjs(formData.toDate);
        const day = local.day();
        // let activeDays = toDate;
        // let count = 0;
        // while (count < 2) {
        //   activeDays = activeDays.subtract(1, 'day');
        //   if (activeDays.day() !== 0 && activeDays.day() !== 1) {
        //     count += 1;
        //   }
        // }
        if (local.isAfter(toDate) || day === 0 || day === 1) {
          return true;
        } 
      }
      if(local.day() === 0 || local.day() === 1){
        return true;
      }
      const dateString = local.startOf('day').format('YYYY-MM-DDTHH:mm:ss');
      return leaves.some((leave) => leave.date === dateString && leave.isOnLeave);
    };
    const toDateDisabled = (date) => {
      const local = dayjs(date);
      if (formData.fromDate) {
        const from = dayjs(formData.fromDate);
        const day = local.day();
        //let activeDays = from;
        // let count = 0;
        // while (count < 2) {
        //   activeDays = activeDays.add(1, 'day');
        //   if (activeDays.day() !== 0 && activeDays.day() !== 1) {
        //     count += 1;
        //   }
        // }=~
        if (local.isBefore(from) || day === 0 || day === 1) {
          return true;
        }
      }
      if (local.day() === 0 || local.day() === 1) {
        return true;
      }
      const dateString = local.startOf('day').format('YYYY-MM-DDTHH:mm:ss');
      return leaves.some((leave) => leave.date === dateString && leave.isOnLeave);
    };
    const handleRestrictedLeaveApply = async (leave) => {
      let data = {
        get_id: "",
        isActive: true,
        employeeId: "",
        fromDate: null,
        toDate: null,
        leaveType: "",
        days: 0,
        leaveReason: "",
        reportingManagerIds: [],
        additionalProps: {
          additionalProp1: {},
          additionalProp2: {},
          additionalProp3: {},
        },
      }
      handleClose();
      setLoading(true);
      data.employeeId = Id;
      data.fromDate = dayjs(leave.date).startOf('day').format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
      data.toDate = dayjs(leave.date).endOf('day').format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
      data.leaveType = restrictedHoliday
      data.days = 1
      try{
        let response = await axios.post(
          `${serviceUrl}/api/leaveTransaction/submitLeave`,
          data,
          { headers: genericHeaders() });
          if(response.status === 201){
            handleClose();
            setLoading(false);
            handleSnackOpen(true,false,"Leave Applied Successfully");
          }
      }catch (error){
        console.error(error)
        setLoading(false);
        handleSnackOpen(true,true,"Something Went Wrong");
      }
    }
    const handleClose = () => {
      setDialogOpen(false);
      let newData = {...formData};
      newData.leaveType = oldLeaveCategory;
      setFormData(newData);
    }
  return (
    <>
      <div className="w-full relative">
        <div className="w-1/2">
          <p className="absolute top-5 right-10 text-xl">
            Leave Balance : {leaveCount}
          </p>
        </div>
        {loading && (<Loader/>)}
        <div className="my-10 mx-10 w-1/2">
          <Snackbar
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            open={snack.open}
            autoHideDuration={2000}
            onClose={handleSnackClose}
          >
            <Alert
              severity={snack.error ? "error" : "success" }
              variant="filled"
              onClose={handleSnackClose}
            >
              {snack.message}
            </Alert>
          </Snackbar>

          <Dialog
            open={dialogOpen}
            onClose={handleClose}
            maxWidth="lg" 
            fullWidth
          >
            <DialogTitle id="alert-dialog-title">Restricted Holiday</DialogTitle>
            <DialogContent>
              <div className='grid grid-cols-3 gap-5'>
                {optionalHolidays.map((item) => {
                  const leaveDate = new Date(item.date);
                  const today = new Date();
                  const differenceInMs = leaveDate - today;
                  const differenceInDays = Math.floor(differenceInMs / (1000 * 60 * 60 * 24));
                  return (<div className='px-5 py-3 rounded-lg border border-blue-500'>
                  <div className='flex flex-col'>
                   <h3 className='text-4xl font-semibold'>{item.occation}</h3>
                   <p className='text-lg'>{dayjs(item.date).format("DD MMM YYYY")}</p>
                  </div>
                  <div className='flex items-end justify-end'>
                    {differenceInDays < 3 && differenceInDays > 0 && leaveCount > 0 ? ( item.isApplied ? <p className='text-blue-400 cursor-default'>Applied</p> : <p className='text-blue-400 cursor-pointer' onClick={() => handleRestrictedLeaveApply(item)}>Apply</p>): <></>}
                    
                  </div>
                </div>)})}
              </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>CANCEL</Button>
            </DialogActions>
          </Dialog>


          <h3 className="text-xl">Applying for Leave</h3>
          <div className="my-8">
            <FormControl className="w-5/12">
              <InputLabel id="leave-type">
                Leave Type<span className="bg-red">*</span>
              </InputLabel>
              <Select
                labelId="leave-type"
                label="Leave Type"
                name="leaveType"
                value={formData.leaveType}
                onChange={handleForm}
                MenuProps={{
                  sx: {
                    '& .MuiList-root': {
                      maxHeight: 300,
                    },
                    "& .MuiInputBase-root": {
                      height: 50
                    },
                  },
                }}
              >
                {leaveType.map((item) => {
                  
                  return (
                    <MenuItem value={item._id} key={item._id}>
                      {item.leaveCategory}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </div>

          <div className="grid grid-cols-2 gap-5">
            {/* <TextField
              label="From Date"
              name="fromDate"
              variant="outlined"
              onChange={handleDate}
              type="date"
              InputLabelProps={{ shrink: true }}
              className="w-4/5"
              value={formData.fromDate.split("T")[0]}
              required
            /> */}
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="From Date"
                name="fromDate"
                format="DD/MM/YYYY"
                className="w-4/5"
                value={formData.fromDate ? dayjs(formData.fromDate) : null}
                onChange={(e) => handleDate(e, "from")}
                onOpen={ () => SetCalendarLoading(false) }
                shouldDisableDate={(date) => fromDateDisabled(date)}
                loading={calendarLoading}
                disablePast
                onMonthChange={handleMonthChange}
                slotProps={{
                  textField: {
                    readOnly: true,
                  },
                }}
              />
            </LocalizationProvider>
            {/* <TextField
              label="To Date"
              name="toDate"
              variant="outlined"
              onChange={handleDate}
              type="date"
              InputLabelProps={{ shrink: true }}
              className="w-4/5"
              value={formData.toDate.split("T")[0]}
              required
            /> */}
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="To Date"
                name="toDate"
                className="w-4/5"
                format="DD/MM/YYYY"
                value={formData.toDate ? dayjs(formData.toDate) : null}
                onChange={(e) => handleDate(e, "to")}
                onOpen={ () => SetCalendarLoading(false) }
                onMonthChange={handleMonthChange}
                shouldDisableDate={(date) => toDateDisabled(date)}
                disablePast
                slotProps={{
                  textField: {
                    readOnly: true,
                  },
                }}
              />
            </LocalizationProvider>
          </div>

          <div className="my-10">
            <TextField
              label="Manager"
              name="manager"
              variant="outlined"
              className="w-1/2"
              value={Object.keys(employee?.managersMap || [])}
              disabled
            />
          </div>
          <div className="flex flex-col space-y-5">
            <FormControl className="w-1/2">
              <InputLabel id="cc-to">CC</InputLabel>
              <Select
                labelId="cc-to"
                label="CC"
                name="cc-to"
                className="w-full"
                multiple
                value={ccEmployees}
                MenuProps={{
                  sx: {
                    '& .MuiList-root': {
                      maxHeight: 200,
                    },
                    "& .MuiInputBase-root": {
                      height: 50
                    },
                    maxWidth: 50
                  },
                }}
                onChange={handleCcEmployee}
                renderValue={(selected) => {
                  return selected
                    .map((value) => {
                      const selectedItem = employeeList.find(
                        (item) => item.id === value
                      );
                      return selectedItem ? selectedItem.name : "";
                    })
                    .join(", ");
                }}
              >
                {employeeList.map((item) => {
                  return (
                    <MenuItem value={item.id} key={item.id}>
                      {item.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <TextField
              label="Reason"
              name="leaveReason"
              variant="outlined"
              onChange={handleForm}
              className="w-1/2"
              value={formData.leaveReason}
            />
          </div>
        </div>
      </div>
      <div className="flex items-end justify-end mt-20 space-x-10 mx-20">
        <Button
          variant="contained"
          onClick={() => {
            setFormData(form);
            setCcEmployees([]);
          }}
          size="large"
        >
          Reset
        </Button>
        <Button
          variant="contained"
          size="large"
          onClick={handleSubmit}
          disabled={
            !formData.leaveType ||
            !formData.fromDate ||
            !formData.toDate ||
            !(leaveCount > 0)
          }
        >
          Apply Leave
        </Button>
      </div>
    </>
  );
}

export default ApplyLeave