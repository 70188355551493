import React, { useEffect, useState } from "react";
import { getToken } from "../services/Token";
import { useLazyGetTenantDetailsQuery } from "../api/tenantApi";
import { useDispatch } from "react-redux";
import { setLogoUrl } from "../store/reducers/calendarSlice";
import { Dialog, DialogContent, Alert, Snackbar } from "@mui/material";
import axios from "axios";
import { genericHeaders, serviceUrl } from "../utils/apiConfig";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import PaySlipEmployee from "./paySlipEmployee";
import {
  setPaySlipData,
  setEmployeeAudiData,
} from "../store/reducers/payrollSlice";
import {
  useLazyPayslipQuery,
  useLazyEmployeeAuditQuery,
  useSigninstatusQuery,
} from "../api/loginApi";
import SignInTrackingCard from "./SignInTrackingCard";
const EmployeeMessage = () => {
  const [snack, setSnack] = useState({
    open: false,
    error: false,
    message: "",
  });
  const loggedInUserId = getToken("Id");
  const [getTenantDetails, { data }] = useLazyGetTenantDetailsQuery();
  const employeeName = getToken("employeeName");
  const role = getToken("roles");
  console.log(role,"ROLESOFEMPLOYE")
  const isAdmin = role ? role.includes("ADMIN") : false;
  const isManager = role ? role.includes("MANAGER") : false;
  const isEmployee = role ? role.includes("EMPLOYE") : false;
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dropdownValue, setDropdownValue] = useState("");
  const [comments, setComments] = useState("");
  const [signIn, setSignIn] = useState("");
  const [signOut, setSignOut] = useState("");
  const [Location, setLocation] = useState("");
  const loggedIn = localStorage.getItem("loggedIn");
  const [isLoading, setIsLoading] = useState(false);

  const [payslip, { data: payslipdata, isSuccess, isError, error }] =
    useLazyPayslipQuery({});
  const [
    employeeAudit,
    { data: employeeAuditData, isSuccess: employeeAuditDataSuccess },
  ] = useLazyEmployeeAuditQuery({});

  const dispatch = useDispatch();
  const attendanceLogin = localStorage.getItem("loggedInToday");
  const lastLoggedInTime = localStorage.getItem("lastLoggedInTime");
  if (data) {
    dispatch(setLogoUrl(data?.logoUrl));
  }

  useEffect(() => {
    if (isAdmin || isManager) {
      const currentDate = dayjs();
      const formatted = currentDate.toISOString();
      getTenantDetails(loggedInUserId);
      employeeAudit(formatted);
    }
    getSignInstatus();
  }, []);


  const handleDialogueOpen = () => {
    setDialogOpen(true);
  };
  const handleClose = () => {
    setIsLoading(false);
    setDialogOpen(false);
    setDropdownValue("");
    setComments("");
  };
  useEffect(() => {
    if (isSuccess) {
      dispatch(setPaySlipData(payslipdata));
      localStorage.setItem(
        "paySlipDate",
        payslipdata?.data?.payRollProcessedDate
      );
    } else {
      dispatch(setPaySlipData(error?.data?.message));
    }
  }, [isSuccess, isError]);

  useEffect(() => {
    if (employeeAuditDataSuccess) {
      dispatch(setEmployeeAudiData(employeeAuditData));
    }
  }, [employeeAuditDataSuccess]);

  const getSignInstatus = async () => {
    try {
      let response = await axios.get(
        `${serviceUrl}/api/employee/audit/signinstatus`,
        { headers: genericHeaders() }
      );
      
      if(response.status===200){
        localStorage.setItem("loggedIn", response?.data.data?.isloggedintoday);
      localStorage.setItem(
        "lastLoggedInTime",
        response?.data.data?.lastloggedintime
      );
      setLocation(response?.data.data?.lastloggedinlocation);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleDialogueCloseSignIn = async () => {
    try {
      localStorage.setItem("loggedInToday", "LOGGEDIN");
      setIsLoading(true);
      if (dropdownValue !== "Select") {
        if (dropdownValue !== "") {
          let response = await axios.post(
            `${serviceUrl}/api/employee/audit/signin?location=${dropdownValue}&comment=${comments}`,
            null,
            { headers: genericHeaders() }
          );
          if (response.status === 200) {
            setIsLoading(false);
            localStorage.setItem("loggedIn", response?.data?.data?.loginStatus);
            localStorage.setItem(
              "lastLoggedInTime",
              response?.data?.data?.date
            );
            setLocation(response?.data?.data?.location);
            setDropdownValue("");
            setComments("");
            setSignIn(response);
            handleSnackOpen(true, false, response?.data?.message);
            setDialogOpen(false);
          }
        }
      }
    } catch (err) {
      setIsLoading(false);
      handleSnackOpen(true, true, "Something Went Wrong");
      setSignIn(false);
      console.error(err);
    }
  };
  const handleDialogueCloseSignOut = async () => {
    setIsLoading(true);
    try {
      if (dropdownValue !== "Select") {
        if (dropdownValue !== "") {
          let response = await axios.post(
            `${serviceUrl}/api/employee/audit/signout?location=${dropdownValue}&comment=${comments}`,
            null,
            { headers: genericHeaders() }
          );
          if (response.status === 200) {
            setIsLoading(false);
            localStorage.setItem("loggedIn", response?.data?.data?.loginStatus);
            setDropdownValue("");
            setComments("");
            setSignOut(true);
            setDialogOpen(false);
            handleSnackOpen(true, false, response?.data?.message);
          }
        }
      }
    } catch (err) {
      setIsLoading(false);
      handleSnackOpen(true, true, "Something Went Wrong");
      setSignOut(false);
      console.error(err);
    }
  };

  dayjs.extend(duration);

  const formatDate = (dateString) => {
    const date = dayjs(dateString);
    const updatedDate = date.add(dayjs.duration({ hours: 5, minutes: 30 }));

    const options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    };

    return updatedDate.toDate().toLocaleString("en-US", options);
  };

  const handleSnackOpen = (snackopen, err, msg) => {
    setSnack({
      open: snackopen,
      error: err,
      message: msg,
    });
  };
  const handleSnackClose = () => {
    setSnack({
      open: false,
      error: false,
      message: "",
    });
  };
  useEffect(() => {
    if (!isAdmin) {
      payslip(loggedInUserId);
    }
  }, []);

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        open={snack.open}
        autoHideDuration={3000}
        onClose={handleSnackClose}
      >
        <Alert
          severity={snack.error ? "error" : "success"}
          variant="filled"
          onClose={handleSnackClose}
        >
          {snack.message}
        </Alert>
      </Snackbar>
      {isAdmin || isManager ? (
        <div
          className={`flex  flex-col items-center justify-center h-screen bg-white transition-opacity duration-500`}
        >
          <div className="w-full p-8 ">
            {(attendanceLogin === "LOGGEDOUT" && !signIn) ||
            loggedIn === "LOGGEDOUT" ? (
              <>
                <div className="flex justify-center  w-full">
                  <div className="flex flex-col justify-center items-center">
                    <div className="flex items-center mb-4 ">
                      <p className="  text-purple-800 text-[3.875rem] font-extrabold font-serif">
                        H{" "}
                        <span className="text-gray-700 text-[2.3rem] font-bold -ml-[1rem]">
                          ello,
                        </span>
                      </p>
                      <p className="text-[2.25rem] font-bold text-blue-600 font-serif mt-[1.5rem]">
                        {employeeName?.split("(")[0]}
                      </p>
                    </div>
                    <div className="flex gap-2 items-center">
                      <div className="border border-black p-6 rounded-lg shadow-md hover:shadow-lg transition-transform transform hover:scale-105 w-[45%] bg-blue-300 text-center flex flex-col justify-center">
                        <p className="text-2xl font-semibold text-gray-900 mb-6 font-serif text-center">
                          You are not signed in yet. Please sign in to record
                          your attendance.
                        </p>
                        <div className="flex justify-center">
                          <button
                            className="bg-blue-600 rounded-full text-white font-semibold p-3 flex items-center justify-center w-[50%]"
                            onClick={handleDialogueOpen}
                          >
                            Sign In
                          </button>
                        </div>
                      </div>
                      {isManager && <PaySlipEmployee isError={isError} />}
                      {isAdmin && (
                        <div className="border border-black p-6 rounded-lg shadow-md hover:shadow-lg transition-transform transform hover:scale-105 w-[45%] bg-blue-50 ml-6">
                          <div className="flex flex-col gap-4 ">
                            <p className="text-lg text-black text-center font-serif  font-bold">
                              Address:
                            </p>
                            <p className="text-lg text-gray-600 text-center  font-serif font-semibold">
                              {data?.address}
                            </p>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <Dialog
                  open={dialogOpen}
                  onClose={handleClose}
                  maxWidth="lg"
                  fullWidth
                  PaperProps={{
                    style: {
                      width: "30%",
                    },
                  }}
                >
                  <div className="flex justify-between items-center bg-blue-100 p-4">
                    <h1 className="text-blue-500 font-bold font-serif text-2xl">
                      Sign-In Details
                    </h1>
                    <button
                      className="text-black text-4xl cursor-pointer"
                      onClick={handleClose}
                    >
                      &times;
                    </button>
                  </div>
                  <DialogContent>
                    <div className="bg-white p-4 rounded-lg">
                      <div className="flex items-center mb-4">
                        <h2 className="text-black font-serif font-bold text-xl mr-2">
                          Sign-In Location
                        </h2>
                        <span className="text-red-500">*</span>
                      </div>
                      <select
                        value={dropdownValue}
                        onChange={(e) => setDropdownValue(e.target.value)}
                        className="w-full mb-4 border border-black rounded-lg p-2"
                      >
                        <option value="Select">Select</option>
                        <option value="Office">Office</option>
                        <option value="Work From Home">Work From Home</option>
                        <option value="Client Office">Client Office</option>
                      </select>
                      <div className="flex items-center mb-4">
                        <h2 className="text-black font-serif font-bold text-xl mr-2">
                          Comments
                        </h2>
                      </div>
                      <textarea
                        value={comments}
                        onChange={(e) => setComments(e.target.value)}
                        className="w-full mb-4 border border-black rounded-lg p-2 text-black"
                        placeholder="Comments"
                      ></textarea>
                      <div className="flex justify-center">
                        {isLoading ? (
                          <div className="inline-block">
                            <div className="text-blue-400 bg-gradient-to-r from-blue-700 to-transparent bg-clip-text animate-pulse text-2xl">
                              Loading...
                            </div>
                          </div>
                        ) : (
                          <button
                            id="signInButton"
                            className={`${
                              dropdownValue === "" || dropdownValue === "Select"
                                ? "bg-gray-500 text-black cursor-not-allowed"
                                : "bg-blue-500 text-white cursor-pointer"
                            }   font-bold p-4 rounded-full w-[12rem] text-xl mr-4`}
                            onClick={handleDialogueCloseSignIn}
                            disabled={
                              dropdownValue === "Select" || dropdownValue === ""
                            }
                          >
                            Sign In
                          </button>
                        )}
                      </div>
                    </div>
                  </DialogContent>
                </Dialog>
              </>
            ) : (
              ((attendanceLogin === "LOGGEDIN" && !signOut) ||
                loggedIn === "LOGGEDIN" ||
                (attendanceLogin === "LOGGEDOUT" && signIn)) && (
                <>
                  <div className="flex justify-center  w-full">
                    <div className="flex flex-col justify-center items-center">
                      <div className="flex items-center mb-4 ">
                        <p className="  text-purple-800 text-[3.875rem] font-extrabold font-serif">
                          H{" "}
                          <span className="text-gray-700 text-[2.3rem] font-bold -ml-[1rem]">
                            ello,
                          </span>
                        </p>
                        <p className="text-[2.25rem] font-bold text-blue-600 font-serif mt-[1.5rem]">
                          {employeeName?.split("(")[0]}
                        </p>
                      </div>
                      <div className="flex gap-2 text-center">
                        <div className="border border-black p-6 rounded-lg shadow-md hover:shadow-lg transition-transform transform hover:scale-105 w-[45%] bg-blue-300 text-center flex flex-col justify-center">
                          <p className="text-2xl font-semibold text-gray-900 mb-6 font-serif text-center">
                            You are signed in {Location}{" "}
                            {formatDate(lastLoggedInTime)}
                          </p>
                          <div className="flex justify-center">
                            <button
                              className="bg-blue-600 rounded-full text-white font-semibold p-3 flex items-center justify-center w-[50%]"
                              onClick={handleDialogueOpen}
                            >
                              Sign Out
                            </button>
                          </div>
                        </div>
                        {isManager && <PaySlipEmployee isError={isError} />}
                        {isAdmin && (
                          <div className="border border-black p-6 rounded-lg shadow-md hover:shadow-lg transition-transform transform hover:scale-105 w-[45%] bg-blue-50 ml-6">
                            <div className="flex flex-col gap-4 ">
                              <p className="text-lg text-black text-center font-serif  font-bold">
                                Address:
                              </p>
                              <p className="text-lg text-gray-600 text-center  font-serif font-semibold">
                                {data?.address}
                              </p>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <Dialog
                    open={dialogOpen}
                    onClose={handleClose}
                    maxWidth="lg"
                    fullWidth
                    PaperProps={{
                      style: {
                        width: "30%",
                      },
                    }}
                  >
                    <div className="flex justify-between">
                      <h1 className="text-blue-500 font-bold font-serif text-2xl px-4 pt-4 pb-2">
                        Sign-Out Details
                      </h1>
                      <button
                        className="text-black font-bold text-3xl pl-1  pr-4 pt-4 pb-2 cursor-pointer"
                        onClick={handleClose}
                      >
                        X
                      </button>
                    </div>
                    <DialogContent>
                      <div className="bg-white p-1 rounded-lg">
                        <div className="flex">
                          <h1 className="text-black font-serif font-bold text-xl">
                            Sign-Out Location
                          </h1>
                          <span className="text-red-500 text-xl">*</span>
                        </div>
                        <select
                          value={dropdownValue}
                          onChange={(e) => setDropdownValue(e.target.value)}
                          className="w-full mb-4 border border-black rounded-lg p-2"
                        >
                          <option value="Select">Select</option>
                          <option value="Office">Office</option>
                          <option value="Work From Home">Work From Home</option>
                          <option value="Client Office">Client Office</option>
                        </select>
                        <h1 className="text-black font-serif font-bold text-xl">
                          Comments
                        </h1>
                        <textarea
                          value={comments}
                          onChange={(e) => setComments(e.target.value)}
                          className="w-full mb-4 border border-black rounded-lg p-2 text-black"
                          placeholder="Comments"
                        ></textarea>
                        <div className="flex justify-center">
                          {isLoading ? (
                            <div className="inline-block">
                              <div className="text-blue-400 bg-gradient-to-r from-blue-700 to-transparent bg-clip-text animate-pulse text-2xl">
                                Loading...
                              </div>
                            </div>
                          ) : (
                            <button
                              id="signOutButton"
                              className={`${
                                dropdownValue === "" ||
                                dropdownValue === "Select"
                                  ? "bg-gray-500 text-black cursor-not-allowed"
                                  : "bg-blue-500 text-white cursor-pointer"
                              }   font-bold p-4 rounded-full w-[12rem] text-xl mr-4`}
                              onClick={handleDialogueCloseSignOut}
                              disabled={
                                dropdownValue === "Select" ||
                                dropdownValue === ""
                              }
                            >
                              Sign Out
                            </button>
                          )}
                        </div>
                      </div>
                    </DialogContent>
                  </Dialog>
                </>
              )
            )}
          </div>
          { <SignInTrackingCard />}
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center h-screen bg-gray-100 relative">
          {(attendanceLogin === "LOGGEDOUT" && !signIn) ||
          loggedIn === "LOGGEDOUT" ? (
            <>
              <div className="flex justify-center w-full">
                <div className="flex flex-col justify-center items-center">
                  <div className="flex items-center mb-4">
                    <p className="text-purple-800 text-[3.875rem] font-extrabold font-serif">
                      H{" "}
                      <span className="text-gray-700 text-[2.3rem] font-bold -ml-[1rem]">
                        ello,
                      </span>
                    </p>
                    <p className="text-[2.25rem] font-bold text-blue-600 font-serif mt-[1.5rem]">
                      {employeeName?.split("(")[0]}
                    </p>
                  </div>
                  <div className="flex gap-2">
                    <div className="border border-black p-6 rounded-lg shadow-md hover:shadow-lg transition-transform transform hover:scale-105 w-[45%] bg-blue-300 text-center flex flex-col justify-center">
                      <p className="text-2xl font-semibold text-gray-900 mb-6 font-serif text-center">
                        You are not signed in yet. Please sign in to record your
                        attendance.
                      </p>
                      <div className="flex justify-center">
                        <button
                          className="bg-blue-600 rounded-full text-white font-semibold p-3 flex items-center justify-center w-[50%]"
                          onClick={handleDialogueOpen}
                        >
                          Sign In
                        </button>
                      </div>
                    </div>
                    <PaySlipEmployee isError={isError} />
                  </div>
                </div>
              </div>
              <Dialog
                open={dialogOpen}
                onClose={handleClose}
                maxWidth="lg"
                fullWidth
                PaperProps={{
                  style: {
                    width: "30%",
                  },
                }}
              >
                <div className="flex justify-between items-center bg-blue-100 p-4">
                  <h1 className="text-blue-500 font-bold font-serif text-2xl">
                    Sign-In Details
                  </h1>
                  <button
                    className="text-black text-4xl cursor-pointer"
                    onClick={handleClose}
                  >
                    &times;
                  </button>
                </div>
                <DialogContent>
                  <div className="bg-white p-4 rounded-lg">
                    <div className="flex items-center mb-4">
                      <h2 className="text-black font-serif font-bold text-xl mr-2">
                        Sign-In Location
                      </h2>
                      <span className="text-red-500">*</span>
                    </div>
                    <select
                      value={dropdownValue}
                      onChange={(e) => setDropdownValue(e.target.value)}
                      className="w-full mb-4 border border-black rounded-lg p-2"
                    >
                      <option value="Select">Select</option>
                      <option value="Office">Office</option>
                      <option value="Work From Home">Work From Home</option>
                      <option value="Client Office">Client Office</option>
                    </select>
                    <div className="flex items-center mb-4">
                      <h2 className="text-black font-serif font-bold text-xl mr-2">
                        Comments
                      </h2>
                    </div>
                    <textarea
                      value={comments}
                      onChange={(e) => setComments(e.target.value)}
                      className="w-full mb-4 border border-black rounded-lg p-2 text-black"
                      placeholder="Comments"
                    ></textarea>
                    <div className="flex justify-center ml-6">
                      {isLoading ? (
                        <div className="inline-block">
                          <div className="text-blue-400 bg-gradient-to-r from-blue-700 to-transparent bg-clip-text animate-pulse text-2xl">
                            Loading...
                          </div>
                        </div>
                      ) : (
                        <button
                          id="signInButton"
                          className={`${
                            dropdownValue === "" || dropdownValue === "Select"
                              ? "bg-gray-500 text-black cursor-not-allowed"
                              : "bg-blue-500 text-white cursor-pointer"
                          } font-bold p-4 rounded-full w-[12rem] text-xl mr-4`}
                          onClick={handleDialogueCloseSignIn}
                          disabled={
                            dropdownValue === "Select" || dropdownValue === ""
                          }
                        >
                          Sign In
                        </button>
                      )}
                    </div>
                  </div>
                </DialogContent>
              </Dialog>
            </>
          ) : (
            ((attendanceLogin === "LOGGEDIN" && !signOut) ||
              loggedIn === "LOGGEDIN" ||
              (attendanceLogin === "LOGGEDOUT" && signIn)) && (
              <>
                <div className="flex justify-center w-full">
                  <div className="flex flex-col justify-center items-center">
                    <div className="flex items-center mb-4">
                      <p className="text-purple-800 text-[3.875rem] font-extrabold font-serif">
                        H{" "}
                        <span className="text-gray-700 text-[2.3rem] font-bold -ml-[1rem]">
                          ello,
                        </span>
                      </p>
                      <p className="text-[2.25rem] font-bold text-blue-600 font-serif mt-[1.5rem]">
                        {employeeName?.split("(")[0]}
                      </p>
                    </div>
                    <div className="flex gap-2">
                      <div className="border border-black p-6 rounded-lg shadow-md hover:shadow-lg transition-transform transform hover:scale-105 w-[45%] bg-blue-300 flex flex-col justify-center">
                        <p className="text-2xl font-semibold text-gray-900 mb-6 font-serif text-center">
                          You are signed in {Location}{" "}
                          {formatDate(lastLoggedInTime)}
                        </p>
                        <div className="flex justify-center">
                          <button
                            className="bg-blue-600 rounded-full text-white font-semibold p-3 flex items-center justify-center w-[50%]"
                            onClick={handleDialogueOpen}
                          >
                            Sign Out
                          </button>
                        </div>
                      </div>
                      <PaySlipEmployee isError={isError} />
                    </div>
                  </div>
                </div>
                <Dialog
                  open={dialogOpen}
                  onClose={handleClose}
                  maxWidth="lg"
                  fullWidth
                  PaperProps={{
                    style: {
                      width: "30%",
                    },
                  }}
                >
                  <div className="flex justify-between">
                    <h1 className="text-blue-500 font-bold font-serif text-2xl px-4 pt-4 pb-2">
                      Sign-Out Details
                    </h1>
                    <button
                      className="text-black font-bold text-3xl pl-1 pr-4 pt-4 pb-2 cursor-pointer"
                      onClick={handleClose}
                    >
                      X
                    </button>
                  </div>
                  <DialogContent>
                    <div className="bg-white p-1 rounded-lg">
                      <div className="flex">
                        <h1 className="text-black font-serif font-bold text-xl">
                          Sign-Out Location
                        </h1>
                        <span className="text-red-500 text-xl">*</span>
                      </div>
                      <select
                        value={dropdownValue}
                        onChange={(e) => setDropdownValue(e.target.value)}
                        className="w-full mb-4 border border-black rounded-lg p-2"
                      >
                        <option value="Select">Select</option>
                        <option value="Office">Office</option>
                        <option value="Work From Home">Work From Home</option>
                        <option value="Client Office">Client Office</option>
                      </select>
                      <h1 className="text-black font-serif font-bold text-xl">
                        Comments
                      </h1>
                      <textarea
                        value={comments}
                        onChange={(e) => setComments(e.target.value)}
                        className="w-full mb-4 border border-black rounded-lg p-2 text-black"
                        placeholder="Comments"
                      ></textarea>
                      <div className="flex justify-center">
                        {isLoading ? (
                          <div className="inline-block">
                            <div className="text-blue-400 bg-gradient-to-r from-blue-700 to-transparent bg-clip-text animate-pulse text-2xl">
                              Loading...
                            </div>
                          </div>
                        ) : (
                          <button
                            id="signOutButton"
                            className={`${
                              dropdownValue === "" || dropdownValue === "Select"
                                ? "bg-gray-500 text-black cursor-not-allowed"
                                : "bg-blue-500 text-white cursor-pointer"
                            } font-bold p-4 rounded-full w-[12rem] text-xl mr-4`}
                            onClick={handleDialogueCloseSignOut}
                            disabled={
                              dropdownValue === "Select" || dropdownValue === ""
                            }
                          >
                            Sign Out
                          </button>
                        )}
                      </div>
                    </div>
                  </DialogContent>
                </Dialog>
              </>
            )
          )}
        </div>
      )}
    </>
  );
};

export default EmployeeMessage;
