import React, { useEffect, useState, useRef } from 'react'
import { serviceUrl,genericHeaders } from '../../utils/apiConfig';
import axios from 'axios';
import {Dialog, DialogActions, DialogContent, Button } from '@mui/material';
import { DatePicker,LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { getCommentsValue, getLogHoursValue, generateDates, generateWeeks, handleCommentChange, handleHourChange } from './WorkLog3';
import WeekCard from './WeekCard';
import checkCircle from '../../Icons/CheckCircle.svg'
import { getToken } from '../../services/Token';
import Loader from "../customComponents/customLoader";

const AdminWorkLog = () => {
  const [employees, setEmployees] = useState([]);
  const [employeeFilter, setEmployeeFilter] = useState({
    employeeId: "",
    date: "",
  });
  const [dataNotAvailable, setDataNotAvailable] = useState();
  const [daysVisible, setDaysVisible] = useState([]);
  const [weekData, setWeekData] = useState([]);
  const [datesData, setDatesData] = useState([]);
  const [timeSheet, setTimeSheet] = useState();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [projects, setProjects] = useState([]);
  const approvalStatus = useRef("");
  const timesheetId = useRef("");
  const empIdfromLocalStorage = localStorage.getItem("Id");
  const [projectId, setProjectId] = useState([]);

  const toggleDaysVisibility = (index) => {
    setDaysVisible((prev) => {
      const newData = [...prev];
      newData[index] = !newData[index];
      return newData;
    });
  };

  const getEmployeeData = async () => {
    const role = localStorage.getItem("roles");
    const url =
      role === "MANAGER"
        ? `${serviceUrl}/api/employee/fetchemployeeslistformanager?managerId=${empIdfromLocalStorage}`
        : `${serviceUrl}/api/employee/getEmployeesList`;
    try {
      let response = await axios.get(url, { headers: genericHeaders() });
      if (role === "MANAGER") {
        setEmployees(transformData(response.data));
      } else {
        setEmployees(response.data);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const getProjectData = async () => {
    setLoading(true);
    const Id = getToken("Id");
    try {
      let response = await axios.get(
        `${serviceUrl}/api/project/getAllProjects`,
        { headers: genericHeaders() }
      );
      if (response.status === 200) {
        setProjects(response.data);
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  };

  const transformData = (data) => {
    let transformedData = Object.keys(data).map((key) => ({
      id: key,
      name: data[key],
    }));
    return transformedData;
  };

  const getProjectNamesByIds = (workLog, projects) => {
    return workLog.map((log) => {
      return log.projectIds
        .map((id) => {
          const project = projects.find((project) => project._id === id);
          return project ? project.project : null; // Return project name or null
        })
        .filter(Boolean); // Filter out any null values
    });
  };

   const handleProjects = (evt, index) => {
     const selectedValues = Array.isArray(evt.target.value)
       ? evt.target.value
       : [evt.target.value];
     const updatedProjectIds = [...projectId];
     updatedProjectIds[index] = selectedValues;
     setProjectId(updatedProjectIds);
   };

  const getProjectDataByEmpId = async () => {
    setLoading(true);
    const Id = getToken("Id");
    try {
      let response = await axios.get(
        `${serviceUrl}/api/project/getprojectsbyempid?employeeId=${Id}`,
        { headers: genericHeaders() }
      );
      if (response.status === 200) {
        setLoading(false);
        setProjects(response.data);
      }
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  };

  const getEmployeelog = async (payload) => {
    try {
      let response = await axios.post(
        `${serviceUrl}/api/workLogManagement/getworklogbyfilter`,
        payload,
        { headers: genericHeaders() }
      );
      if (response.status === 200) {
        if (
          response.data.employeeWorkLog !== null &&
          response.data.employeeWorkLog.length > 0
        ) {
          setTimeSheet(response.data);
          timesheetId.current = response.data._id;
          setDataNotAvailable(false);
          const project = getProjectNamesByIds(
            response.data.employeeWorkLog,
            projects
          );
          const dataForApi = response.data?.employeeWorkLog?.map(
            (item) => item.projectIds
          );
          if (getIsEditable()) {
            setProjectId(project);
          } else {
            if (dataForApi?.length > 0) {
              setProjectId(project);
            } else {
              setProjectId([]);
            }
          }
           
        } else {
          setTimeSheet(null);
          setDataNotAvailable(true);
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleFilter = (evt) => {
    let newData = { ...employeeFilter };
    if (evt?.target?.name === "employeeId") {
      newData.employeeId = evt.target.value;
    } else {
      let date = new Date(evt);
      date.setHours(0, 0, 0, 0);
      let selectedDate = new Date(
        date.getTime() - date.getTimezoneOffset() * 60000
      );
      newData.date = selectedDate.toISOString();
      getProjectDataByEmpId();
      setProjectId([]);
    }
    setEmployeeFilter(newData);
    if (newData.employeeId !== "" && newData.date !== "") {
      getEmployeelog(newData);
    }
  };

  const getApprovalStatus = (status) => {
    if (status === "approve") {
      return "APPROVED";
    } else if (status === "reject") {
      return "REJECTED";
    }
  };

  const getProjectID = (projectData) => {
    let projectNames = [...projects];
    return projectData.map((item) => {
      const project = projectNames.find((proj) => proj.project === item);
      return project ? project._id : null;
    });
  };

  const handleApprove = async () => {
    setLoading(true);
    let payload = { ...timeSheet };
    payload.status = getApprovalStatus(approvalStatus.current);
    payload.approvedDate = new Date();
    payload.isApprovedBy = getToken("Id");
    payload.projectId = getProjectID(payload.projectId);
    approvalStatus.current === "reject"
      ? (payload.isSubmitted = false)
      : (payload.isSubmitted = true);
    setLoading(false);
    try {
      let url = `${serviceUrl}/api/workLogManagement/updateworklog?workLogId=${timesheetId.current}`;
      let response = await axios.put(url, payload, {
        headers: genericHeaders(),
      });
      if (response.status === 200) {
        getEmployeelog(employeeFilter);
        setLoading(false);
        setDialogOpen(false);
      }
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  };

  const maxDate = dayjs().endOf("month");

  const displayMonth = dayjs(employeeFilter.date).format("MMMM");

  const handleDialog = (status) => {
    approvalStatus.current = status;
    setDialogOpen(true);
  };

  const handleDialogCommentChange = (evt) => {
    let newData = { ...timeSheet };
    newData.comments = evt?.target?.value;
    setTimeSheet(newData);
  };

  const getIsEditable = () => {
    if (timeSheet?.status === "SUBMITTED") {
      return true;
    }
    return false;
  };

  const renderContent = () => {
    if (dataNotAvailable) {
      return (
        <p className="text-center text-2xl mt-44">
          Data not available for the selected month
        </p>
      );
    }

    if (
      !employeeFilter?.employeeId ||
      !employeeFilter?.date ||
      weekData.length === 0
    ) {
      return (
        <p className="text-center mt-20 text-3xl">
          Please select the employee and the month.
        </p>
      );
    }
    return (
      <>
        <h2 className="text-center font-semibold text-2xl my-5">
          Time Sheet for the month of {displayMonth}
        </h2>

        <div className="flex mx-auto justify-end w-[90%] my-2">
          {/* <div className="w-1/2">
            <p className="text-2xl whitespace-normal">
              Projects: <span>{timeSheet?.projectId.join(",")}</span>
            </p>
          </div> */}
          {timeSheet?.status !== "DRAFT" ? (
            <>
              <div className="flex">
                <p className="text-2xl">Status: {timeSheet?.status}</p>
              </div>
            </>
          ) : (
            <div className="text-xl text-red-600">Yet to submit</div>
          )}
        </div>

        {weekData.map((item, weekIndex) => (
          <WeekCard
            key={weekIndex}
            item={item}
            isEditable={getIsEditable()}
            daysVisible={daysVisible}
            weekIndex={weekIndex}
            toggleDaysVisibility={toggleDaysVisibility}
            datesData={datesData}
            getLogHoursValue={getLogHoursValue}
            getCommentsValue={getCommentsValue}
            timeSheet={timeSheet}
            setTimeSheet={setTimeSheet}
            month={employeeFilter.date}
            handleCommentChange={handleCommentChange}
            handleHourChange={handleHourChange}
            handleSubmit={handleApprove}
            isAdmin={true}
            projectId={projectId}
            projects={projects}
            handleProjects={handleProjects}
          />
        ))}

        {timeSheet?.status === "SUBMITTED" ? (
          <div className={`flex justify-end mx-auto w-4/5 mt-7 space-x-3`}>
            <button
              className="bg-red-500 hover:bg-white hover:text-red-500 border border-red-500 text-white font-semibold py-1 px-2 rounded"
              onClick={() => handleDialog("reject")}
            >
              Reject
            </button>
            <button
              className="bg-green-500 hover:bg-white hover:text-green-500 border border-green-500 text-white font-semibold py-1 px-2 rounded"
              onClick={() => handleDialog("approve")}
            >
              Approve
            </button>
          </div>
        ) : (
          <></>
        )}
      </>
    );
  };

  useEffect(() => {
    getEmployeeData();
    getProjectData();
  }, []);

  useEffect(() => {
    if (employeeFilter.date && employeeFilter.employeeId) {
      const weeks = generateWeeks(employeeFilter.date);
      const days = generateDates(weeks);
      setWeekData(weeks);
      setDatesData(days);
      setDaysVisible(Array(weeks.length).fill(false));
    }
  }, [employeeFilter.date, employeeFilter.employeeId]);

  return (
    <>
      {loading && <Loader />}
      <section className="mt-20">
        <div className="mt-28 mx-10">
          <section className="flex space-x-5">
            <div className="flex flex-row w-1/2">
              <h4 className="text-lg my-auto">Select Employee: </h4>
              <select
                name="employeeId"
                value={employeeFilter.employeeId}
                onChange={handleFilter}
                className={`border border-slate-300 rounded-md py-1 h-8 px-3 w-60 ml-5`}
              >
                <option disabled={true} value="">
                  -- Select an option --
                </option>
                {employees.map((option) => (
                  <option key={option.id} value={option.id}>
                    {option.name}
                  </option>
                ))}
              </select>
            </div>
            {employeeFilter.employeeId !== "" ? (
              <div className="flex flex-row w-1/2">
                <h4 className="text-lg my-auto">Select Month: </h4>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    value={
                      employeeFilter.date ? dayjs(employeeFilter.date) : null
                    }
                    openTo="month"
                    views={["month", "year"]}
                    onChange={handleFilter}
                    maxDate={maxDate}
                    sx={{
                      maxHeight: 30,
                      width: "15rem",
                      bgcolor: "white",
                      marginTop: "auto",
                      marginBottom: "auto",
                      marginLeft: "1.25rem",
                      "& .MuiInputBase-root": {
                        height: 30,
                      },
                      borderRadius: "0.375rem",
                    }}
                  />
                </LocalizationProvider>
              </div>
            ) : (
              <></>
            )}
          </section>

          <section className="mb-5 space-y-5 mx-auto">
            {renderContent()}
          </section>
        </div>
      </section>
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent className="space-y-3">
          <p className="text-2xl font-semibold">
            Are you sure you want to {approvalStatus.current} this timesheet?
          </p>
          <div className="w-full">
            <p className="text-base">
              Please add your comments here, if you have any.
            </p>
            <textarea
              value={timeSheet?.comments}
              onChange={handleDialogCommentChange}
              className="border w-full rounded-md px-2"
              placeholder="Enter your comments here"
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)}>CANCEL</Button>
          <Button onClick={() => handleApprove()}>
            {String(approvalStatus.current).toUpperCase()}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default AdminWorkLog
