import React from "react";
import { serviceUrl, genericHeaders } from "../../../utils/apiConfig";
import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";

const EmployeeLetters = () => {
  const [allEmpLetters, setAllEmpLetters] = useState([]);

  const getAllEmployeeLetters = async () => {
    try {
      const id = localStorage.getItem("Id");
      const url = `${serviceUrl}/api/empLetterMgmt/getEmpLetters?employeeId=${id}`;
      const res = await axios.get(url, {
        headers: genericHeaders(),
      });

      if (res.status === 200) {
        const data = res.data.filter((item) => item.isActive === true);
        setAllEmpLetters(data);
      }
    } catch (error) {
      console.log(error, "error123");
    }
  };

  useEffect(() => {
    getAllEmployeeLetters();
  }, []);

  const handleDownload = async (url) => {
    const fileUrl = url;
  

    try {
      const response = await fetch(fileUrl);
      const blob = await response.blob();
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      const fileName = fileUrl?.substring(fileUrl.lastIndexOf("/") + 1);
      link.download = fileName;
      link.click();
    } catch (error) {
      console.error("Error downloading the file:", error);
    }
  };

  return (
    <div className="mt-24 ml-6">
      <div className="w-4/5">
        {allEmpLetters &&
          allEmpLetters.length > 0 ?
          (
          allEmpLetters.map((item, i) => {
            return (
              <div className="flex justify-between border my-2 px-4 py-2 rounded-md bg-slate-50 border-zinc-400">
                <p id={item._id} className="text-black">
                  {item.document.fileName}
                </p>
                <div className="flex items-center">
                  <svg
                    title="Download Letter Template"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6 cursor-pointer"
                    onClick={() => handleDownload(item.document.resourceName)}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3"
                    />
                  </svg>
                </div>
              </div>
            );
          })): (<p className="text-center text-xl mt-56">No Letters Available</p>)}
      </div>
    </div>
  );
};

export default EmployeeLetters;
