import React from "react";
import { serviceUrl, genericHeaders } from "../../../utils/apiConfig";
import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import Select from "../../customComponents/customSelect";
import { CircularProgress } from "@mui/material";

const EmployeesAllLetters = () => {
  const [allEmpLetters, setAllEmpLetters] = useState([]);
  const [filters, setFilters] = useState({
    employeeId: null,
    departmentId: null,
    isFilterApplied: false,
  });
  const [isFilterApplied, setIsFilterApplied] = useState({
    department: false,
    employee: false,
  });
  const [allDepartments, setAllDepartments] = useState([]);
  const [allEmployees, setAllEmployess] = useState([]);
  const [loading, setLoading] = useState(false);

  const getAllEmployeeLetters = async () => {
    setLoading(true);
    try {
      let queryParams = {
        ...filters,
      };
      const queryString = Object.keys(queryParams)
        .map(
          (key) =>
            `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`
        )
        .join("&");
      const url = `${serviceUrl}/api/empLetterMgmt/getAllEmpsLetters`;
      let payload = {
        isFilterApplied: filters.isFilterApplied,
        employeeId: filters.employeeId,
        departmentId: filters.departmentId,
      };
      const res = await axios.post(url, payload, {
        headers: genericHeaders(),
      });

      if (res.status === 200) {
        const data = res.data.filter((item) => item.isActive === true);
        setAllEmpLetters(data);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error, "error123");
    }
  };

  const getAllDepartments = async () => {
    try {
      const url = `${serviceUrl}/api/department/getAll`;
      const res = await axios.get(url, {
        headers: genericHeaders(),
      });

      if (res.status === 200) {
        setAllDepartments(res.data);
      }
    } catch (error) {
      console.log(error, "error123");
    }
  };

  const getAllEmployees = async () => {
    try {
      const url = `${serviceUrl}/api/employee/getEmployeesList`;
      const res = await axios.get(url, {
        headers: genericHeaders(),
      });

      if (res.status === 200) {
        setAllEmployess(res.data);
      }
    } catch (error) {
      console.log(error, "error123");
    }
  };
  useEffect(() => {
    getAllDepartments();
    getAllEmployees();
  }, []);

  useEffect(() => {
    getAllEmployeeLetters();
  }, [filters]);

  const employeesOptions =
    allEmployees && allEmployees.length > 0
      ? allEmployees.map((item) => {
          return { value: item.id, label: item.name };
        })
      : [];

  const departmentOptions =
    allDepartments && allDepartments.length > 0
      ? allDepartments.map((item) => {
          return { value: item._id, label: item.departmentName };
        })
      : [];

  const handleSelectChange = (e) => {
    const { value, name } = e.target;
    let filter = { ...filters };
    if (value !== "") {
      filter.isFilterApplied = true;
      filter[name] = value;
    } else {
      filter.isFilterApplied = false;
      filter[name] = null;
    }
    if (name === "employeeId") {
      filter["departmentId"] = null;
    }
    if (name === "departmentId") {
      filter["employeeId"] = null;
    }
    setFilters(filter);
  };

  const handleDownload = async (url) => {
    const fileUrl = url;

    try {
      const response = await fetch(fileUrl);
      const blob = await response.blob();
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      const fileName = fileUrl.substring(fileUrl.lastIndexOf("/") + 1);
      link.download = fileName;
      link.click();
    } catch (error) {
      console.error("Error downloading the file:", error);
    }
  };

  return (
    <div className="mt-24 ml-6">
      <div className="flex">
        <div className="mr-6">
          <Select
            label="Select"
            name="none"
            options={[
              { value: "none", label: "None" },
              { value: "employee", label: "Employee" },
              { value: "department", label: "department" },
            ]}
            value={filters.none}
            onChange={(e) => {
              if (e.target.value == "department") {
                setIsFilterApplied({ department: true });
              } else if (e.target.value == "employee") {
                setIsFilterApplied({ employee: true });
              } else {
                setIsFilterApplied({});
              }
            }}
            isMandatory={false}
            disabled={false}
          />
        </div>

        {isFilterApplied.employee && (
          <Select
            label="Employee"
            name="employeeId"
            options={employeesOptions}
            value={filters.employeeId}
            onChange={handleSelectChange}
            isMandatory={true}
            disabled={false}
          />
        )}
        {isFilterApplied.department && (
          <Select
            label="Department"
            name="departmentId"
            options={departmentOptions}
            value={filters.departmentId}
            onChange={handleSelectChange}
            isMandatory={true}
          />
        )}
      </div>
      <div className="w-4/5">
        {loading ? (
          <div className="flex justify-center mt-14">
            <CircularProgress />
          </div>
        ) : allEmpLetters && allEmpLetters.length > 0 ? (
          allEmpLetters.map((item, i) => (
            <div
              key={item._id}
              className="flex justify-between border my-2 px-4 py-2 rounded-md bg-slate-50 border-zinc-400"
            >
              <p id={item._id} className="text-black">
                {item.document.fileName || "N/A"}
              </p>
              <button
                onClick={() => handleDownload(item.document.resourceName)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="16"
                  width="16"
                  viewBox="0 0 512 512"
                >
                  <path d="M216 0h80c13.3 0 24 10.7 24 24v168h87.7c17.8 0 26.7 21.5 14.1 34.1L269.7 378.3c-7.5 7.5-19.8 7.5-27.3 0L90.1 226.1c-12.6-12.6-3.7-34.1 14.1-34.1H192V24c0-13.3 10.7-24 24-24zm296 376v112c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V376c0-13.3 10.7-24 24-24h146.7l49 49c20.1 20.1 52.5 20.1 72.6 0l49-49H488c13.3 0 24 10.7 24 24zm-124 88c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20zm64 0c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20z" />
                </svg>{" "}
              </button>
            </div>
          ))
        ) : (
          <div className="flex flex-col justify-center items-center mt-14">
            <h1>0 Results Found</h1>
            <h3>Sorry! We couldn't find any results</h3>
          </div>
        )}
      </div>
    </div>
  );
};

export default EmployeesAllLetters;
