import React,{useState} from 'react'
import { ChevronDoubleDownIcon, ChevronDoubleUpIcon } from "@heroicons/react/24/solid"
import { Dialog, DialogContent, DialogActions, Button } from '@mui/material';
import ViewDetailsOfHistory from './ViewDetailsOfHistory';
import dayjs from 'dayjs';

const LeaveComponent = (props) => {
    const [detailsVisible,setDetailsVisible] = useState(false);
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };

    const DetailsVisibility = () => {
      setDetailsVisible(!detailsVisible);
    }
  
    return (
      <>
        <Dialog open={open} onClose={handleClose} maxWidth="xl" fullWidth>
          <DialogContent>
            <ViewDetailsOfHistory data={props.data} />
          </DialogContent>
          <DialogActions className="my-2 m-5">
            <Button onClick={handleClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
        <div className="w-11/12 my-10 mx-auto border border-slate-300 rounded-xl">
          <section
            className={`p-1 w-full flex justify-between ${
              detailsVisible ? "border-b-[1px]  border-slate-300" : ""
            }`}
          >
            <div className="grid grid-cols-4 gap-10 w-11/12 ">
              {props.data.role === "Manager" ? (
                <>
                  <div className="my-auto mx-5">
                    <p className="text-xl">{props?.data.employeeName}</p>
                  </div>
                  <div className="my-auto mx-5">
                    <p>Category</p>
                    <p className="text-xl">{props?.data.leaveCategory}</p>
                  </div>
                </>
              ) : (
                <>
                  <div className="my-auto mx-5">
                    <p>Category</p>
                    <p className="text-xl">{props?.data.leaveCategory}</p>
                  </div>
                  <div className="my-auto mx-5">
                    <p>Holiday</p>
                    <p className="text-xl">{props?.data.leaveCategory}</p>
                  </div>
                </>
              )}
              <div className="my-auto mx-5">
                <p>No. of days</p>
                <p className="text-xl">{props?.data.days}</p>
              </div>
              <div className="my-auto mx-5">
                <p
                  className={`${
                    props?.data.status === "APPROVED"
                      ? "text-blue-500"
                      : "text-red-600"
                  } text-xl`}
                >
                  {props?.data.status}
                </p>
              </div>
            </div>
            <div className="my-auto items-end w-1/12">
              {detailsVisible ? (
                <ChevronDoubleUpIcon
                  className="h-7 text-black cursor-pointer"
                  onClick={DetailsVisibility}
                />
              ) : (
                <ChevronDoubleDownIcon
                  className="h-7 text-black cursor-pointer"
                  onClick={DetailsVisibility}
                />
              )}
            </div>
          </section>
          <div
            className={`overflow-hidden transition-transform transform ${
              detailsVisible ? "max-h-screen" : "max-h-0"
            }`}
          >
            <section
              className={`w-full p-1 flex flex-row justify-between ${
                detailsVisible ? "border-b-[1px] border-slate-300" : ""
              }`}
            >
              <div className="my-auto mx-5">
                <p>
                  <span className="text-l">Duration :</span>
                  {dayjs(props?.data.fromDate).format("DD MMM YYYY") 
                  + " - " +
                    dayjs(props?.data.toDate).format("DD MMM YYYY")}
                </p>
                <p>
                  <span className="text-l">Remarks :</span>{" "}
                  {props?.data.leaveReason}{" "}
                </p>
              </div>
            </section>

            <section className="w-full flex flex-row justify-between p-1">
              <div className="my-auto mx-5">
                <p>Applied On</p>
                <p className="text-l">{dayjs(props?.data.appliedOn).format("DD MMM YYYY")}</p>
              </div>
              <div className="my-auto mx-5 flex space-x-5 text-blue-500 ">
                <p className="cursor-pointer text-xl" onClick={handleClickOpen}>
                  View Details
                </p>
                {props?.data.button === "Pending" ? (
                  <p
                    className="cursor-pointer text-xl"
                    onClick={() => props.withdraw(props?.data._id)}
                  >
                    Withdraw
                  </p>
                ) : (
                  ""
                )}
                {props?.data.button === "Approve" ? (
                  <p
                    className="cursor-pointer text-xl"
                    onClick={() => props.reject(props?.data._id)}
                  >
                    Reject
                  </p>
                ) : (
                  ""
                )}
                {props?.data.button === "Approve" ? (
                  <p
                    className="cursor-pointer text-xl"
                    onClick={() => props.approve(props?.data._id)}
                  >
                    Approve
                  </p>
                ) : (
                  ""
                )}
              </div>
            </section>
          </div>
        </div>
      </>
    );
}

export default LeaveComponent