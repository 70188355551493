import React,{useState,useEffect} from 'react'
import axios from 'axios'
import { genericHeaders, serviceUrl } from '../utils/apiConfig';
import NoContent from '../Icons/No-Content-Laptop.svg'  
import LeaveComponent from './LeaveComponent';
import Loader from "../Components/customComponents/customLoader"

const ClosedLeaves = () => {

    const [leaveData,setLeaveData] = useState([]);
    const [historyData,setHistoryData] = useState([]);
    const [employees,setEmployees] = useState([]);
    const [data,setData] = useState([]);
    const [loading,setLoading] = useState(false);

    const getActiveLeaves = async () => {
        setLoading(true);
        try {
          let response = await axios.get(
            `${serviceUrl}/api/leaveTransaction/reviewedLeaveTransactions`,
            { headers: genericHeaders() });
          if (response.status === 200) {
            setData(response.data);
            setLoading(false);
          }
        } catch (err) {
          setLoading(false);
          console.error(err);
        }
    };
    const getLeaveData = async() => {
        try{
          let response = await axios.get(`${serviceUrl}/api/leaveCategory/getAllLeaveCategories`,{headers: genericHeaders()});
          if(response.status === 200){
            setLeaveData(response.data);
          }

        }catch(err){
            console.error(err)
        }
    }
    const getEmployees = async() => {
      try{
        let response = await axios.get(`${serviceUrl}/api/employee/getEmployeesList`,{headers: genericHeaders()})
        if(response.status === 200){
          setEmployees(response.data);
        }
      } catch(err){
        console.error(err);
      }
    }
    useEffect(() => {
      getActiveLeaves(); 
      getLeaveData();
      getEmployees();
  }, [])
  useEffect( () => {
      if(data && leaveData){
       let newData = matchData(data,leaveData,employees);
       setHistoryData(newData);
      }
   },[data,leaveData,employees])
     const matchData = (employeeLeaves, leaveData, empData) => {
      let data = [];
      if (employeeLeaves && leaveData) {
        employeeLeaves.map( (item) => {
            let matchedLeave = leaveData.find( (leave) => leave._id === item.leaveType);
            if(matchedLeave){
              item.leaveCategory = matchedLeave.leaveCategory;
            }
            let matchedEmployee = empData.find( (emp) => emp.id === item.employeeId);
            if(matchedEmployee){
             item.employeeName = matchedEmployee.name;
            }
            if(item.declinerName !== null){
              let matchedEmployee = empData.find( (emp) => emp.id === item.declinerName);
              if(matchedEmployee){
                item.declinerName = matchedEmployee.name;
              }
            }
            if(item.approverName !== null){
              let matchedEmployee = empData.find( (emp) => emp.id === item.approverName);
              if(matchedEmployee){
                item.approverName = matchedEmployee.name;
              }
            }
            item.role = "Manager"
            data.push(item);
        })
      }
      return data;
    };
  return (
    <div>
      {loading && (<Loader/>)}
      <div>
        {historyData.length > 0 ? (
          historyData.map((item) => <LeaveComponent data={item} />)
        ) : (
          <div className="mt-[15%] flex flex-col items-center justify-center">
            <img src={NoContent} />
            <p className="text-xl">
              No historical records of leave transactions are available.
            </p>
          </div>
        )}
      </div>
    </div>
  );
}

export default ClosedLeaves