import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { getToken } from '../services/Token';
import { genericHeaders, serviceUrl } from '../utils/apiConfig';
import LeaveComponent from './LeaveComponent';
import NoContent from '../Icons/No-Content-Laptop.svg'
import { Alert, Snackbar } from "@mui/material";   
import Loader from "../Components/customComponents/customLoader" 

const LeavesPending = () => {
    const [data,setData] = useState([]);
    const [leaveData,setLeaveData] = useState([]);
    const [pendingData,setPendingData] = useState([])
    const [snack, setSnack] = useState(false);
    const [loading,setLoading] = useState(false);
    const Id = getToken('Id');
    const getData = async () => {
      setLoading(true);
      try {
        let response = await axios.get(
          `${serviceUrl}/api/leaveTransaction/getEmpPendingLeaveTrans?employeeId=${Id}`,
          { headers: genericHeaders() }
        )
        if(response.status === 200){
            setData(response.data);
            setLoading(false);
        }
      } catch (err) {
        console.error(err);
      }
    };
    const getLeaveData = async() => {
        try{
          let response = await axios.get(`${serviceUrl}/api/leaveCategory/getAllLeaveCategories`,{headers: genericHeaders()});
          if(response.status === 200){
            setLeaveData(response.data);
          }

        }catch(err){
            console.error(err)
        }
    }
    useEffect(() => {
      getData();
      getLeaveData();
    }, []);
    useEffect( () => {
       if(data && leaveData){
        let newData = matchData(data,leaveData);
        setPendingData(newData);
       }
    },[data,leaveData])
    const matchData = (employeeLeaves, leaveData) => {
      let data = [];
      if (employeeLeaves && leaveData) {
        let matchedData = employeeLeaves.map( (item) => {
            let matchedLeave = leaveData.find( (leave) => leave._id === item.leaveType);
            if(matchedLeave){
              item.leaveCategory = matchedLeave.leaveCategory;
            }
            item.button = "Pending";
            data.push(item);
        })
      }
      return data;
    };
    const handleWithdraw = async (id) => {
       try{
        let response = await axios.put(`${serviceUrl}/api/leaveTransaction/leaveCancelledByEmp?leaveTransactionId=${id}`,null,{headers : genericHeaders()})
        if(response.status === 204){
            getData();
            handleSnackOpen();
        }
       }catch(err){
        console.error(err)
       }
    }
    const handleSnackOpen = () => {
        setSnack(true);
      };
      const handleSnackClose = () => {
        setSnack(false);
      };
    return (
      <div>
        {loading && (<Loader/>)}
        <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        open={snack}
        autoHideDuration={3000}
        onClose={handleSnackClose}
      >
        <Alert severity="success" variant="filled" onClose={handleSnackClose}>
          Leave Withdrawn
        </Alert>
      </Snackbar>
        {pendingData.length > 0 ? (
          pendingData.map((item) => (
            <LeaveComponent data={item} withdraw={handleWithdraw} />
          ))
        ) : (
          <div className="mt-[15%] flex flex-col items-center justify-center">
            <img src={NoContent} />
            <p className='text-xl'>There are no pending records of any leave transaction</p>
          </div>
        )}
      </div>
    );
}
export default LeavesPending