import React from "react";
import { Link } from "react-router-dom";

const Breadcrumbs = ({ paths }) => {
  return (
    <nav className="text-gray-600 mb-4 ml-6" aria-label="Breadcrumb">
      <ol className="list-none p-0 inline-flex">
        {paths.map((path, index) => (
          <li key={index+1} className="flex items-center">
            <Link to={path.url} className="hover:underline text-black font-semibold text-2xl">
              {path.name}
            </Link>
            {index < paths.length - 1 && (
              <svg
                className="fill-current w-6  h-9 mx-3"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
              >
                <path d="M8.59 16.58L13.17 12 8.59 7.41 10 6l6 6-6 6z" />
              </svg>
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
};

export default Breadcrumbs;
