import dayjs from 'dayjs';
import React, { useEffect,useState,useRef } from 'react';
import { FormControl,Select,Checkbox,MenuItem,ListItemText } from '@material-ui/core';
import { Alert, Snackbar, Dialog, DialogActions, DialogContent, Button } from '@mui/material';
import { getToken } from '../../services/Token';
import Loader from "../customComponents/customLoader";
import axios from 'axios';
import { serviceUrl,genericHeaders } from '../../utils/apiConfig';
import checkCircle from '../../Icons/CheckCircle.svg'
import WeekCard from './WeekCard';
import { DatePicker,LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useGetEmployeesListQuery } from '../../api/getEmployeeList';

const timesheetStructure = {
    "isSubmitted": "",
    "get_id": "", 
    "isActive": true,
    "createdDate": "",
    "projectId": [],
    "employeeWorkLog": [],
    "currentMonth": "",
    "updatedDate":"",
    "employeeId": "", 
    "isApprovedBy": "",
    "status": "",
    "approvedDate": "",
    "comments": ""
    }
const dailyLog = {
    "fromDate": "",
    "toDate": "",
    "comment": "",
    "logHours": "",
    "projectIds":[]
}

const WorkLog = () => {

    const [dialogOpen,setDialogOpen] = useState(false);
    const [daysVisible,setDaysVisible] = useState([]);
    const [timeSheet,setTimeSheet] = useState();
    const [weekData,setWeekData] = useState([]);
    const [datesData,setDatesData] = useState([]);
    const [projectId,setProjectId] = useState([]);
    const [projects,setProjects] = useState([]);
    const [isSubmitted,setIsSubmitted] = useState(false);
    const [loading, setLoading] = useState(false);
    const [date,setDate] = useState(getIstTime(new Date()));
    const [snack, setSnack] = useState({
      open: false,
      error: false,
      message: "",
    });

    const { data: employeeListData, isSuccess: employeeListSuccess } = useGetEmployeesListQuery();

    const timesheetId = useRef('');

 const handleProjects = (evt, index) => {
   const selectedValues = Array.isArray(evt.target.value)
     ? evt.target.value
     : [evt.target.value];
   const updatedProjectIds = [...projectId];
   updatedProjectIds[index] = selectedValues;
   setProjectId(updatedProjectIds);
 };

    const addLogs = (customMonthIsoString) => {
        let customMonth;
        if (customMonthIsoString !== undefined) {
          customMonth = new Date(customMonthIsoString)
        } else {
          customMonth = new Date();
        }
        let newData = timesheetStructure;
        const firstOfMonth = new Date(customMonth.getFullYear(), customMonth.getMonth(), 1);
        const lastDayOfMonth = new Date(customMonth.getFullYear(), customMonth.getMonth() + 1, 0);
        let date = new Date(firstOfMonth);
        newData.employeeWorkLog = [];
        while (date <= lastDayOfMonth) {
            if (date.getDay() !== 0) { // 0 is Sunday
                let dayLog = { ...dailyLog }; // assuming dailyLog is an object
                const fromDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
                const toDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000 + 86399999); // end of the day
                dayLog.fromDate = fromDate.toISOString();
                dayLog.toDate = toDate.toISOString();
                dayLog.logHours = "0";
                newData.employeeWorkLog.push(dayLog);
            }
            date.setDate(date.getDate() + 1);
        }
        setIsSubmitted(false);
        setTimeSheet(newData); 
    };

    const handleProjectToggle = (projectId) => () => {
      const currentIndex = projectId.indexOf(projectId);
      const newProjectIds = [...projectId];
      if (currentIndex === -1) {
        newProjectIds.push(projectId);
      } else {
        newProjectIds.splice(currentIndex, 1);
      }
      setProjectId(newProjectIds);
    };

    const getProjectData = async () => {
      setIsSubmitted(false);
      setLoading(true);
      const Id = getToken("Id");
      try {
        let response = await axios.get(
          `${serviceUrl}/api/project/getprojectsbyempid?employeeId=${Id}`,
          { headers: genericHeaders() }
        );
        if (response.status === 200) {
          setProjects(response.data);
          getDataOfCurrentMonth(response.data);
        }
      } catch (err) {
        setLoading(false);
        console.error(err);
      }
    };
    
    const handleSnackOpen = (open, error, message) => {
      let snackData = { ...snack };
      snackData.open = open;
      snackData.error = error;
      snackData.message = message;
      setSnack(snackData);
    };

    const handleSnackClose = () => {
      let data = {
        open: false,
        error: false,
        message: "",
      };
      setSnack(data);
    };

    const toggleDaysVisibility = (index) => {
        setDaysVisible((prev) => {
            const newData = [...prev];
            newData[index] = !newData[index];
            return newData;
        });
    };
      const getProjectNamesByIds = (workLog, projects) => {
        return workLog.map((log) => {
          return log.projectIds
            .map((id) => {
              const project = projects.find((project) => project._id === id);
              return project ? project.project : null; // Return project name or null
            })
            .filter(Boolean); // Filter out any null values
        });
      };

    const getDataOfCurrentMonth = async (allProjects) => {
        setLoading(false);
        let Id = getToken('Id')
        let projectsData = [...allProjects]
        let payload = {
            "employeeId": Id,
            "date": date
          }
        try {
          let response = await axios.post(
            `${serviceUrl}/api/workLogManagement/getworklogbyfilter`,
            payload,
            { headers: genericHeaders() }
          );
          if (response.status === 200) {
            let data = response.data;
            if (!data?.employeeWorkLog?.length > 0) {
              addLogs(date);
              setProjectId([]);
            } else if (data._id && data._id !== null) {
              setTimeSheet(data);
              timesheetId.current = data._id;
              data.projectId.forEach((item, index) => {
                let match = projectsData.find((obj) => obj.project === item);
                if (match) {
                  data.projectId[index] = match._id;
                }
              });

              setIsSubmitted(data.isSubmitted);
              const project = getProjectNamesByIds(
                response.data.employeeWorkLog,
                allProjects
              );
              const dataForApi = data?.employeeWorkLog?.map(
                (item) => item.projectIds
              );
              if (
                response.data.status === "SUBMITTED" ||
                response.data.status === "APPROVED" ||
                response.data.status !== null
              ) {
                setProjectId(project);
              } else {
                setProjectId(dataForApi?.length > 0 ? dataForApi : []);
              }
            }

            setLoading(false);
          }
        } catch (err) {
          setLoading(false);
          console.error(err);
        }
    }; 

    const handleSubmit = async (submitted) => {
      setDialogOpen(false);
      let payload = { ...timeSheet };
      // payload.projectId = projectId;
      payload.employeeId = getToken("Id");
      payload.isSubmitted = submitted;
      payload.currentMonth = date;
      payload.status = submitted ? 'SUBMITTED' : 'DRAFT';
      setLoading(true);
      try {
        let response;
        if(timesheetId.current !== ''){
          response = await axios.put(`${serviceUrl}/api/workLogManagement/updateworklog?workLogId=${timesheetId.current}`, payload, {
            headers: genericHeaders(),
          });
          try{
            await axios.post(`${serviceUrl}/api/workLogManagement/timesheetemail?workLogId=${timesheetId.current}`,{},{headers: genericHeaders()}) //api call for email triggering
          }catch(err){
            console.error(err);
          }  
        }else{
          response = await axios.post(`${serviceUrl}/api/workLogManagement/create`, payload, {
            headers: genericHeaders(),
          });
        }
        if (response.status === 201 || response.status === 200) {
          setTimeSheet({
            isSubmitted: "",
            get_id: "",
            isActive: true,
            createdDate: new Date().toISOString(),
            projectId: [],
            employeeWorkLog: [],
            currentMonth: new Date().toISOString(),
            employeeId: "",
            isApproved: false,
            approvedOn: "",
            approvedBy: "",
          });
          getDataOfCurrentMonth(projects);
          if (submitted) {
            handleSnackOpen(true, false, "Submitted Successfully");
          } else {
            handleSnackOpen(true, false, "Saved Successfully");
          }
          setProjectId([]);
          setLoading(false);
        }
      } catch (err) {
        setLoading(false);
        handleSnackOpen(true, true, "Something went wrong");
        console.error(err);
      }
      setDaysVisible(Array(weekData.length).fill(false));
    };

    function getIstTime(date){
      let newDate = new Date(date);
      newDate.setHours(0, 0, 0, 0);
      return new Date(newDate.getTime() - newDate.getTimezoneOffset() * 60000).toISOString();
    };

    const handleDateChange = (evt) => {
      let changedDate = getIstTime(evt);
      setDate(changedDate);
      getProjectData();
      setProjectId([])
    };

    const getIsEditable = () => {
      return !timeSheet?.status || timeSheet.status === 'DRAFT' || timeSheet.status === 'REJECTED';
    }

    const getVerifierName = () => {
      let employee = employeeListData?.find((item) => item?.id == timeSheet?.isApprovedBy);
      return employee?.name || '--';
    }

    const renderContent = () => {
      return (
        <section className="flex flex-col mx-auto">
          <h2 className="text-center font-semibold text-2xl my-5">
            Time Sheet for the month of {dayjs(date).format("MMMM")}
          </h2>

          <div className="flex justify-between w-[80%] mx-auto mt-5 mb-2 text-2xl">
            {timeSheet?.status && timeSheet.status !== "DRAFT" ? (
              <p>Status: {timeSheet.status}</p>
            ) : null}
            {isSubmitted ? (
              <div className="flex">
                <img className="w-6 mx-2" src={checkCircle} alt="ICON" />
                <p>Submitted Successfully</p>
              </div>
            ) : (
              <></>
            )}
          </div>

          {weekData.length > 0
            ? weekData.map((item, weekIndex) => (
                <WeekCard
                  key={weekIndex}
                  item={item}
                  daysVisible={daysVisible}
                  weekIndex={weekIndex}
                  toggleDaysVisibility={toggleDaysVisibility}
                  datesData={datesData}
                  getLogHoursValue={getLogHoursValue}
                  getCommentsValue={getCommentsValue}
                  isEditable={getIsEditable()}
                  handleCommentChange={handleCommentChange}
                  handleHourChange={handleHourChange}
                  handleSubmit={handleSubmit}
                  timeSheet={timeSheet}
                  setTimeSheet={setTimeSheet}
                  projects={projects}
                  projectId={projectId}
                  handleProjects={handleProjects}
                />
              ))
            : null}
          <div
            className={`justify-between w-4/5 mx-auto mt-7 mb-5 ${
              projectId?.length > 0 ? "flex" : "hidden"
            } `}
          >
            <div className="w-1/2 text-xl">
              {timeSheet?.status && timeSheet.status !== "DRAFT" ? (
                <p>Comments: {timeSheet?.comments || "--"}</p>
              ) : (
                <></>
              )}
            </div>
            {isSubmitted ? (
              <p className="text-xl">Verifier Name:{getVerifierName()}</p>
            ) : (
              <button
                className={`${
                  isSubmitted || timesheetId.current === "" ? "hidden" : "flex"
                } bg-blue-500 h-fit hover:bg-white hover:text-blue-500 border border-blue-500 text-white font-bold py-1 px-2 rounded`}
                onClick={() => setDialogOpen(!dialogOpen)}
                disabled={timesheetId.current === ""}
              >
                Submit
              </button>
            )}
          </div>
        </section>
      );
    }

    const maxDate = dayjs().endOf('month');
    
    useEffect(() => {
      const weeks = generateWeeks(date);
      const days = generateDates(weeks);

      getProjectData();
      setWeekData(weeks);
      setDatesData(days);
      setDaysVisible(Array(weeks.length).fill(false));
    },[date])


  return (
    <div className="mt-28 w-[95%] mx-auto">
      {loading && <Loader />}
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        open={snack.open}
        autoHideDuration={2000}
        onClose={handleSnackClose}
      >
        <Alert
          severity={snack.error ? "error" : "success"}
          variant="filled"
          onClose={handleSnackClose}
        >
          {snack.message}
        </Alert>
      </Snackbar>

      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(!dialogOpen)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <p className="text-2xl font-semibold">
            Are you sure you want to submit the timesheet?
          </p>
          <p className="text-sm text-red-500 py-2">
            Kindly be aware that once submitted, the timesheet cannot be edited.
          </p>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(!dialogOpen)}>CANCEL</Button>
          <Button onClick={() => handleSubmit(true)}>SUBMIT</Button>
        </DialogActions>
      </Dialog>

      <div className="flex mx-auto mb-5 justify-between w-4/5 ">
        {/* <div className="flex space-x-5 w-2/5">
          <h4 className="text-xl my-auto">Projects: </h4>
          <FormControl className="w-1/2">
            <Select
              label="Projects..."
              name="projects..."
              className="w-full"
              multiple
              value={projectId}
              onChange={handleProjects}
              disabled={isSubmitted}
              MenuProps={{
                sx: {
                  "& .MuiList-root": {
                    maxHeight: 300,
                  },
                  "& .MuiInputBase-root": {
                    height: 50,
                  },
                },
              }}
              //disabled={isApplied}
              renderValue={(selected) => {
                return selected
                  .map((value) => {
                    const selectedItem = projects.find(
                      (item) => item._id === value
                    );
                    return selectedItem ? selectedItem.project : "";
                  })
                  .join(", ");
              }}
            >
              {projects.length > 0 ? (
                projects.map((item) => {
                  return (
                    <MenuItem key={item._id} value={item._id}>
                      <Checkbox
                        checked={projectId.indexOf(item._id) !== -1}
                        onClick={handleProjectToggle(item._id)}
                      />
                      <ListItemText primary={item.project} />
                    </MenuItem>
                  );
                })
              ) : (
                <MenuItem disabled>No Projects have been Allocated</MenuItem>
              )}
            </Select>
          </FormControl>
        </div> */}
        <div className="flex space-x-5">
          <h4 className="text-xl my-auto">Month: </h4>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              value={dayjs(date)}
              openTo="month"
              views={["month", "year"]}
              onChange={handleDateChange}
              maxDate={maxDate}
              sx={{
                maxHeight: 30,
                width: "15rem",
                bgcolor: "white",
                marginTop: "auto",
                marginBottom: "auto",
                marginLeft: "1.25rem",
                "& .MuiInputBase-root": {
                  height: 30,
                  padding:"0 10px",
                },
                borderRadius: "0.375rem",
              }}
            />
          </LocalizationProvider>
        </div>
      </div>

      {renderContent()}
    </div>
  );
}

export default WorkLog



export const getLogHoursValue = (date,data) => {
    let newData = {...data};
    let logData= newData?.employeeWorkLog?.find((item) => item.fromDate === date || item.fromDate === date.split('.')[0]);
    return logData?.logHours.toString();
}

export  const getCommentsValue = (date,data) => {
    let newData = {...data};
    let logData= newData?.employeeWorkLog?.find((item) => item.fromDate === date || item.fromDate === date.split('.')[0]);
    return logData?.comment.toString();
}

export const generateWeeks = (month) => { 

    let customDate;
    if (month !== undefined) {
        customDate = dayjs(month);
    } else {
        customDate = dayjs();
    }

    const startOfMonth = customDate.startOf('month');
    const endOfMonth = customDate.endOf('month');

    const dateRanges = [];

    let date = startOfMonth;

    while(date.isBefore(endOfMonth) || date.isSame(endOfMonth, 'day')){
        let firstDayOfWeek = date;
        let endDayOfWeek = date.add(6 - date.day(),'day');

        if(endDayOfWeek.isAfter(endOfMonth)){
            endDayOfWeek = endOfMonth;
        }

        dateRanges.push({firstDay : firstDayOfWeek.format('DD/MM/YYYY'), lastDay : endDayOfWeek.format('DD/MM/YYYY')});

        date = endDayOfWeek.add(2, 'day'); //skipping sundays
    }

    return dateRanges
}

export const generateDates = (weekData) => {
    const dates = [];
    weekData.forEach((item) => {
        let firstDay = dayjs(item?.firstDay, 'DD/MM/YYYY');
        let lastDay = dayjs(item?.lastDay, 'DD/MM/YYYY');
        let weekDates = [];
        while (firstDay.isBefore(lastDay) || firstDay.isSame(lastDay, "day")) {
            if (firstDay.day() !== 0) { // Skip Sundays
                weekDates.push(firstDay.format('DD/MM/YYYY'));
            }
            firstDay = firstDay.add(1, "day");
        }
        dates.push(weekDates);
    });
    return dates;
}

export const getHourValue = (hour) => {
  if (isNaN(hour)) {
      return 0; // Default to 0 if input is not a number
  }
  if (hour < 0) {
      return 0;
  } else if (hour > 8) {
      return 8;
  } else {
      return hour;
  }
}

export const handleHourChange = (evt,timeSheet,setTimeSheet) => {
  const {id,value} = evt.target;
  let newData = {...timeSheet};
  let logDataIndex = newData?.employeeWorkLog?.findIndex((item) => item.fromDate === id || item.fromDate === id.split('.')[0]);
  newData.employeeWorkLog[logDataIndex]['logHours'] = getHourValue(value);
  setTimeSheet(newData);
};

export const handleCommentChange = (evt,timeSheet,setTimeSheet) => {
  const {id,value} = evt.target;
  let newData = {...timeSheet};
  let logDataIndex = newData?.employeeWorkLog?.findIndex((item) => item.fromDate === id || item.fromDate === id.split('.')[0]);
  newData.employeeWorkLog[logDataIndex]['comment'] = value;
  setTimeSheet(newData);
};

export const handleProjectChange = (
  id,
  timeSheet,
  setTimeSheet,
  projectId,
  isChecked
) => {
  let newData = {
    ...timeSheet,
    employeeWorkLog: timeSheet.employeeWorkLog.map((log) => ({
      ...log,
      projectIds: [...log.projectIds],
    })),
  };

  let logDataIndex = newData?.employeeWorkLog?.findIndex((item) => {
    const formattedDate = new Date(item.fromDate).toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
    return formattedDate === id || item.fromDate === id.split(".")[0];
  });

  if (logDataIndex === -1) {
    console.error("No log data found for the given date ID");
    return;
  }

  if (!newData.employeeWorkLog[logDataIndex].projectIds) {
    newData.employeeWorkLog[logDataIndex].projectIds = [];
  }

  if (isChecked) {
    if (!newData.employeeWorkLog[logDataIndex].projectIds.includes(projectId)) {
      newData.employeeWorkLog[logDataIndex].projectIds.push(projectId);
    }
  } else {
    newData.employeeWorkLog[logDataIndex].projectIds = newData.employeeWorkLog[
      logDataIndex
    ].projectIds.filter((id) => id !== projectId);
  }

  setTimeSheet(newData);
};