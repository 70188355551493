import React, { useState } from "react";
import { useEffect } from "react";
import "./style.css"; // Import a CSS file for styling

const CustomFieldTable = ({ filePlaceholders, setFormData, formData }) => {
  const [tableData, setTableData] = useState([]);
  const [editingId, setEditingId] = useState(null);

  const handleSaveClick = (id) => {
    // Save the edited data to the state or send it to the server
    setEditingId(null);
  };

  const handleInputChange = (id, field, value) => {
    // Update the field's value in the state
    const updatedData = tableData.map((row) => {
      if (row.id === id) {
        return { ...row, [field]: value };
      }
      return row;
    });
    setFormData({ ...formData, customFields: updatedData });
    setTableData(updatedData);
  };

  const toggleRequired = (id) => {
    // Toggle the "required" value
    const updatedData = tableData.map((row) => {
      if (row.id == id) {
        return { ...row, isRequired: !row.isRequired };
      }
      return row;
    });
    setFormData({ ...formData, customFields: updatedData });
    setTableData(updatedData);
  };

  useEffect(() => {
    if (filePlaceholders) {
      const lines = filePlaceholders?.split("\n");
      // Create an array of objects with editing enabled by default
      const data =
        lines &&
        lines.length > 0 &&
        lines.map((line, index) => ({
          id: index + 1,
          fieldPlaceHolder: line.trim(),
          fieldDisplayName: "",
          isRequired: false,
          fieldValue: "",
        }));
      setTableData(data);
    }
  }, [filePlaceholders]);

  return (
    <div>
      <table className="custom-field-table">
        <thead>
          <tr>
            <th>Field Placeholder</th>
            <th>Field Name</th>
            <th>Required</th>
            <th>Field Values</th>
          </tr>
        </thead>
        <tbody>
          {tableData?.map((row) => (
            <tr key={row.id}>
              <td>
                <input
                  disabled
                  type="text"
                  value={row.fieldPlaceHolder}
                  onChange={(e) =>
                    handleInputChange(
                      row.id,
                      "fieldPlaceHolder",
                      e.target.value
                    )
                  }
                />
              </td>

              <td>
                <input
                  type="text"
                  value={row.fieldDisplayName}
                  onChange={(e) =>
                    handleInputChange(
                      row.id,
                      "fieldDisplayName",
                      e.target.value
                    )
                  }
                />
              </td>
              <td>
                <div className="flex justify-center">
                  <input
                    type="checkbox"
                    checked={row.isRequired}
                    onChange={() => toggleRequired(row.id)}
                  />
                </div>
              </td>
              <td>
                <input
                  disabled
                  type="text"
                  value={row.fieldValue}
                  onChange={(e) =>
                    handleInputChange(row.id, "fieldValue", e.target.value)
                  }
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default CustomFieldTable;
