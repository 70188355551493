import React from "react";

const DocList = ({ documents, deleteDoc, feildDisabled }) => {
  return (
    <>
      {documents?.map((item, index) => {
        return (
          <>
            <div class="bg-blue-50 rounded-lg p-2 shadow-md w-52 mr-3 mb-3">
              {feildDisabled ? (
                <></>
              ) : (
                <div
                  class="flex justify-end mb-2"
                  onClick={() => {
                    console.log("inside onclick");
                    deleteDoc(index);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-3 w-3 text-red-500 cursor-pointer"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </div>
              )}
              <div
                class="text-blue-800 font-semibold text-md  text-lg	 mb-1"
                title="Document Name That Is Very Long and Doesn't Fit in the Card"
              >
                {item.name}
              </div>
              <div class="text-blue-400 font-semibold text-base	">
                File Name:
              </div>
              <div class="text-blue-300 text-base	">{item.fileName}</div>
              <div class="text-blue-400 font-semibold mt-1 text-base	">
                Uploaded Date:
              </div>
              <div class="text-blue-300 text-base	">
                {new Date(item.uploadDate).toLocaleDateString(undefined, {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                })}
              </div>
            </div>
          </>
        );
      })}
      {feildDisabled && documents?.length == 0 ? <p>No Documents</p> : <></>}
    </>
  );
};

export default DocList;
