import React from "react";
import CustomHoliday from "./CustomHoliday";
import { TrashIcon } from "@heroicons/react/24/outline";
import dayjs from "dayjs";
import HolidayDialog from "./HolidayDialog";

const ViewCalendar = (props) => {
  console.log(props.data, "props78");

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  // Initialize holidaysByMonth as an array of empty arrays
  const holidaysByMonth = Array(12)
    .fill(null)
    .map(() => []);

  // Group holidays by their month
  props.data.holidays.forEach((holiday) => {
    const month = new Date(holiday.date).getMonth(); // Get month index (0-11)
    holidaysByMonth[month].push(holiday); // Push the holiday into the corresponding month array
  });

const [dialogOpen, setDialogOpen] = React.useState(false);
const [selectedMonth, setSelectedMonth] = React.useState(null);

const openDialog = (monthIndex) => {
  setSelectedMonth(monthIndex);
  setDialogOpen(true);
};

const closeDialog = () => {
  setDialogOpen(false);
  setSelectedMonth(null);
};

  return (
    <section className="mx-6">
      <div className="flex justify-between w-4/5 pl-5 border-l-4 border-blue-500">
        <div>
          <div className="flex items-center gap-2">
            <h2 className="text-3xl">{props.data.calenderName}</h2>
            {props.delete && (
              <div
                className="my-auto mx-5 bg-blue-100 rounded-md cursor-pointer"
                onClick={() => props.handleDeleteDialog(props.data._id)}
              >
                <TrashIcon className="w-10 h-10 p-2" />
              </div>
            )}
          </div>
          <p className="text-lg">
            {new Date(props.data.yearOfCalender).getFullYear()}
          </p>
        </div>
      </div>

      <div className="mt-5 flex flex-wrap">
        {holidaysByMonth.map((holidays, index) => (
          <div key={index} className="2xl:w-1/4 w-1/3 px-2 mb-4 flex flex-col">
            <h3 className="font-semibold mb-1">
              {monthNames[index]}{" "}
              {new Date(props.data.yearOfCalender).getFullYear()} - (
              {holidays.length})
            </h3>
            <div className="flex-grow flex h-[165px] items-center">
              {holidays.length === 0 ? (
                <p className="border h-[165px] border-gray-400 rounded-xl py-5 px-2 w-full flex items-center justify-center">
                  No Holidays
                </p>
              ) : (
                <div className="w-full h-[165px] border border-gray-400 rounded-xl py-5 px-2">
                  {holidays.slice(0, 2).map((holiday, holidayIndex) => (
                    <div key={holidayIndex} className="flex items-center mb-2">
                      <div className="w-[15%]">
                        <p className="font-semibold">
                          {holiday.date
                            ? dayjs(holiday.date).format("DD")
                            : "N/A"}
                        </p>
                        <p className="text-sm">
                          {holiday.date
                            ? dayjs(holiday.date).format("ddd")
                            : "N/A"}
                        </p>
                      </div>
                      <div className="w-[85%]">
                        <p className="font-medium">{holiday.occasion}</p>
                        <p className="text-xs text-gray-500">
                          {holiday.leaveType}
                        </p>
                      </div>
                    </div>
                  ))}
                  {holidays.length > 2 && (
                    <button
                      className="text-blue-500 hover:text-blue-700 my-2"
                      onClick={() => openDialog(index)}
                    >
                      +{holidays.length - 2} more
                    </button>
                  )}
                </div>
              )}
            </div>
          </div>
        ))}
      </div>

      <HolidayDialog
        isOpen={dialogOpen}
        onClose={closeDialog}
        holidays={selectedMonth !== null ? holidaysByMonth[selectedMonth] : []}
        month={selectedMonth !== null ? monthNames[selectedMonth] : ""}
        year={new Date(props.data.yearOfCalender).getFullYear()}
      />
    </section>
  );
};

export default ViewCalendar;
