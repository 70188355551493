import React from 'react'
import Backdrop from "@mui/material/Backdrop";
import { CircularProgress } from "@material-ui/core";

const customLoader = () => {
  return (
    <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
          //onClick={handleClose}
        >
          <CircularProgress
            color="inherit"
            size={100}
            className="absolute top-[50%] left-[50%]"
          />
    </Backdrop>
  )
}

export default customLoader