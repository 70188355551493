import React from "react";
import { getToken } from "../../services/Token";
import dayjs from "dayjs";
import { useCreateProfileQuery } from "../../api/profileApi";

function Backgroundcheck() {
  const Id = getToken("Id");
  const { data, isSuccess } = useCreateProfileQuery({
    employeeId: `${Id}`,
  });

  return (
<div className="w-full">
      <div className="w-[95%] bg-white rounded-xl mx-auto p-3">
        <div>
          <h3 className="text-xl my-2 p-2 border-l-2 border-purple-300 font-semibold">
            Background Check
          </h3>
          <div className="grid grid-cols-4 p-2 gap-2">
            <div>
              <h4 className="text-slate-600">Verification Status</h4>
              <p className="my-1 text-lg">
                {data?.backgroundVerification?.verificationStatus
                  ? data?.backgroundVerification?.verificationStatus
                  : "-"}
              </p>
            </div>
            <div>
              <h4 className="text-slate-600">Verification Completed on</h4>
              <p className="my-1 text-lg">
                {data?.backgroundVerification?.verificationCOmpletedOn
                  ? dayjs(
                      data?.backgroundVerification?.verificationCOmpletedOn
                    ).format("DD/MM/YYYY")
                  : "-"}
              </p>
            </div>
            <div>
              <h4 className="text-slate-600">Agency Name</h4>
              <p className="my-1 text-lg">
                {data?.backgroundVerification?.completedby
                  ? data?.backgroundVerification?.completedby
                  : "-"}
              </p>
            </div>
            <div>
              <h4 className="text-slate-600">Remarks</h4>
              <p className="my-1 text-lg">
                {data?.backgroundVerification?.remarks
                  ? data?.backgroundVerification?.remarks
                  : "-"}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Backgroundcheck;
