import React from "react";
import { getToken } from "../../services/Token";
import dayjs from "dayjs";
import { useCreateProfileQuery } from "../../api/profileApi";

function JoiningDetails() {
  const Id = getToken("Id");
  const { data, isSuccess } = useCreateProfileQuery({
    employeeId: `${Id}`,
  });

  return (
<div className="w-full">
<div className="w-[95%] bg-white rounded-xl mx-auto p-3">
              <div>
                <h3 className="text-xl my-2 p-2 border-l-2 border-purple-300 font-semibold">
                  Joining Details
                </h3>

                <div className="grid grid-cols-4 p-2 gap-2">
                  <div>
                    <h4 className="text-slate-600">Joined On</h4>
                    <p className="my-1 text-lg">
                      {data.professionalDetails?.dateofJoining
                        ? dayjs(
                            data.professionalDetails?.dateofJoining
                          ).format("DD/MM/YYYY")
                        : "-"}{" "}
                    </p>
                  </div>
                  <div>
                    <h4 className="text-slate-600">Confirmed Date</h4>
                    <p className="my-1 text-lg">
                      {data.professionalDetails?.confirmationDate
                        ? dayjs(
                            data.professionalDetails?.confirmationDate
                          ).format("DD/MM/YYYY")
                        : "-"}
                    </p>
                  </div>
                  <div>
                    <h4 className="text-slate-600">Status</h4>
                    <p className="my-1 text-lg">
                      {data.professionalDetails?.status
                        ? data.professionalDetails?.status
                        : "-"}
                    </p>
                  </div>
                  <div>
                    <h4 className="text-slate-600">Probation Period</h4>
                    <p className="my-1 text-lg">
                      {data.professionalDetails?.probationPeriod
                        ? data.professionalDetails?.probationPeriod
                        : "-"}
                    </p>
                  </div>
                  <div>
                    <h4 className="text-slate-600">Notice Period</h4>
                    <p className="my-1 text-lg">
                      {data.professionalDetails?.noticePeriod
                        ? data.professionalDetails?.noticePeriod
                        : "-"}
                    </p>
                  </div>
                  <div>
                    <h4 className="text-slate-600">
                      Current Company Experience
                    </h4>
                    <p className="my-1 text-lg">
                      {data.professionalDetails?.currentCompanyExperience
                        ? data.professionalDetails?.currentCompanyExperience
                        : "-"}
                    </p>
                  </div>
                  <div>
                    <h4 className="text-slate-600">Total Experience</h4>
                    <p className="my-1 text-lg">
                      {data.professionalDetails?.totalExperience
                        ? data.professionalDetails?.totalExperience
                        : "-"}
                    </p>
                  </div>
                </div>
              </div>
            </div>
    </div>
  );
}
export default JoiningDetails;
