import React, { useState } from "react";

function CustomRadioSelect({
  options,
  label,
  onSelect,
  name,
  value,
  isMandatory,
  disabled
}) {
  const [selectedOption, setSelectedOption] = useState(value);

  const handleOptionChange = (event) => {
    const option = event.target.value;
    setSelectedOption(option);
    onSelect(event);
  };

  return (
    <div className="mb-4 flex items-center">
      <label className="block text-sm font-medium text-gray-700 mr-5">
        {label} {isMandatory && <span className="text-red-500 text-xl">*</span>}
      </label>
      <div>
        {options.map((option) => (
          <>
            <input
              name={name}
              type="radio"
              value={option}
              checked={selectedOption === option}
              onChange={handleOptionChange}
              className="w-4 h-4 mr-1 text-blue-600 form-radio  focus:ring-blue-500"
              disabled={disabled}
            />
            <label key={option} className="ml-1 mr-2">
              {option}
            </label>
          </>
        ))}
      </div>
    </div>
  );
}

export default CustomRadioSelect;
