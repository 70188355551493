import { getToken } from "../services/Token";

const get_profile = () => {
  const host = window.location.host;
  if (host === 'ei.app.fallnspring.com') return 'production';
  if (host === 'ei-app-ictcrp.web.app') return 'dev';
  if (host === 'localhost:3000') return 'dev';
  return 'unknown';
};

let be_url;
let cloudUrl = "https://ictservice.cambri.ai/api/";
const profile = get_profile();
switch (profile) {
  case 'production':
    be_url = 'https://api.ei.fallnspring.com';
    cloudUrl = "https://ictsystems.cambri.ai/api/";
    break;
  case 'dev':
    be_url = 'https://api.ei.prismatica.ai';
    cloudUrl = "https://ictservice.cambri.ai/api/";
    break;

  // REMOVE THIS BLOCK BEFORE DEPLOYMENT FOR APP SPECIFIC CHANGES
  default:
    be_url = 'https://api.ei.prismatica.ai';
    cloudUrl = "https://ictservice.cambri.ai/api/";
    break;
}

export const serviceUrl = be_url;
export const cloudServiceUrl = cloudUrl;

export const cloudApplicationUrl = get_profile() === "production" ? "ei.app.fallnspring.com" : "ei-app-ictcrp.web.app"; 

//get_profile() === "production" ? "ei.app.fallnspring.com" : "ei-app-ictcrp.web.app"


export const genericHeaders = () => {
  if (getToken('Token') != null)
  {
    return {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + getToken('Token') // add the cache & get the token here
    };
  }
  return {
    'Content-Type': 'application/json'
  };
};