import React, { useEffect, useState } from "react";
import { genericHeaders, serviceUrl } from "../utils/apiConfig";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { getToken } from "../services/Token";


export default function LeaveBalance() {
  const [leaveCategories, setLeaveCategories] = useState([]);
  const [employeeLeave, setEmployeeLeave] = useState([]);
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const Id = getToken("Id");
  const getLeaveData = async () => {
    try {
      let response = await axios.get(
        `${serviceUrl}/api/employeeLeave/getEmployeeLeaves?employeeId=${Id}`,
        { headers: genericHeaders() }
      );
      if (response.status === 200) {
        setEmployeeLeave(response.data);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const getAllLeaveData = async () => {
    try {
      let response = await axios.get(
        `${serviceUrl}/api/leaveCategory/getAllLeaveCategories`,
        { headers: genericHeaders() }
      );
      if (response.status === 200) {
        setLeaveCategories(response.data);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const matchData = (employeeLeaves, leaveData) => {
    let data = [];
  
    if (employeeLeaves && leaveData) {
      for (let i = 0; i < leaveData.length; i++) {
        let matchedData = employeeLeaves.find(
          (item) => item.leaveType === leaveData[i]._id
        );
  
        if (matchedData) {
          matchedData.leaveCategory = leaveData[i].leaveCategory;
          matchedData.description = leaveData[i].description;
          data.push(matchedData);
        }
      }
    }
  
    return data;
  };
  

  useEffect(() => {
    getLeaveData();
    getAllLeaveData();
  }, []);

  useEffect(() => {
    if (leaveCategories && employeeLeave) {
      let newData = matchData(employeeLeave.leaves, leaveCategories);
      setData(newData);
    }
  }, [leaveCategories, employeeLeave]);
  return (
    <div className="mt-20 relative">
         <button
        className="absolute top-0 right-0 mt-[0.25rem] mr-[4.25rem] bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 focus:outline-none"
        onClick={() => {
            navigate('/leaves');
        }}
      >
        Apply
      </button>
      <h1 className="text-2xl font-semibold mt-24 mb-10 mx-5">
        Leave Categories
      </h1>
      <div className=" mx-10 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
        {data.map((item, index) => (
          <div
            key={index}
            className="p-6 bg-white rounded-lg shadow-md hover:shadow-lg transition-transform transform hover:scale-105"
          >
            <p className="text-gray-600">{item.leaveCategory}</p>
            <p className="text-gray-600">
              {item.currentAvailableLeaves}
              <div
                className="absolute top-1/2 right-4 transform -translate-y-1/2"
              >
                <FontAwesomeIcon
                  icon={faInfoCircle}
                  title={item.description}
                  className="cursor-pointer text-blue-500"
                />
              </div>
            </p>
          </div>
        ))}
      </div>
    </div>
  );
}
