import { React, useState, useEffect } from "react";
import { Alert, Snackbar } from "@mui/material";
import {
  CircularProgress,
  Container,
  makeStyles,
  Paper,
} from "@material-ui/core";
import { useNavigate, useParams } from "react-router-dom";
import Backdrop from "@mui/material/Backdrop";
import { useCreateNewEmployeeMutation } from "../../api/addEmployeeApi";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import StepLabel from "@mui/material/StepLabel";
import { useSelector, useDispatch } from "react-redux";
import {
  updateAddEmployeeClicked,
  clearEmployeeDetails,
  updateErrorState,
} from "../../store/reducers/addEmployeeSlice";
//import { updateEmployeeDetails,updateErrorState } from "../../store/reducers/addEmployeeSlice";
import AddEmployeeJson from "./addEmployee.json";
import BasicInfo from "./BasicInfo";
import EmployeePosition from "./EmployeePosition";
import StatutoryInfo from "./StatutoryInfo";
import PaymentMode from "./PaymentMode";
import Experience from "./Experience";
import OtherDocs from "./OtherDocs";
import CustomButton from "../../Components/customComponents/customButton";
import { useLazyCreateProfileQuery } from "../../api/profileApi";
import {
  updateEmployeeJson,
  updateFeildsDisabled,
} from "../../store/reducers/addEmployeeSlice";
import { updateErrorMessage } from "../../store/reducers/errorMessageSlice";
import Tooltip from "@mui/material/Tooltip";
import Breadcrumbs from "../../Components/EmployeePunchTracking/BreadCrumbs";

const steps = [
  "PROFESSIONAL DETAILS",
  "PERSONAL DETAILS",
  "ACADEMIC DETAILS",
  "EXPERIENCE",
  "BANK DETAILS",
  "OTHER DOCUMENTS",
];
const steps2 = [
  "PROFESSIONAL DETAILS",
  "PERSONAL DETAILS",
  "ACADEMIC DETAILS",
  "BANK DETAILS",
  "OTHER DOCUMENTS",
];

const useStyles = makeStyles((theme) => ({
  container: {
    position: "relative",
    minHeight: "100vh", // Adjust this value based on your layout
  },
  circularProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
  },
  content: {
    // Define styles for your content here
    padding: theme.spacing(2),
  },
}));

const AddEmployee = () => {
  const classes = useStyles();
  const empState = useSelector((state) => state.addEmployee.employeeDetails);
  const errorMessageState = useSelector(
    (state) => state.errorMessage.errorMessage
  );
  const addEmployeeState = useSelector(
    (state) => state.addEmployee.addEmployeeClicked
  );
  const errorCheck = useSelector((state) => state.addEmployee.errorState);
  const errorCheckWork = useSelector((state)=>state.addEmployee.errorStateWork);
  const feildDisabled = useSelector(
    (state) => state.addEmployee.feildsDisabled
  );

  const dispatch = useDispatch();
  const [createNewEmployee, { isLoading, isSuccess, isError, error }] =
    useCreateNewEmployeeMutation();
  const [showSnack, setShowSnack] = useState(isSuccess);
  const [snackBarMsg, setShowSnackBarmsg] = useState("");
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState({});
  const navigate = useNavigate();
  let { empId } = useParams();

  const [createProfile, employeeData] = useLazyCreateProfileQuery();
  useEffect(() => {
    if (empId) {
      createProfile({ employeeId: empId });
    }
  }, [empId]);
  useEffect(() => {
    if (employeeData.isSuccess) {
      let empData = employeeData.data;
      dispatch(updateEmployeeJson(empData));
      dispatch(updateFeildsDisabled(true));
    }
  }, [employeeData]);

  const totalSteps = () => {
    if (
      +empState.professionalDetails.totalExperience !== "" &&
      +empState.professionalDetails.totalExperience !== 0
    ) {
      return steps.length;
    } else {
      return steps2.length;
    }
  };
  const isLastStep = () => {
    return activeStep === totalSteps - 1;
  };
  const completedSteps = () => {
    return Object.keys(completed).length;
  };
  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };
  
  const handleNext = () => {
    
    if (!getDisabledState()) {
      dispatch(updateErrorMessage(false));
      const newActiveStep =
        isLastStep() && !allStepsCompleted()
          ? steps.findIndex((step, i) => !(i in completed))
          : activeStep + 1;

      const currentStepError =
        errorCheck && errorCheck[`step${activeStep}`]
          ? errorCheck[`step${activeStep}`]:{}
      if (
        activeStep === 0 ||
        activeStep === 1 ||
        activeStep === 2 ||
        activeStep === 4
      ) {
        if (Object.keys(currentStepError).length === 0) {
          setActiveStep(newActiveStep);
        }
      } else {
        setActiveStep(newActiveStep);
      }

      if (newActiveStep == 10) {
        createNewEmployee(empState);
      }
    }
 else {
      dispatch(updateErrorMessage(true));
    }
  };
  const handleNextWork = () => {
    if (!getDisabledState()) {
      dispatch(updateErrorMessage(false));
        const newActiveStepExperience =
          isLastStep() && !allStepsCompleted()
            ? steps2.findIndex((step, i) => !(i in completed))
            : activeStep + 1;
if(activeStep === 3){
  const currentStepErrorWork = 
        errorCheckWork && errorCheckWork[`step${activeStep}`]
          ? errorCheckWork[`step${activeStep}`]:{}
              
        if (
          activeStep === 0 ||
          activeStep === 1 ||
          activeStep === 2 ||
          activeStep === 3
        ) {
          if (Object.keys(currentStepErrorWork).length === 0) {
            setActiveStep(newActiveStepExperience);
          }
        } else {
          setActiveStep(newActiveStepExperience);
        }

        if (newActiveStepExperience == 10) {
          createNewEmployee(empState);
        }
}
    else{    
      const currentStepError = 
        errorCheck && errorCheck[`step${activeStep}`]
          ? errorCheck[`step${activeStep}`]:{}
                 
        if (
          activeStep === 0 ||
          activeStep === 1 ||
          activeStep === 2 ||
          activeStep === 3
        ) {
          if (Object.keys(currentStepError).length === 0) {
            setActiveStep(newActiveStepExperience);
          }
        } else {
          setActiveStep(newActiveStepExperience);
        }

        if (newActiveStepExperience == 10) {
          createNewEmployee(empState);
        }
    }

      }
    else {
      dispatch(updateErrorMessage(true));
    }
  };
  const handleViewNext = () => {
    dispatch(updateErrorMessage(false));
    if (
      +empState.professionalDetails.totalExperience !== "" &&
      +empState.professionalDetails.totalExperience !== 0
    ) {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;

    setActiveStep(newActiveStep);

    if (newActiveStep === 10) {
      createNewEmployee(empState);
    }
  }
   else  {
      const newActiveStepExperience =
        isLastStep() && !allStepsCompleted()
          ? steps2.findIndex((step, i) => !(i in completed))
          : activeStep + 1;

      setActiveStep(newActiveStepExperience);

      if (newActiveStepExperience === 10) {
        createNewEmployee(empState);
      }
    }
  };
  const handlePrev = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };
  const handleCancel = () => {
    if (activeStep !== 5) {
      setActiveStep(0);
    }
    setCompleted({});
    dispatch(updateAddEmployeeClicked(false));
    dispatch(clearEmployeeDetails(AddEmployeeJson));
    setTimeout(() => {
      navigate("/manage");
    }, [1000]);
  };
  const getDisabledState = () => {
        if(+empState.professionalDetails.totalExperience !== "" &&
    +empState.professionalDetails.totalExperience !== 0){
    if (
      activeStep != 0 &&
      activeStep != 1 //&&
      //activeStep != 2 &&
      // activeStep != 4
    ) {
      return false;
    }
    if (
      activeStep == 0 &&
      empState.firstName &&
      empState.lastName &&
      empState.professionalDetails.workLocation &&
      empState.professionalDetails.department &&
      empState.professionalDetails.currentDesignation &&
      empState.professionalDetails.holidayCalender &&
      empState.professionalDetails.managerId &&
      empState.professionalDetails.employeeType &&
      empState.professionalDetails.status &&
      empState.professionalDetails.probationPeriod &&
      empState.professionalDetails.noticePeriod &&
      empState.professionalDetails.shift &&
      empState.professionalDetails.dateofJoining &&
      empState.professionalDetails.employeeId 
    ) {
      return false;
    } else if (
      activeStep == 1 &&
      //empState.personalDetails.aadharNumber &&
      //empState.personalDetails.panNumber &&
      empState.personalDetails.email &&
      empState.personalDetails.gender &&
      empState.personalDetails.primaryContactNo &&
      empState.personalDetails.emergencyContactName &&
      empState.personalDetails.emergencyContactNo &&
      empState.personalDetails.maritalStatus
    ) {
      return false;
    } //else if (
      // activeStep == 2 &&
      // empState.academicDetails.institute &&
      // empState.academicDetails.qualification &&
      // empState.academicDetails.grade &&
      // empState.academicDetails.fromDate &&
      // empState.academicDetails.toDate
    // ) {
    //   return false;
    // } else if (
      // activeStep == 4 &&
      // empState.bankDetails.bankName &&
      // empState.bankDetails.accountNo &&
      // empState.bankDetails.branchName &&
      // empState.bankDetails.ifsccode
    // ) {
    //   return false;
    // }
    else {
      return true;
    }
  }
  else {
    if (
      activeStep != 0 &&
      activeStep != 1 //&&
      //activeStep != 2 &&
      //activeStep != 3
    ) {
      return false;
    }
    if (
      activeStep == 0 &&
      empState.firstName &&
      empState.lastName &&
      empState.professionalDetails.workLocation &&
      empState.professionalDetails.department &&
      empState.professionalDetails.currentDesignation &&
      empState.professionalDetails.holidayCalender &&
      empState.professionalDetails.managerId &&
      empState.professionalDetails.employeeType &&
      empState.professionalDetails.status &&
      empState.professionalDetails.probationPeriod &&
      empState.professionalDetails.noticePeriod &&
      empState.professionalDetails.shift &&
      empState.professionalDetails.dateofJoining &&
      empState.professionalDetails.employeeId 
    ) {
      return false;
    } else if (
      activeStep == 1 &&
      //empState.personalDetails.aadharNumber &&
      //empState.personalDetails.panNumber &&
      empState.personalDetails.email &&
      empState.personalDetails.gender &&
      empState.personalDetails.primaryContactNo &&
      empState.personalDetails.emergencyContactName &&
      empState.personalDetails.emergencyContactNo &&
      empState.personalDetails.maritalStatus
    ) {
      return false;
     } //else if (
    //   activeStep == 2 &&
    //   empState.academicDetails.institute &&
    //   empState.academicDetails.qualification &&
    //   empState.academicDetails.grade &&
    //   empState.academicDetails.fromDate &&
    //   empState.academicDetails.toDate
    // ) {
    //   return false;
    // } else if (
    //   activeStep == 3 &&
    //   empState.bankDetails.bankName &&
    //   empState.bankDetails.accountNo &&
    //   empState.bankDetails.branchName &&
    //   empState.bankDetails.ifsccode
    // ) {
    //   return false;
    // } 
    else {
      return true;
    }
  }
  };

  useEffect(() => {
    if (isSuccess) {
      setShowSnack(true);
      setShowSnackBarmsg("Employee Created Successfully");
      handleCancel();
    }
    if (isError) {
      console.log(error);
    setShowSnack(true);
    setShowSnackBarmsg(
      error && error.data
        ?  error.data
        : "Something Went Wrong. Please Try Again"
    );
    }
  }, [isSuccess, isError]);

  const handleClose = () => {
    setShowSnack(false);
  };


  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        open={showSnack}
        autoHideDuration={4000}
        onClose={handleClose}
      >
        <Alert severity={!isError ? "success" : "error"} variant="filled">
          {snackBarMsg}
        </Alert>
      </Snackbar>
      {isLoading && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
          //onClick={handleClose}
        >
          <CircularProgress
            color="inherit"
            size={100}
            className={classes.circularProgress}
          />

          <p class="text-white text-4xl ml-[115px] mb-[100px]">
            Please Give us a moment...
          </p>

          {/* <CircularProgress color="inherit" /> */}
        </Backdrop>
      )}

      <div className="mt-32 ml-10 pb-4">
        {feildDisabled ? (
          <></>
        ) : (
          <div className="text-3xl mb-10">Add Employee</div>
        )}
        <Stepper activeStep={activeStep} alternativeLabel className="mb-10">
          {(+empState.professionalDetails.totalExperience !== "" &&
          +empState.professionalDetails.totalExperience !== 0
            ? steps
            : steps2
          ).map((label, index) => (
            <Step key={label} completed={completed[index]}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>

        {activeStep === 0 && <BasicInfo />}
        {activeStep === 1 && <EmployeePosition />}
        {activeStep === 2 && <StatutoryInfo />}
        {activeStep === 3 &&
        activeStep === 3 &&
        +empState.professionalDetails.totalExperience !== "" &&
        +empState.professionalDetails.totalExperience !== 0 ? (
          <Experience />
        ) : null}
        {activeStep === 3 &&
        activeStep === 3 &&
        (+empState.professionalDetails.totalExperience === "" ||
          +empState.professionalDetails.totalExperience === 0) ? (
          <PaymentMode />
        ) : null}
        {activeStep === 4 &&
        activeStep === 4 &&
        (+empState.professionalDetails.totalExperience === "" ||
          +empState.professionalDetails.totalExperience === 0) ? (
          <OtherDocs />
        ) : null}
        {activeStep === 4 &&
        activeStep === 4 &&
        +empState.professionalDetails.totalExperience !== "" &&
        +empState.professionalDetails.totalExperience !== 0 ? (
          <PaymentMode />
        ) : null}
        {activeStep === 5 &&
        activeStep === 5 &&
        +empState.professionalDetails.totalExperience !== "" &&
        +empState.professionalDetails.totalExperience !== 0 ? (
          <OtherDocs />
        ) : null}
        {/* {activeStep === 5 && <OtherDocs />} */}

        {errorMessageState === true && getDisabledState() && (
          <div className="text-red-500 text-base">
            Note : Please fill all Mandatory Fields
          </div>
        )}

        <div>
          {feildDisabled ? (
            <div className="flex justify-end space-x-2 mr-16">
              <CustomButton
                label="Previous"
                onClick={handlePrev}
                className={`mr-2 ${
                  activeStep === 0
                    ? "bg-gray-300 text-gray-500 cursor-not-allowed"
                    : ""
                } `}
                disabled={activeStep === 0}
              />
              <CustomButton
                label="Next"
                onClick={handleViewNext}
                className="mr-2 "
                disabled={
                  +empState.professionalDetails.totalExperience === "" ||
                  +empState.professionalDetails.totalExperience === 0
                    ? activeStep === 4
                    : activeStep === 5
                }
              />
            </div>
          ) : (
            <>
              <div className="flex justify-end space-x-2 mr-16">
                <CustomButton
                  label="Previous"
                  onClick={handlePrev}
                  className={`mr-2 ${
                    activeStep === 0
                      ? "bg-gray-300 text-gray-500 cursor-not-allowed"
                      : ""
                  } `}
                  disabled={activeStep === 0}
                />
                {+empState.professionalDetails.totalExperience === "" ||
                +empState.professionalDetails.totalExperience === 0 ? (
                  activeStep !== 4 ? (
                    <div className="group">
                      <CustomButton
                        label="Next"
                        onClick={handleNextWork}
                        className={`mr-2 ${
                          getDisabledState() ? "opacity-50" : ""
                        }`}
                      />
                      {getDisabledState() && (
                        <div className="absolute text-red-500 text-sm px-2 py-1 rounded mt-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300 ease-in-out">
                          Please fill all mandatory fields
                        </div>
                      )}
                    </div>
                  ) : (
                    <CustomButton
                      label="Finish"
                      onClick={() => {
                        createNewEmployee(empState);
                      }}
                      className="bg-blue-primary text-white mr-2"
                    />
                  )
                ) : activeStep !== 5 ? (
                  <div className="group">
                    <CustomButton
                      label="Next"
                      onClick={handleNext}
                      className={`mr-2 ${
                        getDisabledState() ? "opacity-50" : ""
                      }`}
                    />
                    {getDisabledState() && (
                      <div className="absolute text-red-500 text-sm px-2 py-1 rounded mt-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300 ease-in-out">
                        Please fill all mandatory fields
                      </div>
                    )}
                  </div>
                ) : (
                  <CustomButton
                    label="Finish"
                    onClick={() => {
                      createNewEmployee(empState);
                    }}
                    className="bg-blue-primary text-white mr-2"
                  />
                )}

                <CustomButton
                  label="Cancel"
                  onClick={handleCancel}
                  className=""
                />
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default AddEmployee;
