import React, { useEffect, useState } from 'react'
import ViewCalendar from './ViewCalendar';
import { useGetEmployeeCalendarQuery } from '../../api/calendarApi';

const EmployeeCalendar = () => {

    const [calendarData,setCalendarData] = useState();

    const {data, isSuccess, isError} = useGetEmployeeCalendarQuery();

    useEffect(()=>{
        if(isSuccess){
            setCalendarData(data)
        }
    } , [isSuccess]); 

  return (
    <div className='mt-28'>
        { calendarData && isSuccess ?
           <ViewCalendar data={calendarData} delete={false}/> 
           : isError ?
            <p className="text-4xl flex items-center justify-center">Something went wrong</p> 
           : <></>
        }
    </div>
  )
}

export default EmployeeCalendar