import api from "../services/index";

const addEmployeeApi = api.injectEndpoints({
  endpoints: (builder) => ({
    createEmployeeUpdate: builder.mutation({
      query: ({ id, payload }) => ({
        url: `/api/employee/updateEmployee?employeeId=${id}`,
        method: "PUT",
        body: payload,
      }),
    }),
    createNewEmployee: builder.mutation({
      query: (payload) => ({
        url: `/api/employee/create`,
        method: "POST",
        body: payload,
      }),
    }),
    getLatestId: builder.query({
      query: () => "api/employee/getLatestId",
      responseHandler: "text"
      //providesTags: ["Todos"],
    }),
    getAllDapartment: builder.query({
      query: () => "api/department/getAll"
    }),
    getEmployeeList: builder.query({
      query: () => "api/employee/getEmployeesList"
    }),
    getWorkLocations: builder.query({
      query: () => "api/workLocation/getAllWorkLocations"
    }),
    getAllDesignations: builder.query({
      query: () => "api/designation/getAllDesignations"
    }),
    getAllHolidayCalender: builder.query({
      query: () => "api/holidayCalender/getAllHolidayCalenderMap"
    }),
    getAllEmployeeTypes: builder.query({
      query: () => "api/employeeType/getAllEmployeeTypes"
    }),
    getAllDesignationsId : builder.query({
      query: () => "api/designation/getAll"
    })
  }),
});

export default addEmployeeApi;
export const { useCreateEmployeeUpdateMutation, useGetLatestIdQuery, useGetAllDapartmentQuery, useGetEmployeeListQuery, useLazyGetEmployeeListQuery, useGetWorkLocationsQuery, useGetAllDesignationsQuery, useGetAllDesignationsIdQuery, useGetAllHolidayCalenderQuery, useGetAllEmployeeTypesQuery, useCreateNewEmployeeMutation,useLazyGetAllDapartmentQuery,useLazyGetAllDesignationsIdQuery,useLazyGetWorkLocationsQuery } = addEmployeeApi;
