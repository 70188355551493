import React, { useEffect, useState } from 'react'
import CustomButton from "../customComponents/customButton"
import AddCalendar from './AddCalendar';
import { useGetholidayCalendarsQuery } from '../../api/calendarApi';
import ViewCalendar from './ViewCalendar';
import { useDispatch } from 'react-redux';
import { updateErrorCheck,clearCalendar } from '../../store/reducers/calendarSlice';
import { Dialog, DialogActions, DialogContent, Button,Snackbar,Alert} from '@mui/material';
import { serviceUrl,genericHeaders } from '../../utils/apiConfig';
import axios from 'axios';

const HolidayCalender = () => {

    const [viewScreen,setViewScreen] = useState(true);
    const [viewData,setViewData] = useState([]); 
    const [dialogOpen,setDialogOpen] = useState();
    const [calendarId,setCalendarId] = useState();
    const [snack,setSnack] = useState({
      open : false,
      error : false,
      message : ""
    })
    const dispatch = useDispatch();
    const {data, isSuccess, isError, refetch:isRefetchHolidayCalendar} = useGetholidayCalendarsQuery();

    useEffect(() => {
      // isRefetchHolidayCalendar();
    if (data) {
      setViewData(data);
    } else {
      setViewData([]);
    }
    },[data,isSuccess,isError])
    const refetch =()=>{
      isRefetchHolidayCalendar();
      setViewScreen(!viewScreen);
      dispatch(updateErrorCheck(false));
      dispatch(clearCalendar());
    }
    const handleCancel = () => {
      setViewScreen(!viewScreen);
      dispatch(updateErrorCheck(false));
      dispatch(clearCalendar());
    }
    const handleAdd = () => {
      setViewScreen(!viewScreen)
    }
    const handleDeleteDialog = (calendarId) => {
      setDialogOpen(true);
      setCalendarId(calendarId);
    }
    const handleDialogClose = () => {
      setDialogOpen(false);
    }
    const handleDeleteCalendar = async() => {
      let calendar = calendarId;
      try{
        let response = await axios.delete(`${serviceUrl}/api/holidayCalender/deleteHolidayCalender?holidayCalender=${calendar}`,{ headers: genericHeaders() });
        if(response.status === 204){
          isRefetchHolidayCalendar();
          handleDialogClose();
          handleSnackOpen(true,false,"Calendar deleted successfully"); 
        }else{
          handleDialogClose();
          handleSnackOpen(true,true,"Something went wrong"); 
        }
      }catch(err){
        console.error(err)
        handleSnackClose();
        handleSnackOpen(true,true,"Something went wrong");
      }  
    }
    const handleClose =() => {
      setDialogOpen(false);
    }
    const handleSnackOpen = (snackopen,err,msg) => {
      setSnack({
        open : snackopen,
        error : err,
        message : msg
      });
    };
    const handleSnackClose = () => {
      setSnack({
        open : false,
        error : false,
        message : ""
      });
    };
    return (
        <div className='mt-[12vh]'> 
          <Snackbar
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            open={snack.open}
            autoHideDuration={3000}
            onClose={handleSnackClose}
          >
            <Alert severity={snack.error ? "error" : "success"} variant="filled" onClose={handleSnackClose}>
              {snack.message}
            </Alert>
          </Snackbar>
          <Dialog open={dialogOpen} onClose={handleClose} fullWidth>
            <DialogContent>
              <p className='text-2xl'>Are you sure about deleting the holiday calendar?</p>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleDeleteCalendar} >Yes</Button>
              <Button onClick={handleClose}>No</Button>
            </DialogActions>
          </Dialog> 
        
          <div className='w-full flex justify-end'>
            <CustomButton
             label="Add Calendar"
             onClick={handleAdd}
             className={`${viewScreen ? '' : 'hidden'} mr-10`}
            /> 
          </div>

        
          { viewScreen ? (<div className='space-y-10'>{viewData.length === 0 ? <p className='flex justify-center text-3xl mt-20'>No Data</p> : viewData.map((item) => (<ViewCalendar key={item.id} data={item} delete={true} handleDeleteDialog={handleDeleteDialog} />))}</div>) : <AddCalendar cancel={handleCancel} refetch={refetch}/> }
        
        
        </div>
    )
}

export default HolidayCalender;