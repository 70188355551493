import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  attendanceLogin: "",
  attendanceTime: "",
};

const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    setAttendanceLogin: (state, action) => {
      state.attendanceLogin = action.payload;
    },
    setAttendanceTime: (state, action) => {
      state.attendanceTime = action.payload;
    },
  },
});

export const { setAttendanceLogin, setAttendanceTime } = loginSlice.actions;

export default loginSlice.reducer;
