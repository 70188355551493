import React, { useEffect, useState } from 'react'
import { DataGrid } from '@mui/x-data-grid';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import EditIcon from '@mui/icons-material/Edit';
import { FormControl, Select, MenuItem, TextField,Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Checkbox, InputLabel, Alert, Snackbar }from '@mui/material';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import axios from 'axios';
import { genericHeaders, serviceUrl } from '../utils/apiConfig';
import checkCircle from '../Icons/CheckCircle.svg'
import crossCircle from '../Icons/x-circle.svg'
import { useGetEmployeeListQuery } from '../api/addEmployeeApi';
import { cloneDeep } from 'lodash';
import dayjs from 'dayjs';

function LocationDialog(props){
    const [formData,setFormData] = useState({
        "get_id": "",
        "isActive": true,
        "location": "",
        "description": "",
        "country": "",
        "isAllowedToDelete":""
    });
    const [error,setError] = useState(false);
    const dataLoad = () => {
        if(props.id){
            getData();
        }
    }
    useEffect(() => {
        dataLoad();
    },[]);

    async function getData() {
        try{
            let response = await axios.get(`${serviceUrl}/api/workLocation/getWorkLocation?workLocationId=${props.id}`,{headers:genericHeaders()})
            if(response.status === 200){
                let data = response.data; 
                setFormData(data); 
            }
        } catch(err) {
            console.error(err)
        }
    }

    const handleChange = (evt) => {
        let data = {...formData}
        if(/^[a-zA-Z0-9\s]*$/.test(evt.target.value)){
          data[evt.target.name] = evt.target.value;
          setFormData(data);
        } 
    }
    const handleValidation = async(evt) => {
        let data = evt.target.value;
        try{
            let response = await axios.post(`${serviceUrl}/api/workLocation/validationCheck?workLocationName=${data}`,null,{headers:genericHeaders()});
            if(response.status === 200){
               setError(false);
            }
        }catch(err){
           setError(true);
        }
    }
    const handleCheck = () => {
        let data = {...formData}
        data.isActive ? data.isActive = false : data.isActive = true;
        setFormData(data);
    }

    const handleSave = async() =>{
        try {
            if(props.id){
                let response = await axios.put(`${serviceUrl}/api/workLocation/updateWorkLocation?workLocationId=${props.id}`,formData,{headers:genericHeaders()});
                if(response.status === 200){
                    setFormData({
                        "get_id": "",
                        "isActive": true,
                        "location": "",
                        "description": "",
                        "country": "",
                        "isAllowedToDelete":""
                    });
                    props.setClose();
                    props.setSnack();
                    props.snack("Location updated successfully");
                }
            }else{
                let response = await axios.post(`${serviceUrl}/api/workLocation/create`,formData,{headers:genericHeaders()});
                if(response.status === 201){
                    setFormData({
                        "get_id": "",
                        "isActive": true,
                        "location": "",
                        "description": "",
                        "country": "",
                        "isAllowedToDelete":""
                    });
                    props.setClose();
                    props.setSnack();
                    props.snack("Location updated successfully");
            }
            }
        } catch (err) {
            console.error(err)
        }
    }
    return (
        <>
          <DialogContent className='flex flex-col space-y-5'>
            <TextField
            label="Location"
            name="location"
            variant="outlined"
            onChange={handleChange}
            value={formData.location.split()}
            onBlur={handleValidation}
            style={{marginTop: "1rem"}}
            error={error}
            helperText={error ? "Location Already Exists" : ""}
            />
            <TextField
            label="Country"
            name="country"
            variant="outlined"
            onChange={handleChange}
            disabled={error}
            value={formData.country}
            />
            {formData.isAllowedToDelete ? (<FormControlLabel
            control={<Checkbox checked={formData.isActive} onChange={handleCheck} color="primary" disabled={error} />}
            label={"Active Location"}
            />) : <></>}
          </DialogContent>
          <DialogActions>
            <Button onClick={props.setClose}>Close</Button>
            <Button onClick={handleSave} disabled={!formData.location || !formData.country || error}>Save</Button>
          </DialogActions>
        </>
    ) 
}

function DepartmentDialog(props){
    const [formData,setFormData] = useState(
        {
            "get_id": "",
            "isActive": true,
            "departmentName": "",
            "description": "",
            "isAllowedToDelete":""
        }     
    );
    const [error,setError] = useState(false);
    const dataLoad = () => {
        if(props.id){
            getData();
        }
    }
    useEffect(() => {
        dataLoad();
    },[]);

    async function getData(){
        try {
            let response = await axios.get(`${serviceUrl}/api/department/getRecord?departmentId=${props.id}`,{headers:genericHeaders()})
            if(response.status === 200){
               setFormData(response.data) 
            }
        } catch (err) {
            console.error(err)
        }
    }
    const handleChange = (evt) => {
      let data = {...formData}
      if(/^[a-zA-Z0-9\s]*$/.test(evt.target.value)){
        data[evt.target.name] = evt.target.value;
        setFormData(data);
      } 
    }
    const handleCheck = () => {
        let data = {...formData}
        data.isActive ? data.isActive = false : data.isActive = true;
        setFormData(data);
    }
    const handleValidation = async(evt) => {
        let data = evt.target.value;
        try{
            let response = await axios.post(`${serviceUrl}/api/department/validationCheck?departmentName=${data}`,null,{headers:genericHeaders()});
            if(response.status === 200){
               setError(false);
            }
        }catch(err){
           setError(true);
        }
    }
    const handleSave = async() =>{  
        try {
            if(props.id){
              let response = await axios.put(`${serviceUrl}/api/department/updateDepartment?departmentId=${props.id}`,formData,{headers:genericHeaders()})
              if(response.status === 200){
                setFormData({
                    "get_id": "",
                    "isActive": true,
                    "location": "",
                    "description": "",
                    "country": "",
                    "isAllowedToDelete":""
                });
                props.setClose();
                props.setSnack();
                props.snack("Department updated successfully");
            } 
            }else{
                let response = await axios.post(`${serviceUrl}/api/department/create`,formData,{headers:genericHeaders()});
                if(response.status === 201){
                    setFormData({
                        "get_id": "",
                        "isActive": true,
                        "location": "",
                        "description": "",
                        "country": "",
                        "isAllowedToDelete":""
                    });
                    props.setClose();
                    props.setSnack();
                    props.snack("Department updated successfully");
                } 
            }
        } catch (err) {
            console.error(err)
        }
    }
    return (
        <>
        <DialogContent className='flex flex-col space-y-5'>
          <TextField
           label="Department"
           name="departmentName"
           variant="outlined"
           onChange={handleChange}
           value={formData.departmentName}
           onBlur={handleValidation}
           error={error}
           helperText={error ? "Department Name Already Exists" : ""}
           style={{marginTop: "1rem"}}
          />
          <TextField
           label="Description"
           name="description"
           variant="outlined"
           onChange={handleChange}
           disabled={error}
           value={formData.description}
          />
          {formData.isAllowedToDelete ? <FormControlLabel
            control={<Checkbox checked={formData.isActive} onChange={handleCheck} color="primary" disabled={error} />}
            label={"Active Department"}
          /> : <></>}
        </DialogContent>
        <DialogActions>
            <Button onClick={props.setClose}>Close</Button>
            <Button onClick={handleSave} disabled={!formData.departmentName || !formData.description || error}>Save</Button>
        </DialogActions>
        </>
    ) 
}

function DesignationDialog(props){
    const [departments, setDepartments] = useState([])
    const [error,setError] = useState(false);
    const [formData,setFormData] = useState({
            "get_id": "",
            "isActive": true,
            "designation": "",
            "description": "",
            "departmentId": "",
            "isAllowedToDelete":""
    });

    const dataLoad = () => {
        if(props.id){
            getData();
        }
    }
    useEffect(() => {
        dataLoad();
    },[]);

    async function getData() {
        try{
            let response = await axios.get(`${serviceUrl}/api/designation/getDesignation?designationId=${props.id}`,{headers:genericHeaders()})
            if(response.status === 200){
               setFormData( response.data) 
            }
        } catch(err) {
            console.error(err)
        }
    }
    const getDepartmentData = async() => {
        try{
           let response = await axios.get(`${serviceUrl}/api/department/getAll`,{headers:genericHeaders()})
           if(response.status === 200){
            setDepartments(response.data);
           }
        }catch(err){
            console.error(err)
        }
    }
    useEffect( () => {
        getDepartmentData();
    },[]);
    
    const handleChange = (evt) => {
      let data = {...formData}
      if(/^[a-zA-Z0-9\s]*$/.test(evt.target.value)){
        data[evt.target.name] = evt.target.value;
        setFormData(data);
      } 
    }
    const handleCheck = () => {
        let data = {...formData}
        data.isActive ? data.isActive = false : data.isActive = true;
        setFormData(data);
    }
    const handleValidation = async(evt) => {
        let data = evt.target.value;
        try{
            let response = await axios.post(`${serviceUrl}/api/designation/validationCheck?designationName=${data}`,null,{headers:genericHeaders()});
            if(response.status === 200){
               setError(false);
            }
        }catch(err){
           setError(true);
        }
    }
    const handleSave = async() =>{
        try {
          if (props.id) {
            let response = await axios.put(
              `${serviceUrl}/api/designation/updateDesignation?designationId=${props.id}`,
              formData,
              { headers: genericHeaders() }
            );
            if (response.status === 200) {
              setFormData({
                get_id: "",
                isActive: true,
                location: "",
                description: "",
                country: "",
                isAllowedToDelete:""
              });
              props.setClose();
              props.setSnack();
              props.snack("Designation updated successfully");
            }
          } else {
            let response = await axios.post(
              `${serviceUrl}/api/designation/create`,
              formData,
              { headers: genericHeaders() }
            );
            if (response.status === 201) {
              setFormData({
                get_id: "",
                isActive: true,
                location: "",
                description: "",
                country: "",
                isAllowedToDelete:""
              });
              props.setClose();
              props.setSnack();
              props.snack("Designation updated successfully");
            }
          }
        } catch (err) {
          console.error(err);
        }
         
    }
    return (
        <>
            <DialogContent className='flex flex-col m-2 space-y-5'>
            <TextField
            label="Designation"
            name="designation"
            variant="outlined"
            onChange={handleChange}
            value={formData.designation}
            onBlur={handleValidation}
            error={error}
            helperText={error ? "Designation Already Exists" : ""}
            style={{marginTop: "1rem"}}
            />
            <FormControl>
                <InputLabel htmlFor="department">Department</InputLabel>
                <Select
                 id="department"
                 label="Department"
                 name="departmentId"
                 onChange={handleChange}
                 value={formData.departmentId}
                 disabled={error}
                >
                {(departments).map((item) => {
                 return(
                  <MenuItem value={item._id}>{item.departmentName}</MenuItem>
                 )})}
                </Select>
            </FormControl>
            {formData.isAllowedToDelete ? <FormControlLabel
              control={<Checkbox checked={formData.isActive} onChange={handleCheck} color="primary" disabled={error} />}
              label={"Active Designation"}
            /> : <></>}
            </DialogContent>
            <DialogActions>
                <Button onClick={props.setClose}>Close</Button>
                <Button onClick={handleSave} disabled={!formData.designation || !formData.departmentId || error}>Save</Button>
            </DialogActions>
        </>
    ) 
}

function LeaveCategoryDialog(props){
    const [formData,setFormData] = useState({
            "get_id": "",
            "isActive": true,
            "leaveCategory": "",
            "description": "",
            "isAllowedToDelete":""
        });
        const [error,setError] = useState(false);
        const dataLoad = () => {
            if(props.id){
                getData();
            }
        }
        useEffect(() => {
            dataLoad();
        },[]);
    
        async function getData() {
            try{
                let response = await axios.get(`${serviceUrl}/api/leaveCategory/getLeaveCategory?leaveCategoryId=${props.id}`,{headers:genericHeaders()})
                if(response.status === 200){
                    setFormData(response.data); 
                }
            } catch(err) {
                console.error(err)
            }
        }
    
        const handleChange = (evt) => {
          let data = {...formData}
          if(/^[a-zA-Z0-9\s]*$/.test(evt.target.value)){
            data[evt.target.name] = evt.target.value;
            setFormData(data);
          } 
        }
        const handleValidation = async(evt) => {
            let data = evt.target.value;
            try{
                let response = await axios.post(`${serviceUrl}/api/leaveCategory/validationCheck?leaveCategoryName=${data}`,null,{headers:genericHeaders()});
                if(response.status === 200){
                   setError(false);
                }
            }catch(err){
               setError(true);
            }
        }
        const handleCheck = () => {
            let data = {...formData}
            data.isActive ? data.isActive = false : data.isActive = true;
            setFormData(data);
        }
    
        const handleSave = async() =>{
            try {
                if(props.id){
                    let response = await axios.put(`${serviceUrl}/api/leaveCategory/updateLeaveCategory?leaveCatrgoryId=${props.id}`,formData,{headers:genericHeaders()});
                    if(response.status === 200){
                        setFormData({
                            "get_id": "string",
                            "isActive": true,
                            "leaveCategory": "Sick Leave",
                            "description": "sick",
                            "isAllowedToDelete":""
                          });
                        props.setClose();
                        props.setSnack();
                        props.snack("Leave category updated successfully");
                    }
                }else{
                    let response = await axios.post(`${serviceUrl}/api/leaveCategory/create`,formData,{headers:genericHeaders()});
                    if(response.status === 201){
                        setFormData({
                            "get_id": "string",
                            "isActive": true,
                            "leaveCategory": "Sick Leave",
                            "description": "sick",
                            "isAllowedToDelete":""
                          });
                        props.setClose();
                        props.setSnack();
                        props.snack("Leave category updated successfully");
                }
                }
            } catch (err) {
                console.error(err)
            }
        }
        return (
            <>
              <DialogContent className='flex flex-col space-y-5'>
                <TextField
                label="Leave Category"
                name="leaveCategory"
                variant="outlined"
                onChange={handleChange}
                value={formData.leaveCategory}
                onBlur={handleValidation}
                style={{marginTop: "1rem"}}
                error={error}
                helperText={error ? "Leave Category Already Exists" : ""}
                />
                <TextField
                label="Description"
                name="description"
                variant="outlined"
                onChange={handleChange}
                disabled={error}
                value={formData.description}
                />
                {formData.isAllowedToDelete ? <FormControlLabel
                control={<Checkbox checked={formData.isActive} onChange={handleCheck} color="primary" disabled={error} />}
                label={"Active Location"}
                />:<></>}
              </DialogContent>
              <DialogActions>
                <Button onClick={props.setClose}>Close</Button>
                <Button onClick={handleSave} disabled={!formData.leaveCategory || !formData.description || error}>Save</Button>
              </DialogActions>
            </>
        ) 
}

function EmployeeCategoryDialog(props){
    const [formData,setFormData] = useState({
        "get_id": "",
        "isActive": true,
        "employeeCategory": "",
        "description": "",
        "isAllowedToDelete":""
      });
    const [error,setError] = useState(false);
    const dataLoad = () => {
        if(props.id){
            getData();
        }
    }
    useEffect(() => {
        dataLoad();
    },[]);

    async function getData() {
        try{
            let response = await axios.get(`${serviceUrl}/api/employeeType/getEmployeeCategory?employeeCategoryId=${props.id}`,{headers:genericHeaders()})
            if(response.status === 200){
                setFormData(response.data); 
            }
        } catch(err) {
            console.error(err)
        }
    }

    const handleChange = (evt) => {
      let data = {...formData}
      if(/^[a-zA-Z0-9\s]*$/.test(evt.target.value)){
        data[evt.target.name] = evt.target.value;
        setFormData(data);
      } 
    }
    const handleValidation = async(evt) => {
        let data = evt.target.value;
        try{
            let response = await axios.post(`${serviceUrl}/api/employeeType/validationCheck?employeeCategoryName=${data}`,null,{headers:genericHeaders()})
            if(response.status === 200){
               setError(false);
            }
        }catch(err){
           setError(true);
        }
    }
    const handleCheck = () => {
        let data = {...formData}
        data.isActive ? data.isActive = false : data.isActive = true;
        setFormData(data);
    }

    const handleSave = async() =>{
        try {
            if(props.id){
                let response = await axios.put(`${serviceUrl}/api/employeeType/updateEmployeeType?employeeCategoryId=${props.id}`,formData,{headers:genericHeaders()});
                if(response.status === 200){
                    setFormData({
                            "get_id": "",
                            "isActive": true,
                            "employeeCategory": "",
                            "description": "",
                            "isAllowedToDelete":""
                        });
                    props.setClose();
                    props.setSnack();
                    props.snack("Employee category updated successfully");
                }
            }else{
                let response = await axios.post(`${serviceUrl}/api/employeeType/create`,formData,{headers:genericHeaders()});
                if(response.status === 201){
                    setFormData({
                        "get_id": "",
                        "isActive": true,
                        "employeeCategory": "",
                        "description": "",
                        "isAllowedToDelete":""
                    });
                    props.setClose();
                    props.setSnack();
                    props.snack("Employee category updated successfully");
            }
            }
        } catch (err) {
            console.error(err)
        }
    }
    return (
        <>
          <DialogContent className='flex flex-col space-y-5'>
            <TextField
            label="Employee Category"
            name="employeeCategory"
            variant="outlined"
            onChange={handleChange}
            value={formData.employeeCategory}
            onBlur={handleValidation}
            style={{marginTop: "1rem"}}
            error={error}
            helperText={error ? "Employee Category Already Exists" : ""}
            />
            <TextField
            label="Description"
            name="description"
            variant="outlined"
            onChange={handleChange}
            disabled={error}
            value={formData.description}
            />
            {formData.isAllowedToDelete ? <FormControlLabel
              control={<Checkbox checked={formData.isActive} onChange={handleCheck} color="primary" disabled={error} />}
              label={"Active Location"}
            /> : <></>}
          </DialogContent>
          <DialogActions>
            <Button onClick={props.setClose}>Close</Button>
            <Button onClick={handleSave} disabled={!formData.employeeCategory || !formData.description || error}>Save</Button>
          </DialogActions>
        </>
    ) 
}

function ProjectsDialog(props) {
  const [formData, setFormData] = useState({
    "get_id": "",
    "isActive": true,
    "project": "",
    "description": "",
    "projectLead": "",
    "projectStatus": "",
    "projectStartDate": "",
    "projectEndDate": ""
  });
  const [empList,setEmpList] = useState([]);
  const [error, setError] = useState(false);
  const {data,isSuccess,isError} = useGetEmployeeListQuery();
  const dataLoad = () => {
    if (props.id) {
      getData();
    }
  }
  useEffect(() => {
    dataLoad();
  }, []);
  useEffect(() => {
    if(isSuccess){
      let newData = cloneDeep(data);
      setEmpList(newData);
    }
  },[isSuccess])
  async function getData() {
    try {
      let response = await axios.get(`${serviceUrl}/api/project/getProjectById?projectId=${props.id}`, { headers: genericHeaders() })
      if (response.status === 200) {
        setFormData(response.data);
      }
    } catch (err) {
      console.error(err)
    }
  }

  const handleChange = (evt) => {
    let data = {...formData}
    if(/^[a-zA-Z0-9\s]*$/.test(evt.target.value)){
      data[evt.target.name] = evt.target.value;
      setFormData(data);
    }  
  }
  const handleValidation = async(evt) => {
    let data = evt.target.value;
    try{
        let response = await axios.post(`${serviceUrl}/api/project/validationCheck?projectName=${data}`,null,{headers:genericHeaders()})
        if(response.status === 200){
           setError(false);
        }
    }catch(err){
       setError(true);
    }
  }

const handleCheck = () => {
    let data = {...formData}
    data.isActive ? data.isActive = false : data.isActive = true;
    setFormData(data);
}
const startDateDisabled = (date) => {
  const local = dayjs(date);
  if(formData.projectEndDate){
    const endDate = dayjs(formData.projectEndDate).subtract(1,'day');
    if(local.isAfter(endDate)){
      return true;
    }
  }
} 
const endDateDisabled = (date) => {
  const local = dayjs(date);
  if(formData.projectStartDate){
    const startDate = dayjs(formData.projectStartDate).add(1,'day');
    if(local.isBefore(startDate)){
      return true;
    }
  }
}
const handleDate = (evt, type) => {
  let data = { ...formData };
  const localDate = dayjs(evt).format("YYYY-MM-DDTHH:mm:ss.SSS");
  if(type === "start") {
    data.projectStartDate = localDate;
  }else if(type === "end") {
    data.projectEndDate = localDate;
  }
  setFormData(data);
}
  const handleSave = async () => {
    let payload = {...formData};
    if(payload.projectEndDate === ""){
      delete payload.projectEndDate;
    }
    try {
      if (props.id) {
        let response = await axios.put(`${serviceUrl}/api/project/updateProject?projectId=${props.id}`, payload, { headers: genericHeaders() });
        if (response.status === 200) {
          setFormData({
                "get_id": "",
                "isActive": true,
                "project": "",
                "description": "",
                "projectLead": "",
                "projectStatus": "",
                "projectStartDate": "",
                "projectEndDate": ""
              });
          props.setClose();
          props.setSnack();
          props.snack("Project updated successfully");
        }
      } else {
        let response = await axios.post(`${serviceUrl}/api/project/create`, payload, { headers: genericHeaders() });
        if (response.status === 201) {
          setFormData({
                "get_id": "",
                "isActive": true,
                "project": "",
                "description": "",
                "projectLead": "",
                "projectStatus": "",
                "projectStartDate": "",
                "projectEndDate": ""
              });
          props.setClose();
          props.setSnack();
          props.snack("Project updated successfully");
        }
      }
    } catch (err) {
      console.error(err)
    }
  }
  return (
    <>
     <DialogContent>
        <div className='grid grid-cols-2 gap-5'>
          <TextField
            label={
              <span>
                Projects<span style={{ color: 'red' }}>*</span>
              </span>
            }
            name="project"
            variant="outlined"
            onChange={handleChange}
            value={formData.project}
            onBlur={handleValidation}
            error={error}
            helperText={error ? "Project Already Exists" : ""}
            sx={{
              "& .MuiInputBase-root": {
                height: 50
              },
              marginTop: 1
            }}
          />
          <TextField
            label="Description"
            name="description"
            variant="outlined"
            onChange={handleChange}
            disabled={error}
            value={formData.description}
            sx={{
              "& .MuiInputBase-root": {
                height: 50
              },
              marginTop: 1
            }}
          />
          <FormControl>
            <InputLabel htmlFor="projectLead">Project Lead<span style={{ color: '#C90003' }}>*</span></InputLabel>
            <Select
              id="projectLead"
              label="Project Lead"
              name="projectLead"
              onChange={handleChange}
              disabled={error}
              value={formData.projectLead}
              MenuProps={{
                sx: {
                  '& .MuiList-root': {
                    maxHeight: 300,
                  },
                  "& .MuiInputBase-root": {
                    height: 50
                  },
                },
              }}
            >
              {empList.map((item) => {
                return <MenuItem value={item.id}>{item.name}</MenuItem>;
              })}
            </Select>
          </FormControl>
          <FormControl>
            <InputLabel htmlFor="projectStatus">Project Status<span style={{ color: '#C90003' }}>*</span></InputLabel>
            <Select
              id="projectStatus"
              label="Project Status"
              name="projectStatus"
              onChange={handleChange}
              disabled={error}
              value={formData.projectStatus}
            >
              <MenuItem value="RED">Red</MenuItem>
              <MenuItem value="AMBER">Amber</MenuItem>
              <MenuItem value="GREEN">Green</MenuItem>
            </Select>
          </FormControl>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label={
                <span>
                  Start Date<span style={{ color: 'red' }}>*</span>
                </span>
              }
              name="projectStartDate"
              format="DD MMM YYYY"
              className="w-4/5"
              disabled={error}
              value={formData.projectStartDate ? dayjs(formData.projectStartDate) : null}
              onChange={(e) => handleDate(e, "start")}
              disableFuture
              shouldDisableDate={(date) => startDateDisabled(date)}
              slotProps={{
                textField: {
                  readOnly: true,
                },
              }}
              sx={{
                width: 265
              }}
            />
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="End Date"
              name="projectEndDate"
              format="DD MMM YYYY"
              className="w-4/5"
              disabled={error}
              disableFuture
              value={formData.projectEndDate ? dayjs(formData.projectEndDate) : null}
              onChange={(e) => handleDate(e, "end")}
              shouldDisableDate={(date) => endDateDisabled(date)}
              slotProps={{
                textField: {
                  readOnly: true,
                },
              }}
              sx={{
                width: 265
              }}
            />
          </LocalizationProvider>
          {/* <FormControlLabel
          control={<Checkbox checked={formData.isActive} onChange={handleCheck} color="primary" disabled={error} />}
          label={"Active Location"}
        /> */}
        </div>
        <p className='text-xs text-red-500 mt-1'>Note: Please enter all the mandatory fields.</p>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.setClose}>Close</Button>
        <Button onClick={handleSave} disabled={!formData.project || !formData.projectLead || !formData.projectStartDate || !formData.projectStatus || error}>Save</Button>
      </DialogActions>
    </>
  )
}

const ManageLookups = () => {
    const [lookup,setLookup] = useState("Department");
    const [open,setOpen] = useState(false);
    const [snack,setSnack] = useState(false);
    const [id,setId] = useState(null);
    const [column,setColumn]= useState([]);
    const [row,setRow]=useState([]);
    const [snackMessage,setSnackMessage] = useState("");

    const getGridData = (lookup) => {
       switch (lookup) {
         case "Work Location":
           getLocationData();
           break;
         case "Designation":
           getDesignationData();
           break;
         case "Department":
           getDepartmentData();
           break;
         case "Leave Category":
            getLeaveData();
            break;
         case "Employee Category":
            getEmployeeData();
            break;
         case "Projects":
            getProjects();
            break;
       }
    }
    useEffect( () => {
        getGridData(lookup); 
    },[lookup]);

    const handleLookups = (evt) => {
        let data = evt.target.value;
        setLookup(data);
    }

    const handleClickOpen = () => {
        setOpen(true);
    }
    
    const handleClose = () => {
        setId(null);
        setOpen(false);
        if(lookup === "Work Location"){
            getLocationData();
        }else if(lookup === "Designation"){
            getDesignationData();
        }else if(lookup === "Department"){
            getDepartmentData();
        }else if(lookup === "Leave Category"){
            getLeaveData();
        }else if(lookup === "Employee Category"){
            getEmployeeData();
        }else if(lookup === "Projects"){
            getProjects();
        }
    }
    
    const handleSnackOpen = () => {
        setSnack(true);
    }

    const handleSnackClose = () => {
        setSnack(false);
    }

    const CustomDialog = (data) => {
        if(data === "Work Location"){
         return (<LocationDialog  setClose={() => handleClose()} setSnack={() => handleSnackOpen()} snack={setSnackMessage} id={id}/>)
        }else if(data === "Designation"){
         return (<DesignationDialog setClose={() => handleClose()} setSnack={() => handleSnackOpen()} snack={setSnackMessage} id={id}/>)
        }else if(data === "Department"){
         return (<DepartmentDialog setClose={() => handleClose()} setSnack={() => handleSnackOpen()} snack={setSnackMessage} id={id}/>)
        }else if(data === "Leave Category"){
         return (<LeaveCategoryDialog setClose={() => handleClose()} setSnack={() => handleSnackOpen()} snack={setSnackMessage} id={id}/>)
        }else if(data === "Employee Category"){
         return (<EmployeeCategoryDialog setClose={() => handleClose()} setSnack={() => handleSnackOpen()} snack={setSnackMessage} id={id}/>)
        }else if(data === "Projects"){
         return (<ProjectsDialog setClose={() => handleClose()} setSnack={() => handleSnackOpen()} snack={setSnackMessage} id={id}/>)
        }
     }
    
    const getLocationData = async() => {
      try{
        let response = await axios.get(`${serviceUrl}/api/workLocation/getAllWorkLocations`,{headers:genericHeaders()});
        if(response.status === 200){
            setColumn([{ field: "locations", headerName : "Locations", width: 200 },
                       { field: "country", headerName : "Country", width: 200 },
                       { field: "status", headerName : "Status", width: 200 , renderCell : (params) => {
                        if(params.row.status === true){
                            return <img className="w-5 ml-2" src={checkCircle} alt="ICON"/>;
                        }else if((params.row.status === false)) {
                            return <img className="w-6 ml-1.5" src={crossCircle} alt="ICON"/>;
                        }
                       }},
                       { field: 'action', headerName : 'Actions',width: 200 , renderCell : (params) => 
                        params.row.delete ? 
                        <>
                        <EditIcon className="cursor-pointer" onClick={() => {
                            setId(params.id);
                            setOpen(true);
                        }}/>
                        <DeleteRoundedIcon className="cursor-pointer mr-2" onClick={() => handleLocationDelete(params.id)}/>
                        </> : <>
                        <EditIcon className="cursor-pointer" onClick={() => {
                            setId(params.id);
                            setOpen(true);
                        }}/>
                        </>
                        }]
                       );
            setRow(response.data.map( (row) => ({
                locations : row.location,
                country : row.country,
                status: row.isActive,
                id : row._id,
                delete : row.isAllowedToDelete
            })))
        }
      }catch(err){
        setRow([]);
      }
    }
    const getDesignationData = async() => {
        try{
            let response = await axios.get(`${serviceUrl}/api/designation/getAll`,{headers:genericHeaders()});
            if(response.status === 200){
                setColumn([{ field: "designation", headerName : "Designation", width: 200  },
                           { field: "department", headerName : "Department", width: 200  },
                           { field: "status", headerName : "Status", width: 200 , renderCell : (params) => {
                            if(params.row.status === true){
                                return <img className="w-5 ml-2" src={checkCircle} alt="ICON"/>;
                            }else if((params.row.status === false)) {
                                return <img className="w-6 ml-1.5" src={crossCircle} alt="ICON"/>;
                            }
                           }},
                           { field: 'actions', headerName : 'Actions',width: 200 , renderCell : (params) => 
                           params.row.delete ? 
                            <>
                             <EditIcon className="cursor-pointer" onClick={() => {
                                setId(params.id);
                                setOpen(true);
                             }}/>
                             <DeleteRoundedIcon className="cursor-pointer mr-2" onClick={() => handleDesignationDelete(params.id)}/>
                            </>
                            :
                            <>
                            <EditIcon className="cursor-pointer" onClick={() => {
                                setId(params.id);
                                setOpen(true);
                             }}/>
                            </>
                            }]);
                setRow(response.data.map( (row) => ({
                    designation : row.designation,
                    department : row.departmentId,
                    status: row.isActive,
                    id : row._id,
                    delete : row.isAllowedToDelete
                   })))          
            }
        }catch(err){
            setRow([]);
        }
    }
    const getDepartmentData = async() => {
        try{
            let response = await axios.get(`${serviceUrl}/api/department/getAll`,{headers:genericHeaders()});
            if(response.status === 200){
                setColumn([{ field: "departmentName", headerName : "Department", width: 200  },
                           { field: "description", headerName : "Description", width: 200  },
                           { field: "status", headerName : "Status", width: 200 , renderCell : (params) => {
                            if(params.row.status === true){
                                return <img className="w-5 ml-2" src={checkCircle} alt="ICON"/>;
                            }else if((params.row.status === false)) {
                                return <img className="w-6 ml-1.5" src={crossCircle} alt="ICON"/>;
                            }
                           }},
                           { field: 'actions', headerName : 'Actions',width: 200 , renderCell : (params) => 
                           params.row.delete ?
                            <>
                            <EditIcon className="cursor-pointer" onClick={() => {
                                setId(params.id);
                                setOpen(true);
                             }}/>
                             <DeleteRoundedIcon className="cursor-pointer mr-2" onClick={() => handleDepartmentDelete(params.id)}/>
                            </> :
                            <EditIcon className="cursor-pointer" onClick={() => {
                                setId(params.id);
                                setOpen(true);
                             }}/>
                            }]);
                setRow(response.data.map( (row) => ({
                    departmentName : row.departmentName,
                    description : row.description,
                    status: row.isActive,
                    id : row._id,
                    delete : row.isAllowedToDelete
                })))          
            }
        }catch(err){
            setRow([]);
        }
    }
    const getLeaveData = async() => {
      
        try{
            let response = await axios.get(`${serviceUrl}/api/leaveCategory/getAllLeaveCategories`,{headers:genericHeaders()})
            if (response.status === 200) {
              setColumn([
                {
                  field: "leaveCategory",
                  headerName: "Leave Category",
                  width: 200,
                },
                { field: "description", headerName: "Description", width: 200 },
                {
                  field: "status",
                  headerName: "Status",
                  width: 200,
                  renderCell: (params) => {
                    if (params.row.status === true) {
                      return (
                        <img
                          className="w-5 ml-2"
                          src={checkCircle}
                          alt="ICON"
                        />
                      );
                    } else if (params.row.status === false) {
                      return (
                        <img
                          className="w-6 ml-1.5"
                          src={crossCircle}
                          alt="ICON"
                        />
                      );
                    }
                  },
                },
                {
                  field: "actions",
                  headerName: "Actions",
                  width: 200,
                  renderCell: (params) =>
                    params.row.delete ? (
                      <>
                        <EditIcon
                          className="cursor-pointer"
                          onClick={() => {
                            setId(params.id);
                            setOpen(true);
                          }}
                        />
                        <DeleteRoundedIcon
                          className="cursor-pointer mr-2"
                          onClick={() => handleLeaveDelete(params.id)}
                        />
                      </>
                    ) : (
                      <EditIcon
                        className="cursor-pointer"
                        onClick={() => {
                          setId(params.id);
                          setOpen(true);
                        }}
                      />
                    ),
                },
              ]);
              setRow(
                response.data.map((row) => ({
                  leaveCategory: row.leaveCategory,
                  description: row.description,
                  status: row.isActive,
                  id: row._id,
                  delete : row.isAllowedToDelete
                }))
              );
            }
        } catch (err) {
            setRow([]);
        }
    }
    const getEmployeeData = async () => {
        try{
           let response = await axios.get(`${serviceUrl}/api/employeeType/getAllEmployeeTypes`,{headers:genericHeaders()})
            if(response.status === 200){
                setColumn([
                  {
                    field: "employeeCategory",
                    headerName: "Employee Category",
                    width: 200,
                  },
                  {
                    field: "description",
                    headerName: "Description",
                    width: 200,
                  },
                  {
                    field: "status",
                    headerName: "Status",
                    width: 200,
                    renderCell: (params) => {
                      if (params.row.status === true) {
                        return (
                          <img
                            className="w-5 ml-2"
                            src={checkCircle}
                            alt="ICON"
                          />
                        );
                      } else if (params.row.status === false) {
                        return (
                          <img
                            className="w-6 ml-1.5"
                            src={crossCircle}
                            alt="ICON"
                          />
                        );
                      }
                    },
                  },
                  {
                    field: "actions",
                    headerName: "Actions",
                    width: 200,
                    renderCell: (params) =>
                      params.row.delete ? (
                        <>
                          <EditIcon
                            className="cursor-pointer"
                            onClick={() => {
                              setId(params.id);
                              setOpen(true);
                            }}
                          />
                          <DeleteRoundedIcon
                            className="cursor-pointer mr-2"
                            onClick={() =>
                              handleEmployeeCategoryDelete(params.id)
                            }
                          />
                        </>
                      ) : (
                        <EditIcon
                          className="cursor-pointer"
                          onClick={() => {
                            setId(params.id);
                            setOpen(true);
                          }}
                        />
                      ),
                  },
                ]);
                setRow(
                    response.data.map((row) => ({
                      employeeCategory: row.employeeCategory,
                      description: row.description,
                      status: row.isActive,
                      id: row._id,
                      delete : row.isAlowedToDelete
                    }))
                  );
                }
            } catch(err){
                setRow([]);
            }
    }
    const getProjects = async () => {
        try{
            let response = await axios.get(`${serviceUrl}/api/project/getAllProjects`,{headers:genericHeaders()})
            if(response.status === 200){
                setColumn([
                  {
                    field: "project",
                    headerName: "Project",
                    width: 200,
                  },
                  {
                    field: "description",
                    headerName: "Description",
                    width: 200,
                  },
                  {
                    field: "status",
                    headerName: "Status",
                    width: 200,
                    renderCell: (params) => {
                      if (params.row.status === true) {
                        return (
                          <img
                            className="w-5 ml-2"
                            src={checkCircle}
                            alt="ICON"
                          />
                        );
                      } else if (params.row.status === false) {
                        return (
                          <img
                            className="w-6 ml-1.5"
                            src={crossCircle}
                            alt="ICON"
                          />
                        );
                      }
                    },
                  },
                  {
                    field: "actions",
                    headerName: "Actions",
                    width: 200,
                    renderCell: (params) =>
                      // params.row.delete ? (
                      //   <>
                      //     <EditIcon
                      //       className="cursor-pointer"
                      //       onClick={() => {
                      //         setId(params.id);
                      //         setOpen(true);
                      //       }}
                      //     />
                      //     <DeleteRoundedIcon
                      //       className="cursor-pointer mr-2"
                      //       onClick={() =>
                      //         handleProjectDelete(params.id)
                      //       }
                      //     />
                      //   </>
                      // ) :
                       (
                        <EditIcon
                          className="cursor-pointer"
                          onClick={() => {
                            setId(params.id);
                            setOpen(true);
                          }}
                        />
                      ),
                  },
                ]);
                setRow(
                    response.data.map((row) => ({
                      project: row.project,
                      description: row.description,
                      status: row.isActive,
                      id: row._id,
                      delete : row.isAlowedToDelete
                    }))
                  );
                }
        }catch(err){
            setRow([]);
        }
    }
    const handleLocationDelete = async(id) => {
        try{
         let response = await axios.delete(`${serviceUrl}/api/workLocation/deleteWorkLocation?workLocationId=${id}`,{headers:genericHeaders()})
         if(response.status === 204){
            setSnackMessage("Location Deleted Successfully");
            handleSnackOpen();
            getLocationData();
         }
        }catch(err){
            console.error(err)
        }
    }
    const handleDepartmentDelete = async(id) => {
        try{
            let response = await axios.delete(`${serviceUrl}/api/department/delete?departmentId=${id}`,{headers:genericHeaders()})
            if(response.status === 204){
                setSnackMessage("Department Deleted Successfully");
                handleSnackOpen();
                getDepartmentData();
            }
        }catch(err){
            console.error(err)
        }
    }
    const handleDesignationDelete = async(id) => {
        try{
            let response = await axios.delete(`${serviceUrl}/api/designation/deleteDesignation?designationId=${id}`,{headers:genericHeaders()})
            if(response.status === 204){
                setSnackMessage("Designation Deleted Successfully");
                handleSnackOpen();
                getDesignationData();
            }
        }catch(err){
            console.error(err);
        }
    }
    const handleLeaveDelete = async(id) => {
        try{
            let response = await axios.delete(`${serviceUrl}/api/leaveCategory/deleteLeaveCategory?leaveCategoryId=${id}`,{headers:genericHeaders()})
            if(response.status === 204){
                setSnackMessage("Leave Category Deleted Successfully");
                handleSnackOpen();
                getLeaveData();
            }
        }catch(err){
        console.error(err);
        }
    }
    const handleEmployeeCategoryDelete = async(id) => {
        try{
            let response = await axios.delete(`${serviceUrl}/api/employeeType/deleteEmployeeCategory?employeeCategoryId=${id}`,{headers:genericHeaders()})
            if(response.status === 204){
                setSnackMessage("Employee Category Deleted Successfully");
                handleSnackOpen();
                getEmployeeData();
            }
        }catch(err){
        console.error(err);
        }
    }
    const handleProjectDelete = async(id) => {
        try{
            let response = await axios.delete(`${serviceUrl}/api/project/deleteProject?projectId=${id}`,{headers:genericHeaders()})
            if(response.status === 204){
                setSnackMessage("Project Deleted Successfully");
                handleSnackOpen();
                getProjects();
            }
        }catch(err){
        console.error(err);
        }
    }
  return (
    <div className='m-16 mt-[7rem]'>
        
        <Dialog open={open} onClose={handleClose} fullWidth>
            <DialogTitle>Add {lookup}</DialogTitle>
            {CustomDialog(lookup)}
        </Dialog> 

        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }} 
          open={snack}
          autoHideDuration={2000}
          onClose={handleSnackClose}
        >
            <Alert
              severity="success"
              variant="filled"
              onClose={handleSnackClose}
              >{snackMessage}
            </Alert>
        </Snackbar>

        <div className='flex flex-row w-1/4'>
            <h4 className='text-l'>Manage Lookups:</h4>
            <FormControl fullWidth variant="outlined">
              <Select
                id="workLocation"
                name="workLocation"
                fullWidth
                value={lookup}
                classes={{ select: 'h-96' }}
                onChange={handleLookups}
              >
                <MenuItem value={"Department"}>Department</MenuItem>
                <MenuItem value={"Designation"}>Designation</MenuItem> 
                <MenuItem value={"Leave Category"}>Leave Category</MenuItem>
                <MenuItem value={"Work Location"}>Work Location</MenuItem>
                <MenuItem value={"Employee Category"}>Employee Category</MenuItem>
                <MenuItem value={"Projects"}>Projects</MenuItem>
              </Select>
            </FormControl>
        </div>

        <div className='m-5 flex justify-end'>
           <Button variant='contained' onClick={handleClickOpen}>Add {lookup}</Button>
        </div>

        <div className='w-3/4 mx-auto'>
            <div className='h-[50vh] my-20 bg-slate-50' >
                <DataGrid 
                    rows={row}
                    columns={column}
                    getRowId={(row) => row.id}
                />
            </div>
        </div>
    </div>
  )
}

export default ManageLookups


