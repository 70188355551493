import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import MenuIcon from "../Icons/Menu.svg";
import EmployeeDataIcon from "../Icons/EmployeeData.svg";
import ManageLookUpsIcon from "../Icons/ManageLookUps.svg";
import EiLogo from "../Icons/EiLogo.svg"
import ProfileIcon from "../Icons/profile.svg";
import LoginEmail from "./loginEmail";
import DashboardIcon from  "../Icons/dashboardIcon.svg";
import { getToken } from "../services/Token";
import LeaveBalanceIcon from "../Icons/leave-balance.svg";
import ApplyLeaveIcon from "../Icons/leave-apply.svg";
import Tooltip from '@mui/material/Tooltip';
import LeavePolicy from "../Icons/leave-policy.svg"
import reviewIcon from "../Icons/review-file.svg"
import editIcon from "../Icons/edit-content.svg"
import letterAdmin from "../Icons/Letter-Admin.svg"
import letterEmployee from "../Icons/Letters-Employee.svg"
import trainingIcon from "../Icons/TrainingIcon.svg"
import tenantIcon from "../Icons/Tenants.svg"
import calendar from "../Icons/Calendar.svg"
import generateLetters from "../Icons/generate-Letters.svg"
import workLog from "../Icons/workLog.svg" 
import project from "../Icons/Projects.svg"
import payroll from "../Icons/payroll.svg"
import employeeLogin from "../Icons/employeeLogin.svg"
import attendanceIcon from "../Icons/attendance-icon.svg"
import { useDispatch, useSelector } from "react-redux";
import { setAdditionalFields,setNavigation,setSelectType } from "../store/reducers/payrollSlice";
import paySlipIcon from "../Icons/payslipIcon.svg"
import timesheet from '../Icons/Timesheet.svg';


const  Layout = ({ children }) => {
  const [showSidebar, setShowSidebar] = useState(true);
  const location = useLocation();
const dispatch = useDispatch();
    const main = <main className="mt-14" style={{
    width: showSidebar ? "calc(100vw - 320px)" : "100vw",
  }}>{children}</main>;
  const role = getToken("roles");
  const employeeName=getToken("employeeName")
  const isEmployee = role ? role.includes("EMPLOYEE") : false;  
  const isManager = role ? role.includes("MANAGER") : false;  
  const isAdmin = role ? role.includes("ADMIN"):false;
  const isSuperUser = role ? role.includes("SUPERADMIN"):false;
  const logoUrl = useSelector((state)=>state.calendar.logoUrl);
  const navigation = [
    {
      name: "Dashboard",
      icon: DashboardIcon,
      href: "/dashboard",
      current: location.pathname === "/dashboard",
      isVisible: true,
    },
    {
      name: "Profile",
      icon: ProfileIcon,
      href: "/editprofile",
      current: location.pathname === "/editprofile",
      isVisible: false,
    },
    {
      name: "Employee Data",
      icon: EmployeeDataIcon,
      href: "/manage",
      current: location.pathname === "/manage",
      isVisible: isAdmin,
    },
    {
      name: "Manage LookUps",
      icon: ManageLookUpsIcon,
      href: "/managelookups",
      current: location.pathname === "/managelookups",
      isVisible: isAdmin,
    },
    {
      name: "Holiday Calendar",
      icon: calendar,
      href: "/holidayCalendar",
      current: location.pathname === "/holidayCalendar",
      isVisible: isAdmin,
    },
    {
      name: "Payroll",
      icon: payroll,
      href: "/payroll",
      current: location.pathname === "/payroll",
      isVisible: isAdmin,
    },
    {
      name: "Leave Balance",
      icon: LeaveBalanceIcon,
      href: "/leavebalance",
      current: location.pathname === "/leavebalance",
      isVisible: isEmployee || isManager,
    },
    {
      name: "Apply Leave",
      icon: ApplyLeaveIcon,
      href: "/leaves",
      current: location.pathname === "/leaves",
      isVisible: isEmployee || isManager,
    },
    {
      name: "Leave Policy",
      icon: LeavePolicy,
      href: "/leavepolicy",
      current: location.pathname === "/leavepolicy",
      isVisible: isAdmin,
    },
    {
      name: "Review",
      icon: reviewIcon,
      href: "/review",
      current: location.pathname === "/review",
      isVisible: isAdmin || isManager,
    },
    {
      name: "Edit Employee",
      icon: editIcon,
      href: "/edit",
      current: location.pathname === "/edit",
      isVisible: isAdmin,
    },
    {
      name: "Create Letter",
      icon: letterEmployee,
      href: "/lettertemplate",
      current: location.pathname === "/lettertemplate",
      isVisible: isAdmin,
    },
    {
      name: "Generate Letters",
      icon: generateLetters,
      href: "/generate",
      current: location.pathname === "/generate",
      isVisible: isAdmin,
    },
    {
      name: "Letters",
      icon: letterAdmin,
      href: "/letters",
      current: location.pathname === "/letters",
      isVisible: isAdmin,
    },
    {
      name: "Letters",
      icon: letterEmployee,
      href: "/employeeletters",
      current: location.pathname === "/employeeletters",
      isVisible: isEmployee || isManager,
    },
    {
      name: "Time Sheet",
      icon: workLog,
      href: "/workLog",
      current: location.pathname === "/workLog",
      isVisible: isEmployee || isManager,
    },
    {
      name: "Employee TimeSheets",
      icon: timesheet,
      href: "/worklogadmin",
      current: location.pathname === "/worklogadmin",
      isVisible: isAdmin || isManager,
    },
    {
      name: "Assign Projects",
      icon: project,
      href: "/assignprojects",
      current: location.pathname === "/assignprojects",
      isVisible: isManager || isAdmin,
    },
    {
      name: "Training Modules",
      icon: trainingIcon,
      href: "/trainingModules",
      current: location.pathname === "/trainingModules",
      isVisible: true,
    },
    {
      name: "Tenants",
      icon: tenantIcon,
      href: "/superadmin",
      current: location.pathname === "/superadmin",
      isVisible: isSuperUser,
    },
    {
      name: "PaySlip",
      icon: paySlipIcon,
      href: "/payslip",
      current: location.pathname === "/payslip",
      isVisible: isEmployee || isManager,
    },
    {
      name: "Attendance Info",
      icon: attendanceIcon,
      href: "/attendance",
      current: location.pathname === "/attendance",
      isVisible: isEmployee,
    },
    {
      name: "Attendance Info",
      icon: attendanceIcon,
      href: "/attendanceinfo",
      current: location.pathname === "/attendanceinfo",
      isVisible: isManager || isAdmin,
    },
    {
      name: "Employee Login's",
      icon: employeeLogin,
      href: '/employeetracking',
      current: location.pathname === "/employeetracking",
      isVisible: isAdmin
    }
  ];

  const navigate = useNavigate();

  const handleSidebarToggle = () => {
    setShowSidebar(!showSidebar);
  };

  const handleClick = (item) => {
    if (item.isVisible) {
      navigate(item.href);
    }
dispatch(setAdditionalFields(""));
dispatch(setSelectType(""))
dispatch(setNavigation(false));
  };


  const topbar = (
    <nav
      id="navbar"
      className={'z-[2] h-20 fixed top-0 flex bg-white px-4 items-center flex-row justify-between border-b border-gray-300 py-4'}
      style={{
        width: showSidebar ? "calc(100vw - 320px)" : "100vw",
      }}
    >
      <div className="flex flex-row items-center justify-between">
        {!showSidebar && (
          <button
            id="btnSidebarToggler"
            type="button"
            className="text-2xl text-black ml-[5px]"
            onClick={handleSidebarToggle}
          >
            <div className="h-10 w-10 flex items-center justify-center rounded-full bg-[#F8F9FA]">
              <img src={MenuIcon} alt="Menu" />
            </div>
          </button>
        )}
        <div className="cursor-pointer relative mr-4">
          {navigation.map(
            (item, index) =>
              item.current && (
                <div
                  key={index+1}
                  className="text-20 font-medium font-Avenir text-[#3C424C]"
                >
                  <div className="ml-2 text-2xl font-semibold">
                    {" "}
                    {item.name}
                  </div>
                </div>
              )
          )}
        </div>
      </div>

      <section className="flex">

        <Tooltip
          title="Help Document"
          className="my-auto cursor-pointer"
          onClick={() => {
            isAdmin
              ? window.open("https://ei-help-document-admin.web.app/", "_blank", "noopener")
              : window.open("https://ei-help-document.web.app/", "_blank", "noopener");
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9 5.25h.008v.008H12v-.008z"
            />
          </svg>
        </Tooltip>
        <div className="text-20 font-medium font-Avenir text-[#3C424C] ml-2 mr-[5rem]">
          <LoginEmail isAdmin={isAdmin} /> 
        </div>
      </section>
    </nav>
  );

  const sidebar = (
    <div id="containerSidebar" className="z-[999]">
      <div className="navbar-menu relative z-[999]">
        <nav
          id="sidebar"
          className={`fixed h-full flex w-3/4 flex-col top-0 overflow-x-hidden overflow-y-auto bg-white pb-2 sm:max-w-xs lg:w-80 border-r border-gray-200 ${
            showSidebar ? "translate-x-0" : "-translate-x-full"
          }`}
        >
          <div>
            <div className="flex flex-row items-center">
              <button
                type="button"
                className="text-xl text-white mr-2"
                onClick={handleSidebarToggle}
              >
                <div className="h-10 w-10 flex items-center justify-center rounded-full mt-[20px] ml-[50px]">
                <img className="mr-[0.75rem]" src={MenuIcon} alt="Menu" />
                <img className="ml-2" src={EiLogo} alt="ICON"/>
                <div className="text-center mb-[-2rem] mt-[-2rem] ml-2">
                
              </div>
                </div>
              </button>
              <h2 className="text-2xl font-serif text-purple-800 mt-3 ml-3 cursor-default">
                  <span className="font-extrabold">work</span><span className="font-extrabold">force</span><span className="text-3xl font-extrabold text-blue-600">HR</span>
              </h2>
            </div>
          </div>
          <div className="flex items-center p-5">
            <div className="">
             {logoUrl ? <img  className = "w-10 h-10 " src={logoUrl} alt="logo"/>:<FontAwesomeIcon icon={faUser} className="w-10 h-10 " />} 
            </div>
            <div className="ml-5">
              <Tooltip title={employeeName}>
                <p className="text-[1rem] font-medium">
                  {employeeName ? employeeName.split("(")[0] : <p className="text-sm">No data available</p>}
                </p>
              </Tooltip>

              {!isAdmin ? (
                <button
                  className="text-[#3944BC] cursor-pointer"
                  onClick={() => {
                    navigate("/profile");
                  }}
                >
                  View My Info
                </button>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="mt-5">
            <ul  className="flex flex-1 flex-col gap-y-7">
              <li>
                <ul className="-mx-2 space-y-1">
                  {navigation.map(
                    (item, index) =>
                      item.isVisible && (
                        <button
                          className={`cursor-pointer ml-2 px-5 py-3 hover:bg-gray-200 text-[14px] font-medium	 text-gray-400 flex flex-row w-full ${
                            item.current
                              ? "bg-blue-400 bg-opacity-20 text-[#3944BC] w-full"
                              : ""
                          }`}
                          key={index+1}
                          onClick={() => handleClick(item)}
                        >
                          <img
                            src={item.icon}
                            alt={item.name}
                            className="mr-4 text-[#3944BC] h-7 w-7"
                          />
                          <span className="ml-2">{item.name}</span>
                        </button>
                      )
                  )}
                </ul>
              </li>
            </ul>
          </div>
        </nav>

      </div>
      <div className="mx-auto lg:ml-80"></div>
    </div>
  );

  return (
      <div className="flex flex-row bg-[#f8f9fd] overflow-hidden min-h-screen ">
        {showSidebar ? sidebar : null}
        <div className="w-full ">
          {topbar}
          {main}
        </div>
      </div>
  );
}

export default Layout;