import React from "react";
import TextField from "../../Components/customComponents/customTextField";
import { useSelector, useDispatch } from "react-redux";
import { updateEmployeeDetails,setIsUploadDocumentsDialogOpen,deleteOtherDocsFiles } from "../../store/reducers/addEmployeeSlice";
import DocumentUploadDialog from "./DocumentUploadDialog";

const OtherDocs = () => {
  const empState = useSelector((state) => state.addEmployee.employeeDetails);
  const feildDisabled = useSelector((state) => state.addEmployee.feildsDisabled);
  const dispatch = useDispatch();
  const handleChange = (e) => {
    if (
      e.target.name == "employeeDetails.personalDetails.dob" ||
      e.target.name == "employeeDetails.professionalDetails.dateofJoining" ||
      e.target.name == "employeeDetails.professionalDetails.confirmationDate" ||
      e.target.name == "employeeDetails.academicDetails.fromDate" ||
      e.target.name == "employeeDetails.academicDetails.toDate"
    ) {
      dispatch(
        updateEmployeeDetails({
          value: new Date(e.target.value).toISOString(),
          path: e.target.name,
        })
      );
    } else if (
      e.target.name == "employeeDetails.personalDetails.aadharNumber"
    ) {
      if (/^\d+$/.test(e.target.value) && e.target.value.length < 13) {
        dispatch(
          updateEmployeeDetails({ value: e.target.value, path: e.target.name })
        );
      }
      if (e.target.value.length == 0) {
        dispatch(
          updateEmployeeDetails({ value: e.target.value, path: e.target.name })
        );
      }
    } else {
      dispatch(
        updateEmployeeDetails({ value: e.target.value, path: e.target.name })
      );
    }
  };

  return (
    <>
      <h1 className="text-2xl mb-10">{+empState.professionalDetails.totalExperience !== "" &&
          +empState.professionalDetails.totalExperience !== 0 ? "Step 6: Other Docs":"Step 5: Other Docs"}</h1>
      <div className="flex flex-col justify-center items-center w-[90%] m-auto">
        {feildDisabled ? (
          <></>
        ) : (
          <div
            className="bg-blue-100 border border-blue-400 text-blue-700 px-4 py-3 rounded relative mb-5 cursor-pointer"
            role="alert"
            onClick={() => {
              dispatch(setIsUploadDocumentsDialogOpen(true));
            }}
          >
            <strong className="font-bold text-2xl">
              Upload Documents Here
            </strong>
            <p className="font-bold text-2xl text-center">+</p>
          </div>
        )}
        <div className="flex flex-wrap">
          {empState.documents.map((item, index) => {
            return (
              <>
                <div class="bg-blue-50 rounded-lg p-2 shadow-md w-52 mr-3 mb-3">
                  {feildDisabled ? (
                    <></>
                  ) : (
                    <div
                      class="flex justify-end mb-2"
                      onClick={() => {
                        dispatch(deleteOtherDocsFiles(index));
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-3 w-3 text-red-500 cursor-pointer"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </div>
                  )}
                  <div
                    class="text-blue-800 font-semibold text-md  text-lg	 mb-1"
                    title="Document Name That Is Very Long and Doesn't Fit in the Card"
                  >
                    {item.name}
                  </div>
                  <div class="text-blue-400 font-semibold text-base	">
                    File Name:
                  </div>
                  <div class="text-blue-300 text-base	">{item.fileName}</div>
                  <div class="text-blue-400 font-semibold mt-1 text-base	">
                    Uploaded Date:
                  </div>
                  <div class="text-blue-300 text-base	">
                    {new Date(item.uploadDate).toLocaleDateString(undefined, {
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    })}
                  </div>
                </div>
              </>
            );
          })}
          {feildDisabled && empState.documents == 0 ? <p>No Documents</p> : <></>}
        </div>
        <DocumentUploadDialog fromComponent="otherDocs"/>
      </div>
    </>
  );
};

export default OtherDocs;
