// Need to use the React-specific entry point to import createApi
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getToken } from "./Token";
import {serviceUrl} from '../utils/apiConfig';

// Define a service using a base URL and expected endpoints
const api = createApi({
  //   reducerPath: "counter",
  baseQuery: fetchBaseQuery({
    baseUrl: serviceUrl,
    prepareHeaders: (headers, { getState, endpoint }) => {
      headers.set("Accept", "application/json");
      headers.set("Content-Type", "application/json");
      const token = getToken('Token');
      if(token){
        headers.set("Authorization" , `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: () => ({}),
});

export default api;



