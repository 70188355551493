import { createSlice } from "@reduxjs/toolkit";

const initialState = {
     calendar : {
        "get_id": "",
        "isActive": true,
        "calenderName": "",
        "yearOfCalender": "",//date
        "additionalProps": {
          "additionalProp1": "",
          "additionalProp2": "",
          "additionalProp3": ""
        },
        "holidays": []
      },
      errorCheck : false,
      logoUrl:""
};

const calendarSlice = createSlice({
  name: "calendar",
  initialState,
  reducers: {
    updateCalendar: (state, action) => {
      const { path, value } = action.payload;
      state.calendar[path] = value;
    },
    clearCalendar: (state,action) => {
       state.calendar = {
        "get_id": "",
        "isActive": true,
        "calenderName": "",
        "yearOfCalender": "",//date
        "additionalProps": {
          "additionalProp1": "",
          "additionalProp2": "",
          "additionalProp3": ""
        },
        "holidays": []
      }
    },
    setLogoUrl:(state,action)=>{
      state.logoUrl = action.payload
    },
    updateHoliday: (state, action) => {
      let holidays = state.calendar.holidays;
      holidays.push(action.payload);
      state.calendar.holidays = holidays;
    },
    removeHoliday: (state, action) => {
      let holidays = state.calendar.holidays;
      holidays.splice(action.payload,1);
      state.calendar.holidays = holidays;
    },
    updateErrorCheck : (state,action) => {
      state.errorCheck = action.payload;
    }
  }
})

export const {updateCalendar,clearCalendar,updateHoliday,removeHoliday,updateErrorCheck,setLogoUrl} = calendarSlice.actions;

export default calendarSlice.reducer;
