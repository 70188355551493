const MonthDisplay = ({ monthNumber }) => {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
  
    // Validate monthNumber
    if (monthNumber < 1 || monthNumber > 12) {
      return <p className="text-balck">Invalid month number</p>;
    }
  
    const monthIndex = monthNumber - 1;
    const monthName = monthNames[monthIndex];
  
    return (
      <div>
        <p> {monthName}</p>
      </div>
    );
  };
  export default MonthDisplay