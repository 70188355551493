import React, { useState,useEffect } from "react";
import { useCreateProfileQuery } from "../api/profileApi";
import Backgroundcheck from "./Employee/Backgroundcheck";
import Education from "./Employee/education";
import PersonalDetails from "./Employee/PersonalDetails";
import EmployeeInformation from "./Employee/EmployeeInformation";
import EditSingleEmployee from "./currentPosition";
import JoiningDetails from "./Employee/joiningDetails";
import PresentAddress from "./Employee/presentaddress";
import PermanentAddress from "./Employee/permenantAddress";
import EmergencyContact from "./Employee/EmergencyContact";
import { getToken } from "../services/Token";
import { cloneDeep } from "lodash";
import axios from "axios";
import { serviceUrl,genericHeaders } from "../utils/apiConfig";
import { Snackbar,Alert } from "@mui/material";
import PhoneInput from "react-phone-input-2";

const VerticalNav = () => {

    const Id = getToken("Id");
  const { data, isSuccess } = useCreateProfileQuery({
    employeeId: `${Id}`,
  });

  const listItems = [
    "Personal",
    "Joining Details",
    "Current position",
    "Education Details",
    "Address",
    "Emergency Contact",
    "Background",
  ];

  const [selectedItem, setSelectedItem] = useState(0);
  const [employee,setEmployee] = useState();
  const [intialEmployeeData,setIntialEmployeeData] = useState();
  const [snack, setSnack] = useState({
    open: false,
    error: false,
    message: "",
  });
  const [edit,setEdit] = useState({
    personalInformation : false,
    educationInformation : false,
    presentAddress : false,
    permanentAddress : false,
    emergencyContact : false,
  });

  const handleItemClick = (index) => {
    setSelectedItem(index);
  };

  const handleSnackOpen = (open,error,message) => {
    let snackData = {...snack};
    snackData.open = open;
    snackData.error = error;
    snackData.message = message;
    setSnack(snackData);
  };
  const handleSnackClose = () => {
    let snackData = {...snack};
    snackData.open = false;
    snackData.error = false;
    snackData.message = "";
    setSnack(snackData);
  };

  const handleSave = async () => {
    console.log("first")
    let payload = {...employee}
    try{
      let response = await axios.put(`${serviceUrl}/api/employee/updateEmployee?employeeId=${Id}`,payload,{headers : genericHeaders()})
      if(response.status === 200){
        handleSnackOpen(true,false,"Updated Successfully");
        setEdit({
          employeeInfo : false,
          personalInfo : false,
          joiningInfo : false,
          currentPosition : false,
          workExperience : false,
          educationInfo : false,
          bankDetails : false,
          presentAddress : false,
          permanentAddress : false,
          emergencyContact : false,
          backgroundCheck : false,
          otherDocs : false
        })
        setIntialEmployeeData(payload);
      }else{
        handleSnackOpen(true,true,"Something Went Wrong");
      }
    }catch(err){
      handleSnackOpen(true,true,"Something Went Wrong");
      console.error(err);
    }
  }

  useEffect( () =>  {
    if(isSuccess){
      let empData = cloneDeep(data);
      setEmployee(empData);
      setIntialEmployeeData(empData);
    }
  },[data])


  const renderComponent = () => {
    switch (selectedItem) {
      case 0:
        return (
          <>
          <div className="flex flex-col w-full">
            <EmployeeInformation />
            <div className="mt-8">
              <PersonalDetails 
                  data={employee}
                  setEmployee={setEmployee}
                  edit={edit}
                  setEdit={setEdit}
                  save={handleSave}
                  oldData={intialEmployeeData} 
              />
            </div>
            </div>
          </>
        );
      case 1:
        return <JoiningDetails />;
      case 2:
        return <EditSingleEmployee />;
      case 3:
        return <Education 
                  data={employee}
                  setEmployee={setEmployee}
                  edit={edit}
                  setEdit={setEdit}
                  save={() => handleSave()}
                  oldData={intialEmployeeData} 
                />;
      case 4:
        return (
          <>
            <div className="flex flex-col w-full mb-10">
              <PresentAddress 
                data={employee}
                setEmployee={setEmployee}
                edit={edit}
                setEdit={setEdit}
                save={() => handleSave()}
                oldData={intialEmployeeData} 
              />
            <div className="mt-8">
              <PermanentAddress 
                data={employee}
                setEmployee={setEmployee}
                edit={edit}
                setEdit={setEdit}
                save={() => handleSave()}
                oldData={intialEmployeeData}
              />
            </div>
            </div>
          </>
        );
      case 5:
        return (
          <EmergencyContact 
            data={employee}
            setEmployee={setEmployee}
            edit={edit}
            setEdit={setEdit}
            save={() => handleSave()}
            oldData={intialEmployeeData}
          />)
      case 6:
        return (<Backgroundcheck />)

      default:
        return null; // Render nothing if no item is selected
    }
  };

  return (
    <div className="flex  flex-row mt-32 ml-20">
      <div className="flex text-lg text-gray-500 font-semibold">
        <ul className="space-y-3">
          {listItems.map((item, index) => (
            <li key={index} >
              <span
                className={`block w-full hover:text-blue-500  cursor-pointer px-5 ${
                  selectedItem === index ? "border-l-2 border-blue-500 text-2xl text-blue-500" : ""
                }`}
                onClick={() => handleItemClick(index)}
              >
                {item}
              </span>
            </li>
          ))}
        </ul>
      </div>

      <div className="flex w-3/4">{renderComponent()}</div>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        open={snack.open}
        autoHideDuration={2000}
        onClose={handleSnackClose}
      >
        <Alert
          severity={snack.error ? "error" : "success"}
          variant="filled"
          onClose={handleSnackClose}
        >
          {snack.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default VerticalNav;

export const CustomPhoneInput = ({
  name,
  value,
  onPhoneInputChange,
  customPlaceholder,
}) => {
  return (
    <PhoneInput
      inputClass="border border-gray-300 rounded-2 p-2 focus:outline-none focus:border-blue-500 "
      country={"in"}
      value={value}
      onChange={(value) => onPhoneInputChange(value, name)}
      placeholder={customPlaceholder}
      containerStyle={{width:"145%",marginTop:".5rem"}}
      inputStyle={{height:35}}
      dropdownStyle={{width:"15rem"}}
    />
  );
}
