import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import { toast } from "react-toastify";
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useSelector, useDispatch } from "react-redux";
import { updateEmployeeDetails,setIsUploadDocumentsDialogOpen,setUploadFileObj,updateEmployeeDocs } from "../../store/reducers/addEmployeeSlice";
import TextField from '../../Components/customComponents/customTextField';
import { upload_file_to_s3 } from '../../utils';

export default function UploadDocs({fromComponent}) {
    const uploadDocState = useSelector((state) => state.addEmployee.isUploadDocumentDialogOpen);
    const [fileObj,setFileObj] = React.useState({
        "fileName": "",
        "name": "",
        "resourceName": "",
        "uploadDate": "",
        "comment": "",
        "extension": "",
        "s3Document": "",
      });
      const [file,setFile]=React.useState("")
      const [loading,setLoading]=React.useState(false);
    const dispatch = useDispatch();

  const handleClickOpen = () => {
    dispatch(setIsUploadDocumentsDialogOpen(true));
  };

  const handleClose = () => {
    resetTheState();
    dispatch(setIsUploadDocumentsDialogOpen(false));

  };

  const resetTheState=()=>{
    setFileObj({
      "fileName": "",
      "name": "",
      "resourceName": "",
      "uploadDate": "",
      "comment": "",
      "extension": "",
      "s3Document": "",
    })
    setFile("");
    setLoading(false);
  }

  const handleChange = (e) =>{
    if(e.target.name=="name"){
        setFileObj({...fileObj,name:e.target.value});
    }
    else{
        setFile(e.target.files[0]);
    }
   
  }

  const handleUpload = async () => {
    setLoading(true);
    if(!fileObj.name){
      toast.error("please enter a file name");
      setLoading(false);
      return
    }
    if(!file){
      toast.error("please select a file");
      setLoading(false);
      return
    }
    
    const file_to_upload = file;
    let awsS3uploadedDTO;
    let document;
    const maxSizeInBytes = 10 * 1024 * 1024; // max 10mb

    if (file_to_upload?.size > maxSizeInBytes) {
      toast.error("File size limit is 10Mb");
      setLoading(false);
      return
    }
    
    try {
      const s3path = await upload_file_to_s3(file);
      awsS3uploadedDTO=s3path?.data?.data;
      setLoading(false);
      let fileData = {
        ...fileObj,
        fileName: file_to_upload.name, // You can set the name as needed
        resourceName: "", // You can set the file url as needed
        uploadDate: new Date().toISOString(),
        comment: "",
        extension: file_to_upload.name.split(".").pop(), // Extract file extension
        s3Document: awsS3uploadedDTO,
      };
      dispatch(setUploadFileObj(fileData));
      dispatch(updateEmployeeDocs({ value: fileData, component: fromComponent }));
      resetTheState();
      dispatch(setIsUploadDocumentsDialogOpen(false));
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
    return document;
  };
  return (
    <div>
      
      <Dialog
        open={uploadDocState}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Upload documents "}
        </DialogTitle>
        <DialogContent>
        <TextField
            name="name"
            label="File Name"
            type="text"

            //value={empState.name}
            onChange={handleChange}
            isMandatory={true}
          />
          <TextField
            name="file"
            label="Upload file"
            type="file"
           // value={empState.name}
             onChange={handleChange}
            isMandatory={true}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>CANCEL</Button>
          <Button onClick={handleUpload} autoFocus disabled={loading}>
            {loading && <CircularProgress size={15}/>}{" "} SAVE
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
