import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import Typography from '@mui/material/Typography';

const PayrollLoader = ({ text }) => {
  return (
    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={true}
    >
      <div style={{ textAlign: 'center' }}>
        <CircularProgress color="inherit" size={60} />
        {text && (
          <Typography variant="subtitle1" color="inherit" mt={2}>
            {text}
          </Typography>
        )}
      </div>
    </Backdrop>
  );
};

export default PayrollLoader;
