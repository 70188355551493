import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  errorMessage : false
};
const errorMessageSlice = createSlice({
  name: "errorMessage",
  initialState,
  reducers: {
    updateErrorMessage: (state, action) => {
      state.errorMessage = action.payload;
    }
  },
});

export const {
updateErrorMessage
} = errorMessageSlice.actions;

export default errorMessageSlice.reducer;
