import React, { useEffect, useState } from "react";
import { TextField } from "@mui/material";
import Button from "@mui/material/Button";
import "../css/Button.css";
import { useCreateProfileQuery } from "../api/profileApi";
import { getToken } from "../services/Token";
import { useCreateEmployeeUpdateMutation } from "../api/employeeUpdateApi";
import { genericHeaders, serviceUrl } from "../utils/apiConfig";
import axios from "axios";

function Profile() {
  const Id = getToken("Id");
  const { data, isSuccess } = useCreateProfileQuery({
    employeeId: `${Id}`,
  });
  const [createEmployeeUpdate] = useCreateEmployeeUpdateMutation();

  const workLocationId = data && data.workLocation ? data.workLocation : "";
  const getLocationData = async () => {
    try {
      let response = await axios.get(
        `${serviceUrl}/api/workLocation/getWorkLocation?workLocationId=${workLocationId}`,
        { headers: genericHeaders() }
      );
      if (response.status === 200) {
        const LocationData = response.data;
        const LocationName = LocationData.location;
        setFormValues((prevFormValues) => ({
          ...prevFormValues,
          workLocation: LocationName,
        }));
      }
    } catch (err) {
      console.error(err);
    }
  };
  const departmentId = data && data.department ? data.department : "";
  const getDepartmentData = async () => {
    try {
      const response = await axios.get(
        `${serviceUrl}/api/department/getRecord?departmentId=${departmentId}`,
        {
          headers: genericHeaders(),
        }
      );

      if (response.status === 200) {
        const departmentData = response.data;
        const departmentName = departmentData.departmentName;
        setFormValues((prevFormValues) => ({
          ...prevFormValues,
          department: departmentName,
        }));
      }
    } catch (err) {
      console.error(err);
    }
  };
  const designationId =
    data && data.currentDesignation ? data.currentDesignation : "";
  const getDesignationData = async () => {
    try {
      let response = await axios.get(
        `${serviceUrl}/api/designation/getDesignation?designationId=${designationId}`,
        { headers: genericHeaders() }
      );
      if (response.status === 200) {
        const designationData = response.data;
        const designationName = designationData.designation;
        setFormValues((prevFormValues) => ({
          ...prevFormValues,
          currentDesignation: designationName,
        }));
      }
    } catch (err) {
      console.error(err);
    }
  };
  const holidayCalenderId =
    data && data.holidayCalenderName ? data.holidayCalenderName : "";
  const getCalenderData = async () => {
    try {
      let response = await axios.get(
        `${serviceUrl}/api/holidayCalender/getHolidayCalender?holidayCalenderId=${holidayCalenderId}`,
        { headers: genericHeaders() }
      );
      if (response.status === 200) {
        const calenderData = response.data;
        const calenderName = calenderData.calenderName;
        setFormValues((prevFormValues) => ({
          ...prevFormValues,
          holidayCalenderName: calenderName,
        }));
      }
    } catch (err) {
      console.error(err);
    }
  };
  const employeeCategoryId = data && data.employeeType ? data.employeeType : "";
  const getemployeeTypeData = async () => {
    try {
      let response = await axios.get(
        `${serviceUrl}/api/employeeType/getEmployeeCategory?employeeCategoryId=${employeeCategoryId}`,
        { headers: genericHeaders() }
      );
      if (response.status === 200) {
        const employeeTypeData = response.data;
        const employeeName = employeeTypeData.employeeCategory;
        setFormValues((prevFormValues) => ({
          ...prevFormValues,
          employeeType: employeeName,
        }));
      }
    } catch (err) {
      console.error(err);
    }
  };

  const [formValues, setFormValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    workLocation: "",
    currentDesignation: "",
    department: "",
    primaryContactNo: "",
    employeeId: "",
    gender: "",
    dob: "",
    emergencyContactNo: "",
    maritalStatus: "",
    nationality: "",
    address: "",
    city: "",
    state: "",
    country: "",
    zipCode: "",
    holidayCalenderName: "",
    employeeType: "",
    companyName: "",
    designation: "",
    endDate: "",
    startDate: "",
    schoolName: "",
    result: "",
    fromDate: "",
    toDate: "",
    bankName: "",
    branchName: "",
    accountNo: "",
    ifsccode: "",
    managerIds: "",
  });
  const reportManagers = data?.managersMap || {};
  const managerNames = Object.keys(reportManagers);
  const managerId =
    data && data.managersMap[managerNames]
      ? data.managersMap[managerNames]
      : "";
  useEffect(() => {
    if (isSuccess) {
      //  const workLocation = data.workLocation;
      //const department = data.department;
      const personalDetails = data.personalDetails;
      //  const employeeType = data.employeeType;
      // const calenderType = data.holidayCalenderName;
      const workExperience = data.workExperience[0];
      const academicDetails = data.academicDetails;
      const banckDetails = data.bankDetails[0];
      const {
        firstName,
        lastName,
        email,
        primaryContactNo,
        gender,
        dob,
        emergencyContactNo,
        maritalStatus,
        nationality,
        address,
        city,
        state,
        country,
        zipCode,
      } = personalDetails;
      // const designationPersonal = data.currentDesignation;
      const employeeId = data.employeeId;
      const { companyName, designation, endDate, startDate } = workExperience;
      const { schoolName, result, fromDate, toDate } = academicDetails;
      const { bankName, branchName, accountNo, ifsccode } = banckDetails;
      const reportManager = data.managersMap;
      const managerName = Object.keys(reportManager);

      setFormValues({
        firstName: firstName,
        lastName: lastName,
        email: email,
        primaryContactNo: primaryContactNo,
        //workLocation: workLocation,
        // department: department,
        // currentDesignation: designationPersonal,
        employeeId: employeeId,
        gender: gender,
        dob: dob,
        emergencyContactNo: emergencyContactNo,
        // employeeType: employeeType,
        // holidayCalenderName: calenderType,
        maritalStatus: maritalStatus,
        nationality: nationality,
        address: address,
        city: city,
        state: state,
        country: country,
        zipCode: zipCode,
        companyName: companyName,
        designation: designation,
        endDate: endDate,
        startDate: startDate,
        schoolName: schoolName,
        result: result,
        fromDate: fromDate,
        toDate: toDate,
        bankName: bankName,
        branchName: branchName,
        accountNo: accountNo,
        ifsccode: ifsccode,
        managerIds: managerName,
      });
      getemployeeTypeData();
      getCalenderData();
      getDepartmentData();
      getDesignationData();
      getLocationData();
    }
  }, [isSuccess, data]);

  const handleFieldChange = (event) => {
    const { name, value } = event.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
    let data = {
      ...formData,
      personalDetails: {
        ...formData.personalDetails,
        firstName: formValues.firstName,
        lastName: formValues.lastName,
        gender: formValues.gender,
        dob: new Date(formValues.dob).toISOString(),
        maritalStatus: formValues.maritalStatus,
        primaryContactNo: formValues.primaryContactNo,
        emergencyContactNo: formValues.emergencyContactNo,
        email: formValues.email,
        nationality: formValues.nationality,
        address: formValues.address,
        city: formValues.city,
        state: formValues.state,
        country: formValues.country,
        zipCode: formValues.zipCode,
      },
      employeeId: formValues.employeeId,
      managerIds: [managerId],
      department: departmentId,

      currentDesignation: designationId,

      workLocation: workLocationId,

      employeeType: employeeCategoryId,

      holidayCalenderName: holidayCalenderId,
      workExperience: [
        {
          ...formData.workExperience[0],

          companyName: formValues.companyName,

          designation: formValues.designation,

          startDate: new Date(formValues.startDate).toISOString(),
          endDate: new Date(formValues.endDate).toISOString(),
        },
      ],
      academicDetails: {
        ...formData.academicDetails,

        schoolName: formValues.schoolName,

        result: formValues.result,
        fromDate: new Date(formValues.fromDate).toISOString(),
        toDate: new Date(formValues.toDate).toISOString(),
      },
      bankDetails: [
        {
          ...formData.bankDetails[0],
          bankName: formValues.bankName,
          accountNo: formValues.accountNo,
          branchName: formValues.branchName,
          ifsccode: formValues.ifsccode,
        },
      ],
    };
    setFormData(data);
  };

  const [isEditing, setIsEditing] = useState(false);

  const handleEditClick = () => {
    setIsEditing(true);
  };
  const form = {
    get_id: "",
    companyEmail: "",
    employeeId: "",
    workLocation: "",
    isAcceptTermsandConditions: true,
    profilePhoto: {
      fileName: "",
      name: "",
      resourceName: "",
      uploadDate: "2023-10-21T18:39:04.584Z",
      comment: "",
      extension: "",
    },
    department: "",
    currentDesignation: "",
    holidayCalenderUrl: "",
    personalDetails: {
      firstName: "",
      lastName: "",
      gender: "",
      maritalStatus: "",
      primaryContactNo: "",
      emergencyContactNo: "",
      email: "",
      nationality: "",
      address: "",
      city: "",
      state: "",
      country: "",
      zipCode: "",
      additionalInformation: {
        additionalProp1: {},
        additionalProp2: {},
        additionalProp3: {},
      },
      dob: "2023-10-21T18:39:04.584Z",
    },
    academicDetails: {
      schoolName: "",
      result: "",
      fromDate: "2023-10-21T18:39:04.584Z",
      toDate: "2023-10-21T18:39:04.584Z",
      documents: [
        {
          fileName: "",
          name: "",
          resourceName: "",
          uploadDate: "2023-10-21T18:39:04.584Z",
          comment: "",
          extension: "",
        },
      ],
      additionalInfo: {
        additionalProp1: {},
        additionalProp2: {},
        additionalProp3: {},
      },
    },
    workExperience: [
      {
        companyName: "",
        designation: "",
        startDate: "2023-10-21T18:39:04.584Z",
        endDate: "2023-10-21T18:39:04.584Z",
        documents: [
          {
            fileName: "",
            name: "",
            resourceName: "",
            uploadDate: "2023-10-21T18:39:04.584Z",
            comment: "",
            extension: "",
          },
        ],
      },
    ],
    offerLetter: {
      fileName: "",
      name: "",
      resourceName: "",
      uploadDate: "2023-10-21T18:39:04.584Z",
      comment: "",
      extension: "",
    },
    managerIds: [""],
    paySlips: [
      {
        fileName: "",
        name: "",
        resourceName: "",
        uploadDate: "2023-10-21T18:39:04.584Z",
        comment: "",
        extension: "",
      },
    ],
    documents: [
      {
        fileName: "",
        name: "",
        resourceName: "",
        uploadDate: "2023-10-21T18:39:04.584Z",
        comment: "",
        extension: "",
      },
    ],
    bankDetails: [
      {
        bankName: "",
        isActive: true,
        accountNo: "",
        branchName: "",
        ifsccode: "",
      },
    ],
    additionalInfo: {
      additionalProp1: {},
      additionalProp2: {},
      additionalProp3: {},
    },
    employeeType: "",
    holidayCalenderName: "",
    managersMap: {
      additionalProp1: "",
      additionalProp2: "",
      additionalProp3: "",
    },
  };
  const [formData, setFormData] = useState(form);
  const handleSaveClick = async () => {
    let data = {
      ...formData,
      personalDetails: {
        ...formData.personalDetails,
        firstName: formValues.firstName,
        lastName: formValues.lastName,
        gender: formValues.gender,
        dob: new Date(formValues.dob).toISOString(),
        maritalStatus: formValues.maritalStatus,
        primaryContactNo: formValues.primaryContactNo,
        emergencyContactNo: formValues.emergencyContactNo,
        email: formValues.email,
        nationality: formValues.nationality,
        address: formValues.address,
        city: formValues.city,
        state: formValues.state,
        country: formValues.country,
        zipCode: formValues.zipCode,
      },
      employeeId: formValues.employeeId,
      managerIds: [managerId],
      department: departmentId,

      currentDesignation: designationId,

      workLocation: workLocationId,

      employeeType: employeeCategoryId,

      holidayCalenderName: holidayCalenderId,
      workExperience: [
        {
          ...formData.workExperience[0],

          companyName: formValues.companyName,

          designation: formValues.designation,

          startDate: new Date(formValues.startDate).toISOString(),
          endDate: new Date(formValues.endDate).toISOString(),
        },
      ],
      academicDetails: {
        ...formData.academicDetails,

        schoolName: formValues.schoolName,

        result: formValues.result,
        fromDate: new Date(formValues.fromDate).toISOString(),
        toDate: new Date(formValues.toDate).toISOString(),
      },
      bankDetails: [
        {
          ...formData.bankDetails[0],
          bankName: formValues.bankName,
          accountNo: formValues.accountNo,
          branchName: formValues.branchName,
          ifsccode: formValues.ifsccode,
        },
      ],
    };
    setFormData(data);
    createEmployeeUpdate({ id: Id, payload: data });
    setIsEditing(false);
  };

  const handleCancelClick = () => {
    setIsEditing(false);
  };
  return (
    <div className="flex flex-col gap-2 m-[6.5rem]">
      {isEditing ? (
        <div className="flex flex-row self-end">
          <Button
            variant="contained"
            className="w-40 "
            onClick={handleCancelClick}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            className="w-40"
            onClick={handleSaveClick}
          >
            Save
          </Button>
        </div>
      ) : (
        <Button
          variant="contained"
          className="w-40 flex self-end	"
          onClick={handleEditClick}
          style={{ marginLeft: "10px" }}
        >
          Edit
        </Button>
      )}

      <h3 className="text-2xl my-5 font-bold">Account Information</h3>
      <div className="grid grid-cols-2 gap-4 mb-10 bg-white shadow border p-4 rounded-lg border-gray-300">
        <TextField
          label="Employee ID"
          name="employeeId"
          variant="outlined"
          value={formValues.employeeId}
          InputProps={{
            readOnly: true,
          }}
          required
        />
        <TextField
          label="ReportingManager"
          name="managerIds"
          variant="outlined"
          value={formValues.managerIds}
          required
          InputProps={{
            readOnly: true,
          }}
        />
        <TextField
          label="Department"
          name="department"
          variant="outlined"
          value={formValues.department}
          required
          InputProps={{
            readOnly: true,
          }}
        />
        <TextField
          label="Designation"
          name="currentDesignation"
          variant="outlined"
          value={formValues.currentDesignation}
          InputProps={{
            readOnly: true,
          }}
          required
        />
        <TextField
          label="Location"
          name="workLocation"
          variant="outlined"
          value={formValues.workLocation}
          InputProps={{
            readOnly: true,
          }}
          required
        />
        <TextField
          label="Employee Type"
          name="employeeType"
          variant="outlined"
          value={formValues.employeeType}
          InputProps={{
            readOnly: true,
          }}
          required
        />
        <TextField
          label="Calender Type"
          name="holidayCalenderName"
          variant="outlined"
          value={formValues.holidayCalenderName}
          InputProps={{
            readOnly: true,
          }}
          required
        />
      </div>

      <h3 className="text-2xl my-5 font-bold">Personal Information</h3>
      <div className="grid grid-cols-2 gap-4 mb-10 bg-white shadow border p-4 rounded-lg border-gray-300">
        {isEditing ? (
          <TextField
            label="First Name"
            name="firstName"
            variant="outlined"
            value={formValues.firstName}
            onChange={handleFieldChange}
            required
          />
        ) : (
          <TextField
            label="First Name"
            name="firstName"
            variant="outlined"
            value={formValues.firstName}
            InputProps={{
              readOnly: true,
            }}
            required
          />
        )}
        {isEditing ? (
          <TextField
            label="Last Name"
            name="lastName"
            variant="outlined"
            value={formValues.lastName}
            onChange={handleFieldChange}
            required
          />
        ) : (
          <TextField
            label="Last Name"
            name="lastName"
            variant="outlined"
            value={formValues.lastName}
            InputProps={{
              readOnly: true,
            }}
            required
          />
        )}
        {isEditing ? (
          <TextField
            label="Gender"
            name="gender"
            variant="outlined"
            value={formValues.gender}
            onChange={handleFieldChange}
            required
          />
        ) : (
          <TextField
            label="Gender"
            name="gender"
            variant="outlined"
            value={formValues.gender}
            InputProps={{
              readOnly: true,
            }}
            required
          />
        )}
        {isEditing ? (
          <TextField
            label="Date Of Birth"
            name="dob"
            variant="outlined"
            type="date"
            value={
              formValues?.dob && typeof formValues.dob === "string"
                ? formValues.dob.split("T")[0]
                : ""
            }
            InputLabelProps={{ shrink: true }}
            onChange={handleFieldChange}
            required
          />
        ) : (
          <TextField
            label="Date Of Birth"
            name="dob"
            variant="outlined"
            type="date"
            value={
              formValues?.dob && typeof formValues.dob === "string"
                ? formValues.dob.split("T")[0]
                : ""
            }
            InputLabelProps={{ shrink: true }}
            InputProps={{
              readOnly: true,
            }}
            required
          />
        )}
        {isEditing ? (
          <TextField
            label="Phone Number"
            name="primaryContactNo"
            variant="outlined"
            value={formValues.primaryContactNo}
            onChange={handleFieldChange}
            required
          />
        ) : (
          <TextField
            label="Phone Number"
            name="primaryContactNo"
            variant="outlined"
            value={formValues.primaryContactNo}
            InputProps={{
              readOnly: true,
            }}
            required
          />
        )}
        {isEditing ? (
          <TextField
            label="Emergency Contact Number"
            name="emergencyContactNo"
            variant="outlined"
            value={formValues.emergencyContactNo}
            onChange={handleFieldChange}
          />
        ) : (
          <TextField
            label="Emergency Contact Number"
            name="emergencyContactNo"
            variant="outlined"
            value={formValues.emergencyContactNo}
            InputProps={{
              readOnly: true,
            }}
            required
          />
        )}
        {isEditing ? (
          <TextField
            label="Email"
            name="email"
            variant="outlined"
            value={formValues.email}
            onChange={handleFieldChange}
            required
          />
        ) : (
          <TextField
            label="Email"
            name="email"
            variant="outlined"
            value={formValues.email}
            InputProps={{
              readOnly: true,
            }}
            required
          />
        )}
        {isEditing ? (
          <TextField
            label="Marital Status"
            name="maritalStatus"
            variant="outlined"
            value={formValues.maritalStatus}
            onChange={handleFieldChange}
          />
        ) : (
          <TextField
            label="Marital Status"
            name="maritalStatus"
            variant="outlined"
            value={formValues.maritalStatus}
            InputProps={{
              readOnly: true,
            }}
            required
          />
        )}
        {isEditing ? (
          <TextField
            label="Nationality"
            name="nationality"
            variant="outlined"
            value={formValues.nationality}
            onChange={handleFieldChange}
          />
        ) : (
          <TextField
            label="Nationality"
            name="nationality"
            variant="outlined"
            value={formValues.nationality}
            InputProps={{
              readOnly: true,
            }}
            required
          />
        )}
        {isEditing ? (
          <TextField
            label="Address"
            name="address"
            variant="outlined"
            value={formValues.address}
            onChange={handleFieldChange}
          />
        ) : (
          <TextField
            label="Address"
            name="address"
            variant="outlined"
            value={formValues.address}
            InputProps={{
              readOnly: true,
            }}
            required
          />
        )}
        {isEditing ? (
          <TextField
            label="City"
            name="city"
            variant="outlined"
            value={formValues.city}
            onChange={handleFieldChange}
          />
        ) : (
          <TextField
            label="City"
            name="city"
            variant="outlined"
            value={formValues.city}
            InputProps={{
              readOnly: true,
            }}
            required
          />
        )}
        {isEditing ? (
          <TextField
            label="State"
            name="state"
            variant="outlined"
            value={formValues.state}
            onChange={handleFieldChange}
          />
        ) : (
          <TextField
            label="State"
            name="state"
            variant="outlined"
            value={formValues.state}
            InputProps={{
              readOnly: true,
            }}
            required
          />
        )}
        {isEditing ? (
          <TextField
            label="Country"
            name="country"
            variant="outlined"
            value={formValues.country}
            onChange={handleFieldChange}
          />
        ) : (
          <TextField
            label="Country"
            name="country"
            variant="outlined"
            value={formValues.country}
            InputProps={{
              readOnly: true,
            }}
            required
          />
        )}
        {isEditing ? (
          <TextField
            label="Zip Code"
            name="zipCode"
            variant="outlined"
            value={formValues.zipCode}
            onChange={handleFieldChange}
          />
        ) : (
          <TextField
            label="Zip Code"
            name="zipCode"
            variant="outlined"
            value={formValues.zipCode}
            InputProps={{
              readOnly: true,
            }}
            required
          />
        )}
      </div>

      <h3 className="text-2xl my-5 font-bold">Work Experience</h3>
      <div className="grid grid-cols-2 gap-4 mb-10 bg-white shadow border p-4 rounded-lg border-gray-300">
        {isEditing ? (
          <TextField
            label="Company Name"
            name="companyName"
            variant="outlined"
            value={formValues.companyName}
            onChange={handleFieldChange}
          />
        ) : (
          <TextField
            label="Company Name"
            name="companyName"
            variant="outlined"
            value={formValues.companyName}
            InputProps={{
              readOnly: true,
            }}
            required
          />
        )}
        {isEditing ? (
          <TextField
            label="Designation"
            name="designation"
            variant="outlined"
            value={formValues.designation}
            onChange={handleFieldChange}
          />
        ) : (
          <TextField
            label="Designation"
            name="designation"
            variant="outlined"
            value={formValues.designation}
            InputProps={{
              readOnly: true,
            }}
            required
          />
        )}
        {isEditing ? (
          <TextField
            label="Start Date"
            name="startDate"
            variant="outlined"
            value={
              formValues?.startDate && typeof formValues.startDate === "string"
                ? formValues.startDate.split("T")[0]
                : ""
            }
            onChange={handleFieldChange}
            type="date"
            InputLabelProps={{ shrink: true }}
            required
          />
        ) : (
          <TextField
            label="Start Date"
            name="startDate"
            variant="outlined"
            value={
              formValues?.startDate && typeof formValues.startDate === "string"
                ? formValues.startDate.split("T")[0]
                : ""
            }
            type="date"
            InputLabelProps={{ shrink: true }}
            InputProps={{
              readOnly: true,
            }}
            required
          />
        )}
        {isEditing ? (
          <TextField
            label="End Date"
            name="endDate"
            variant="outlined"
            value={
              formValues?.endDate && typeof formValues.endDate === "string"
                ? formValues.endDate.split("T")[0]
                : ""
            }
            onChange={handleFieldChange}
            type="date"
            InputLabelProps={{ shrink: true }}
            required
          />
        ) : (
          <TextField
            label="End Date"
            name="endDate"
            variant="outlined"
            value={
              formValues?.endDate && typeof formValues.endDate === "string"
                ? formValues.endDate.split("T")[0]
                : ""
            }
            type="date"
            InputLabelProps={{ shrink: true }}
            InputProps={{
              readOnly: true,
            }}
            required
          />
        )}
      </div>

      <h3 className="text-2xl my-5 font-bold">Academic Details</h3>
      <div className="grid grid-cols-2 gap-4 mb-10  bg-white shadow border p-4 rounded-lg border-gray-300">
        {isEditing ? (
          <TextField
            label="College Name"
            name="schoolName"
            variant="outlined"
            value={formValues.schoolName}
            onChange={handleFieldChange}
          />
        ) : (
          <TextField
            label="College Name"
            name="schoolName"
            variant="outlined"
            value={formValues.schoolName}
            InputProps={{
              readOnly: true,
            }}
            required
          />
        )}
        {isEditing ? (
          <TextField
            label="Result"
            name="result"
            variant="outlined"
            value={formValues.result}
            onChange={handleFieldChange}
          />
        ) : (
          <TextField
            label="Result"
            name="result"
            variant="outlined"
            value={formValues.result}
            InputProps={{
              readOnly: true,
            }}
            required
          />
        )}
        {isEditing ? (
          <TextField
            label="From Date"
            name="fromDate"
            variant="outlined"
            value={
              formValues?.fromDate && typeof formValues.fromDate === "string"
                ? formValues.fromDate.split("T")[0]
                : ""
            }
            onChange={handleFieldChange}
            type="date"
            InputLabelProps={{ shrink: true }}
            required
          />
        ) : (
          <TextField
            label="From Date"
            name="fromDate"
            variant="outlined"
            value={
              formValues?.fromDate && typeof formValues.fromDate === "string"
                ? formValues.fromDate.split("T")[0]
                : ""
            }
            type="date"
            InputLabelProps={{ shrink: true }}
            InputProps={{
              readOnly: true,
            }}
            required
          />
        )}
        {isEditing ? (
          <TextField
            label="To Date"
            name="toDate"
            variant="outlined"
            value={
              formValues?.endDate && typeof formValues.endDate === "string"
                ? formValues.endDate.split("T")[0]
                : ""
            }
            onChange={handleFieldChange}
            type="date"
            InputLabelProps={{ shrink: true }}
            required
          />
        ) : (
          <TextField
            label="To Date"
            name="toDate"
            variant="outlined"
            value={
              formValues?.endDate && typeof formValues.endDate === "string"
                ? formValues.endDate.split("T")[0]
                : ""
            }
            type="date"
            InputLabelProps={{ shrink: true }}
            InputProps={{
              readOnly: true,
            }}
            required
          />
        )}
      </div>

      <h3 className="text-2xl my-5 font-bold">Bank Details</h3>
      <div className="grid grid-cols-2 gap-4 mb-10  bg-white shadow border p-4 rounded-lg border-gray-300">
        {isEditing ? (
          <TextField
            label="Bank Name"
            name="bankName"
            variant="outlined"
            value={formValues.bankName}
            onChange={handleFieldChange}
            required
          />
        ) : (
          <TextField
            label="Bank Name"
            name="bankName"
            variant="outlined"
            value={formValues.bankName}
            InputProps={{
              readOnly: true,
            }}
            required
          />
        )}
        {isEditing ? (
          <TextField
            label="Branch Name"
            name="branchName"
            variant="outlined"
            value={formValues.branchName}
            onChange={handleFieldChange}
            required
          />
        ) : (
          <TextField
            label="Branch Name"
            name="branchName"
            variant="outlined"
            value={formValues.branchName}
            InputProps={{
              readOnly: true,
            }}
            required
          />
        )}
        {isEditing ? (
          <TextField
            label="Account Number"
            name="accountNo"
            variant="outlined"
            value={formValues.accountNo}
            onChange={handleFieldChange}
            required
          />
        ) : (
          <TextField
            label="Account Number"
            name="accountNo"
            variant="outlined"
            value={formValues.accountNo}
            InputProps={{
              readOnly: true,
            }}
            required
          />
        )}
        {isEditing ? (
          <TextField
            label="Account IFSC code"
            name="ifsccode"
            variant="outlined"
            value={formValues.ifsccode}
            onChange={handleFieldChange}
            required
          />
        ) : (
          <TextField
            label="Account IFSC code"
            name="ifsccode"
            variant="outlined"
            value={formValues.ifsccode}
            InputProps={{
              readOnly: true,
            }}
            required
          />
        )}
      </div>
    </div>
  );
}
export default Profile;
