import React, { useEffect, useState, useRef } from "react";
import { cloneDeep } from "lodash";
import { toast } from "react-toastify";
import { upload_file_to_s3 } from "../../utils";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
} from "@mui/material";
import CustomText from "../../Components/customComponents/customTextField";
import { DocumentIcon, PlusIcon } from "@heroicons/react/24/solid";
const DocsEdit = (props) => {
  let formData = cloneDeep(props?.data);
  const { component } = props;
  const [dialogOpen, setDialogOpen] = useState(false);
  const [docName, setDocName] = useState("");
  const [docFormat, setDocFormat] = useState();
  const [documents, setDocuments] = useState();

  const handleOpen = (i) => {
    setDialogOpen(true);
  };
  const handleClose = () => {
    setDialogOpen(false);
    setDocName();
  };
  const handleDocNameChange = (evt) => {
    if (/^[a-zA-Z0-9\s]*$/.test(evt.target.value)) {
      setDocName(evt.target.value);
    }
  };

  const handleDocsChange = async (evt) => {
    const file_to_upload = evt.target.files[0];
    const maxSizeInBytes = 10 * 1024 * 1024; // max 10mb

    if (file_to_upload?.size > maxSizeInBytes) {
      toast.error("File size limit is 10Mb");
      return;
    }

    let s3Data;
    try {
      s3Data = await upload_file_to_s3(file_to_upload);
    } catch (err) {
      toast.error("Failed to upload the file");
      console.error(err);
    }
    let document = {
      fileName: file_to_upload.name,
      name: docName.trim(),
      resourceName: "",
      uploadDate: new Date().toISOString(),
      comment: "",
      extension: file_to_upload.name.split(".").pop(),
      s3Document: s3Data?.data?.data,
    };
    setDocFormat(document);
  };
  const handleSave = () => {
    if (docFormat) {
      let newData = formData;
      if (component === "academic") {
        newData?.academicDetails?.documents?.push(docFormat);
      } else if (component == "bankDetails") {
        newData?.bankDetails?.documents?.push(docFormat);
      }
      props?.setEmployee(newData);
      setDocFormat();
      handleClose();
      setDocName("");
    }
  };
  const handleDocDelete = (docIndex) => {
    let newData = formData;
    if (component === "academic") {
      newData?.academicDetails?.documents?.splice(docIndex, 1);
    } else if (component === "bankDetails") {
      newData?.bankDetails?.documents?.splice(docIndex, 1);
    }

    props?.setEmployee(newData);
  };

  useEffect(() => {
    if (component == "academic") {
      setDocuments(formData?.academicDetails?.documents);
    } else if (component == "bankDetails") {
      setDocuments(formData?.bankDetails?.documents);
    }
  }, [formData]);

  return (
    <>
      <Dialog
        open={dialogOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Upload documents "}</DialogTitle>
        <DialogContent>
          <CustomText
            name="name"
            label="File Name"
            type="text"
            value={docName}
            onChange={handleDocNameChange}
            isMandatory={true}
          />
          <CustomText
            name="file"
            label="Upload file"
            type="file"
            disabled={docName?.trim() === ""}
            // value={empState.name}
            onChange={handleDocsChange}
            // isMandatory={true}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>CANCEL</Button>
          <Button
            onClick={handleSave}
            disabled={
              docName?.trim() === "" ||
              Object.keys(docFormat?.s3Document ?? {}).length === 0
            }
          >
            SAVE
          </Button>
        </DialogActions>
      </Dialog>
      <div className="mx-2">
        <h4>Documents</h4>
        <div className="flex flex-wrap p-2 gap-5">
          {documents?.map((doc, docIndex) => (
            <div
              key={docIndex + 1}
              className="bg-slate-100 rounded-lg p-2 w-56 flex justify-between"
            >
              <div className="flex">
                <div className="text-blue-800 my-auto">
                  <DocumentIcon className="w-7 h-7" />
                </div>
                <div
                  class="text-blue-500 mx-2 w-fit cursor-pointer"
                  onClick={() => props?.handleDownload(doc)}
                >
                  <p className="text-lg">
                    {doc.name.length > 15
                      ? doc.name.substring(0, 15) + "..."
                      : doc.name}
                  </p>
                  <p className="text-xs">
                    {doc.fileName.length > 19
                      ? doc.fileName.substring(0, 19) + "..."
                      : doc.fileName}
                  </p>
                </div>
              </div>
              <div
                className="h-6 w-6 my-auto text-red-500 cursor-pointer"
                onClick={() => handleDocDelete(docIndex)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </div>
            </div>
          ))}
          <div
            className="bg-slate-100 rounded-lg my-auto p-4 text-blue-800 cursor-pointer"
            onClick={() => handleOpen()}
          >
            <PlusIcon className="w-7 h-7" />
          </div>
        </div>
        <p className="text-xs text-red-400">
          Note:Click on the file name to download.
        </p>
      </div>
    </>
  );
};

export default DocsEdit;
