import React, {useState} from "react";
import { cloneDeep } from "lodash";
import EditIcon from "@mui/icons-material/Edit";
import { CustomPhoneInput } from "../EmployeeDetails";

function PermanentAddress(props) {

  // const Id = getToken("Id");
  // const { data, isSuccess } = useCreateProfileQuery({
  //   employeeId: `${Id}`,
  // });

  return (
<div className="w-full">
<div className="w-[95%] bg-white rounded-xl mx-auto p-3">
              <div>
                <h3 className="text-xl my-2 p-2 border-l-2 border-purple-300 font-semibold">
                  Permanent Address
                  <span className={`mx-2 cursor-pointer ${
                      props.edit.permanentAddress ? "hidden" : ""
                    }`}
                    onClick={() =>
                      props.setEdit((prevEdit) => ({
                        ...prevEdit,
                        permanentAddress : true,
                      }))
                    }
                  >
                    {<EditIcon />}
            </span>
                </h3>
                { props.edit.permanentAddress ?
                    <EditPermanentAddress
                        data={props.data}
                        setEmployee={props.setEmployee}
                        edit={props.edit}
                        setEdit={props.setEdit}
                        save={props.save}
                        oldData={props.oldData}
                    /> :
                    <>
                      <div className="grid grid-cols-4 p-2 gap-2">
                        <div>
                          <h4 className="text-slate-600">Name</h4>
                          <p className="my-1 text-lg">
                            {props.data?.permanentAddress?.name
                              ? props.data?.permanentAddress?.name
                              : "-"}
                          </p>
                        </div>
                        <div>
                          <h4 className="text-slate-600">Address</h4>
                          <p className="my-1 text-lg">
                            {props.data?.permanentAddress?.address
                              ? props.data?.permanentAddress?.address
                              : "-"}
                          </p>
                        </div>
                        <div>
                          <h4 className="text-slate-600">City</h4>
                          <p className="my-1 text-lg">
                            {props.data?.permanentAddress?.city
                              ? props.data?.permanentAddress?.city
                              : "-"}
                          </p>
                        </div>
                        <div>
                          <h4 className="text-slate-600">State</h4>
                          <p className="my-1 text-lg">
                            {props.data?.permanentAddress?.state
                              ? props.data?.permanentAddress?.state
                              : "-"}
                          </p>
                        </div>
                        <div>
                          <h4 className="text-slate-600">Country</h4>
                          <p className="my-1 text-lg">
                            {props.data?.permanentAddress?.country
                              ? props.data?.permanentAddress?.country
                              : "-"}
                          </p>
                        </div>
                        <div>
                          <h4 className="text-slate-600">Pincode</h4>
                          <p className="my-1 text-lg">
                            {props.data?.permanentAddress?.pincode
                              ? props.data?.permanentAddress?.pincode
                              : "-"}
                          </p>
                        </div>
                        <div>
                          <h4 className="text-slate-600">Phone</h4>
                          <p className="my-1 text-lg">
                            {props.data?.permanentAddress?.phone
                              ? props.data?.permanentAddress?.phone
                              : "-"}
                          </p>
                        </div>
                        <div>
                          <h4 className="text-slate-600">Fax</h4>
                          <p className="my-1 text-lg">
                            {props.data?.permanentAddress?.fax
                              ? props.data?.permanentAddress?.fax
                              : "-"}
                          </p>
                        </div>
                        <div>
                          <h4 className="text-slate-600">Mobile Number</h4>
                          <p className="my-1 text-lg">
                            {props.data?.permanentAddress?.mobileNumber
                              ? props.data?.permanentAddress?.mobileNumber
                              : "-"}
                          </p>
                        </div>
                        <div>
                          <h4 className="text-slate-600">Email</h4>
                          <p className="my-1 text-lg">
                            {props.data?.permanentAddress?.email
                              ? props.data?.permanentAddress?.email
                              : "-"}
                          </p>
                        </div>
                      </div>
                    </>}
              </div>
            </div>
    </div>
  );
}
export default PermanentAddress;

export const EditPermanentAddress = (props) => {
  const [error,setError] = useState({email:"",phone:"",})
  let formData = cloneDeep(props.data);
  const handleInputChange = (e) => {
    let newData = formData;
    if(e.target.name === "email"){
      const email = e.target.value;
      const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
      let isValid = emailRegex.test(email);
      if(isValid === false){
        setError((prevError) => ({ ...prevError, email : "Enter valid email" }));
      }else{
        setError((prevError) => ({ ...prevError, email : "" }));
      }
      newData.permanentAddress.email = e.target.value;
    }else{
      newData.permanentAddress[e.target.name] = e.target.value;
    }
    props.setEmployee(newData);
  };
  const handlePhoneInputChange = (value, name) => {
    let newData = formData;
    const phoneNumberPattern = /^\d{12,15}$/
    let isValid = phoneNumberPattern.test(value);
    if(isValid === false){
      setError((prevError) => ({ ...prevError, phone: "Invalid phone number" }));
    }else{
      setError((prevError) => ({ ...prevError, phone: "" }));
    }
    newData.permanentAddress[name] = value;
    props.setEmployee(newData);
  };
 return (
   <>
     <div className="grid grid-cols-4 p-2 gap-2">
       <div>
         <h4 className="text-slate-600">Name</h4>
         <input
           type="text"
           name="name"
           value={formData?.permanentAddress?.name || ""}
           className="border rounded-md my-2 px-2 py-1 w-[80%]"
           onChange={handleInputChange}
         />
       </div>
       <div>
         <h4 className="text-slate-600">Address</h4>
         <input
           type="text"
           name="address"
           value={formData?.permanentAddress?.address || ""}
           className="border rounded-md my-2 px-2 py-1 w-[80%]"
           onChange={handleInputChange}
         />
       </div>
       <div>
         <h4 className="text-slate-600">City</h4>
         <input
           type="text"
           name="city"
           value={formData?.permanentAddress?.city || ""}
           className="border rounded-md my-2 px-2 py-1 w-[80%]"
           onChange={handleInputChange}
         />
       </div>
       <div>
         <h4 className="text-slate-600">State</h4>
         <input
           type="text"
           name="state"
           value={formData?.permanentAddress?.state || ""}
           className="border rounded-md my-2 px-2 py-1 w-[80%]"
           onChange={handleInputChange}
         />
       </div>
       <div>
         <h4 className="text-slate-600">Country</h4>
         <input
           type="text"
           name="country"
           value={formData?.permanentAddress?.country || ""}
           className="border rounded-md my-2 px-2 py-1 w-[80%]"
           onChange={handleInputChange}
         />
       </div>
       <div>
         <h4 className="text-slate-600">Pincode</h4>
         <input
           type="text"
           name="pincode"
           value={formData?.permanentAddress?.pincode || ""}
           className="border rounded-md my-2 px-2 py-1 w-[80%]"
           onChange={handleInputChange}
         />
       </div>
       <div>
         <h4 className="text-slate-600">Phone</h4>
         <input
           type="text"
           name="phone"
           value={formData?.permanentAddress?.phone || ""}
           className="border rounded-md my-2 px-2 py-1 w-[80%]"
           onChange={handleInputChange}
         />
       </div>
       <div>
         <h4 className="text-slate-600">Fax</h4>
         <input
           type="text"
           name="fax"
           value={formData?.permanentAddress?.fax || ""}
           className="border rounded-md my-2 px-2 py-1 w-[80%]"
           onChange={handleInputChange}
         />
       </div>
       <div>
         <h4 className="text-slate-600">Mobile Number</h4>
         {/* <input
           type="text"
           name="mobileNumber"
           value={formData?.permanentAddress?.mobileNumber || ""}
           className="border rounded-md my-2 px-2 py-1"
           onChange={handleInputChange}
         /> */}
         <div className="w-[55%]">
           <CustomPhoneInput
             name="mobileNumber"
             value={formData?.permanentAddress?.mobileNumber || ""}
             onPhoneInputChange={handlePhoneInputChange}
             customPlaceholder="Mobile Number"
           />
         </div>
         {error.phone !== "" && (
           <p className="text-red-500 text-xs">{error.phone}</p>
         )}
       </div>
       <div>
         <h4 className="text-slate-600">Email</h4>
         <input
           type="email"
           name="email"
           value={formData?.permanentAddress?.email || ""}
           className="border rounded-md my-2 px-2 py-1 w-[80%]"
           onChange={handleInputChange}
         />
         {error.email !== "" && (
           <p className="text-red-500 text-xs">{error.email}</p>
         )}
       </div>
     </div>
     <div className="mx-10 flex items-end justify-end space-x-5">
       <button
         className="border px-2  rounded-md"
         onClick={props.save}
         disabled={error.email !== "" || error.phone !== ""}
       >
         Save
       </button>
       <button
         className="border px-2  rounded-md"
         onClick={() => {
           props.setEdit((prevEdit) => ({
             ...prevEdit,
             permanentAddress: false,
           }));
           props.setEmployee(props.oldData);
         }}
       >
         Cancel
       </button>
     </div>
   </>
 );
}
