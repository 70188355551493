import api from "./index";

const postsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getPosts: builder.query({
      query: () => ({
        url: "/posts",
        // params: "",
        method: "get",
      }),
    }),
  }),
});


export default postsApi
export const {useGetPostsQuery} = postsApi