import React from "react";
import dayjs from "dayjs";
import EditIcon from "@mui/icons-material/Edit";
import { cloneDeep } from "lodash";

function Education(props) {
  // const Id = getToken("Id");
  // const { data, isSuccess } = useCreateProfileQuery({
  //   employeeId: `${Id}`,
  // });

  return (
<div className="w-full">
<div className="w-[95%] bg-white rounded-xl mx-auto p-3">
              <div>
                <h3 className="text-xl my-2 p-2 border-l-2 border-purple-300 font-semibold">
                  Education
                  <span className={`mx-2 cursor-pointer ${
                      props.edit.educationInformation ? "hidden" : ""
                    }`}
                    onClick={() =>
                      props.setEdit((prevEdit) => ({
                        ...prevEdit,
                        educationInformation : true,
                      }))
                    }
                  >
                    {<EditIcon />}
                  </span>
                </h3>

                {
                   props.edit.educationInformation ? 
                    <EditEducationDetails
                      data={props.data}
                      setEmployee={props.setEmployee}
                      edit={props.edit}
                      setEdit={props.setEdit}
                      save={props.save}
                      oldData={props.oldData}
                    /> : 
                    <>
                      <div className="grid grid-cols-4 p-2 gap-2">
                        <div>
                          <h4 className="text-slate-600">Institute</h4>
                          <p className="my-1 text-lg">
                            {props.data?.academicDetails?.institute
                              ? props.data?.academicDetails?.institute
                              : "-"}
                          </p>
                        </div>
                        <div>
                          <h4 className="text-slate-600">Grade</h4>
                          <p className="my-1 text-lg">
                            {props.data?.academicDetails?.grade
                              ? props.data?.academicDetails?.grade
                              : "-"}
                          </p>
                        </div>
                        <div>
                          <h4 className="text-slate-600">From Date</h4>
                          <p className="my-1 text-lg">
                            {props.data?.academicDetails?.fromDate
                              ? dayjs(props.data?.academicDetails?.fromDate).format(
                                "DD/MM/YYYY"
                              )
                              : "-"}
                          </p>
                        </div>
                        <div>
                          <h4 className="text-slate-600">To Date</h4>
                          <p className="my-1 text-lg">
                            {props.data?.academicDetails?.toDate
                              ? dayjs(props.data?.academicDetails?.toDate).format(
                                "DD/MM/YYYY"
                              )
                              : "-"}
                          </p>
                        </div>
                        <div>
                          <h4 className="text-slate-600">Qualification</h4>
                          <p className="my-1 text-lg">
                            {props.data?.academicDetails?.qualification
                              ? props.data?.academicDetails?.qualification
                              : "-"}
                          </p>
                        </div>
                        <div>
                          <h4 className="text-slate-600">Remarks</h4>
                          <p className="my-1 text-lg">
                            {props.data?.academicDetails?.remarks
                              ? props.data?.academicDetails?.remarks
                              : "-"}
                          </p>
                        </div>
                      </div>
                    </>
                }
              </div>
            </div>
    </div>
  );
}
export default Education;

export const EditEducationDetails = (props) => {
  let formData = cloneDeep(props.data);
  const handleInputChange = (e) => {
    let newData = formData;
    if( e.target.name === "fromDate" || e.target.name === "toDate" ){
      let date = new Date(e.target.value).toISOString();
      newData.academicDetails[e.target.name] = date;
    }else if(e.target.name === "institute"){
       if(/^[a-zA-Z\s]*$/.test(e.target.value) || e.target.value === ""){
        newData.academicDetails[e.target.name] = e.target.value;
       }
    }else{
      newData.academicDetails[e.target.name] = e.target.value;
    }
    props.setEmployee(newData);
  };

  return (
    <>
      <div className="grid grid-cols-4 p-2 gap-2">
        <div>
          <h4 className="text-slate-600">Institute</h4>
          <input
            type="text"
            name="institute"
            value={formData?.academicDetails?.institute || ""}
            className="border rounded-md my-2 px-2 py-1 w-[80%]"
            onChange={handleInputChange}
          />
        </div>
        <div>
          <h4 className="text-slate-600">Grade</h4>
          <input
            type="text"
            name="grade"
            value={formData?.academicDetails?.grade || ""}
            className="border rounded-md my-2 px-2 py-1 w-[80%]"
            onChange={handleInputChange}
          />
        </div>
        <div>
          <h4 className="text-slate-600">From Date</h4>
          <input
            type="date"
            name="fromDate"
            max={formData?.academicDetails?.toDate ? (() => {
              const toDate = new Date(formData.academicDetails.toDate);
              toDate.setDate(toDate.getDate() - 1);
              return toDate.toISOString().split("T")[0];})() 
              : new Date().toISOString().split("T")[0]}
            value={dayjs(formData?.academicDetails?.fromDate).format(
              "YYYY-MM-DD"
            )}
            className="border rounded-md my-2 px-2 py-1 w-[80%]"
            onChange={handleInputChange}
          />
        </div>
        <div>
          <h4 className="text-slate-600">To Date</h4>
          <input
            type="date"
            max={new Date().toISOString().split("T")[0]}
            min={formData?.academicDetails?.fromDate ? (() => {
                 const fromDate = new Date(formData.academicDetails.fromDate);
                  fromDate.setDate(fromDate.getDate() + 1);
                  return fromDate.toISOString().split("T")[0];
            })() : ""}
            name="toDate"
            value={dayjs(formData?.academicDetails?.toDate).format(
              "YYYY-MM-DD"
            )}
            className="border rounded-md my-2 px-2 py-1 w-[80%]"
            onChange={handleInputChange}
          />
        </div>
        <div>
          <h4 className="text-slate-600">Qualification</h4>
          <input
            type="text"
            name="qualification"
            value={formData?.academicDetails?.qualification || ""}
            className="border rounded-md my-2 px-2 py-1 w-[80%]"
            onChange={handleInputChange}
          />
        </div>
        <div>
          <h4 className="text-slate-600">Remarks</h4>
          <input
            type="text"
            name="remarks"
            value={formData?.academicDetails?.remarks || ""}
            className="border rounded-md my-2 px-2 py-1 w-[80%]"
            onChange={handleInputChange}
          />
        </div>
      </div>
      <div className="mx-10 flex items-end justify-end space-x-5">
        <button className="border px-2  rounded-md" onClick={props.save}>
          Save
        </button>
        <button
          className="border px-2  rounded-md"
          onClick={() => {
            props.setEdit((prevEdit) => ({
              ...prevEdit,
              educationInformation : false,
            }));
            props.setEmployee(props.oldData);
          }}
        >
          Cancel
        </button>
      </div>
    </>
  );
}
