import React from 'react'
import { XMarkIcon } from '@heroicons/react/24/solid';
import { useDispatch, useSelector } from 'react-redux';
import { removeHoliday } from '../../store/reducers/calendarSlice';
import dayjs from 'dayjs';


const CustomHoliday = (props) => {

    const dispatch = useDispatch();
    const errorCheck = useSelector(state => state.calendar.errorCheck)
    const restrictToYear = (year) => {
      if(year){
        let restrict = new Date(year).getFullYear();
        const startOfYear = new Date(restrict, 0, 1).toISOString();
        const endOfYear = new Date(restrict, 11, 31).toISOString();
        return { start: startOfYear, end: endOfYear };
      }else {
        return { start: null, end: null }; 
      }
    };
    const { start, end } = restrictToYear(props.year);

    const handleRemoveHoliday = (index) => {
      dispatch(removeHoliday(index))
    }

    const handleError = (e) => {
      if(e.target.value === "") {

      }
    }
    return (
      <section className="relative border border-black rounded-xl py-5 px-2">
        <div className="flex space-x-10">
          <div>
            <h4 className="text-slate-600">
              Date
              <span
                className={`text-red-500 text-xl ${
                  props.disabled ? "hidden" : ""
                }`}
              >
                *
              </span>
            </h4>
            {!props.disabled ? (
              <input
                type="date"
                name="date"
                className={`border rounded-md my-2 px-2 py-1 w-40 h-10 ${
                  props.date === "" && errorCheck ? "border-red-500" : ""
                }`}
                value={props.date ? props.date.split("T")[0] : ""}
                min={start ? start.split("T")[0] : ""}
                max={end ? end.split("T")[0] : ""}
                onChange={(e) => props.handleChange(e, props.index)}
                onBlur={(e) => handleError(e)}
                disabled={props.disabled}
              />
            ) : (
              <>
                <p className="mt-3">
                  {props.date ? dayjs(props.date).format("DD") : "N/A"}
                </p>
                <p className="mb-3 text-sm">
                  {props.date ? dayjs(props.date).format("ddd") : "N/A"}
                </p>
              </>
            )}
          </div>
          <div>
            <h4 className="text-slate-600">
              Holiday Type
              <span
                className={`text-red-500 text-xl ${
                  props.disabled ? "hidden" : ""
                }`}
              >
                *
              </span>
            </h4>
            {!props.disabled ? (
              <select
                name="leaveType"
                value={props.leaveType || ""}
                className={`border rounded-md my-2 px-2 py-1 w-40 h-10 ${
                  props.leaveType === "" && errorCheck ? "border-red-500" : ""
                }`}
                onChange={(e) => props.handleChange(e, props.index)}
                onBlur={(e) => handleError(e)}
                disabled={props.disabled}
              >
                <option defaultValue={true} disabled={true} value="">
                  -- Select an option --
                </option>
                <option value="CONFIRM">Confirm</option>
                <option value="OPTIONAL">Optional</option>
              </select>
            ) : (
              <p className="my-3">{props.leaveType}</p>
            )}
          </div>
          <div>
            <h4 className="text-slate-600">
              Occasion
              <span
                className={`text-red-500 text-xl ${
                  props.disabled ? "hidden" : ""
                }`}
              >
                *
              </span>
            </h4>
            {!props.disabled ? (
              <input
                type="text"
                name="occasion"
                value={props.occasion || ""}
                className={`border rounded-md my-2 px-2 py-1 w-40 h-10 ${
                  props.occasion === "" && errorCheck ? "border-red-500" : ""
                }`}
                onChange={(e) => props.handleChange(e, props.index)}
                onBlur={(e) => handleError(e)}
                disabled={props.disabled}
              />
            ) : (
              <p className="my-3">{props.occasion}</p>
            )}
          </div>
        </div>
        <div
          className={`absolute top-10 right-5 w-7 h-7 text-red-500 cursor-pointer ${
            props.disabled ? "hidden" : ""
          }`}
          onClick={() => handleRemoveHoliday(props.index)}
        >
          <XMarkIcon />
        </div>
      </section>
    );
}

export default CustomHoliday