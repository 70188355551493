import { React, useState, useEffect } from "react";
import {
  useGetLatestIdQuery,
  useGetAllDapartmentQuery,
  useGetEmployeeListQuery,
  useGetWorkLocationsQuery,
  useGetAllDesignationsQuery,
  useGetAllHolidayCalenderQuery,
  useGetAllEmployeeTypesQuery,
} from "../../api/addEmployeeApi";
import { useSelector, useDispatch } from "react-redux";
import {
  updateEmployeeDetails,
  clearErrorState,
  updateErrorState,
} from "../../store/reducers/addEmployeeSlice";
import { updateErrorMessage } from "../../store/reducers/errorMessageSlice";
import TextField from "../../Components/customComponents/customTextField";
import CustomRadioSelect from "../../Components/customComponents/customRadioSelection";
import Select from "../../Components/customComponents/customSelect";
import { values } from "lodash";

const BasicInfo = () => {
  const empState = useSelector((state) => state.addEmployee.employeeDetails);
  const errorState = useSelector((state) => state.addEmployee.errorState);
  const feildDisabled = useSelector((state) => state.addEmployee.feildsDisabled);
  const errorMessageState = useSelector((state)=>state.errorMessage.errorMessage);
  const dispatch = useDispatch();
  const [selectedOption, setSelectedOption] = useState("");
  const {
    data: empLatestId,
    isLoading: isLoadingEmpLatestId,
    isSuccess: isSuccessEmpLatestId,
    isError: isErrorEmpLatestId,
    refetch: isRefetchEmpLatestId,
  } = useGetLatestIdQuery();

  const {
    data: empList,
    isLoading: isLoadingEmpList,
    isSuccess: isSuccessEmpList,
    isError: isErrorEmpList,
    refetch: isRefetchEmpList,
  } = useGetEmployeeListQuery();

  const {
    data: workLocations,
    isLoading: isLoadingWorkLocations,
    isSuccess: isSuccessWorkLocation,
    isError: isErrorWorkLocations,
    refetch: isRefetchWorklocations,
  } = useGetWorkLocationsQuery();

  const {
    data: allDeapartments,
    isLoading: isLoadingAllDepartments,
    isSuccess: isSuccessAllDepartments,
    isError: isErrorAllDepartments,
    refetch: isRefetchDepartments,
  } = useGetAllDapartmentQuery();

  const {
    data: allDesignation,
    isLoading: isLoadingAllDesignation,
    isSuccess: isSuccessAllDesignation,
    isError: isErrorAllDesignation,
    refetch: isRefetchDesignation,
  } = useGetAllDesignationsQuery();

  const {
    data: allHolidayCalender,
    isLoading: isLoadingAllHolidayCalender,
    isSuccess: isSuccessAllHolidayCalender,
    isError: isErrorAllHolidayCalender,
    refetch: isRefetchHolidayCalender,
  } = useGetAllHolidayCalenderQuery();

  const {
    data: allEmployeeTypes,
    isLoading: isLoadingAllEmployeeTypes,
    isSuccess: isSuccessAllEmployeeTypes,
    isError: isErrorAllEmployeeTypes,
    refetch: isRefetchEmployeeTypes,
  } = useGetAllEmployeeTypesQuery();

  const options1 = [
    { value: "CONFIRMED", label: "CONFIRMED" },
    { value: "CONSULTANT", label: "CONSULTANT" },
    { value: "PROBATION", label: "PROBATION" },
  ];

  const managerOptions = [
    { value: true, label: "Yes" },
    { value: false, label: "No" },
  ]

  const shiftOptions = [
    { value: "GENERAL_SHIFT_1", label: "GENERAL SHIFT 1" },
    { value: "GENERAL_SHIFT_2", label: "GENERAL SHIFT 2" },
    { value: "NIGHT_SHIFT", label: "NIGHT SHIFT" },
  ];

  const allWorkLocationOptions = workLocations
    ? workLocations
        .filter((location) => location.isActive)
        .map((item) => {
          return { value: item._id, label: item.location };
        })
    : [];

  const allDeapartmentsOptions = allDeapartments
    ? allDeapartments
        .filter((department) => department.isActive)
        .map((item) => {
          return { value: item._id, label: item.departmentName };
        })
    : [];

  const designationOptions = allDesignation
    ? Object.entries(
        allDesignation[empState.professionalDetails.department] || {}
      ).map((item) => {
        return { value: item[1], label: item[0] };
      })
    : [];

  const holidayCalenderOptions = allHolidayCalender
    ? Object.entries(allHolidayCalender || {}).map((item) => {
        return { value: item[0], label: item[1] };
      })
    : [];

  const allEmployeeTypesOptions = allEmployeeTypes
    ? allEmployeeTypes
        .filter((type) => type.isActive)
        .map((item) => {
          return { value: item._id, label: item.employeeCategory };
        })
    : [];

  const empListOptions = empList
    ? empList
        .filter((emp) => (emp.ismanager === "true" && empState.professionalDetails.department === emp.department) || emp.ismanager === "null")
        .map((emp) => {
          return { value: emp.id, label: emp.name };
        })
    : [];

  const [selectedOption1, setSelectedOption1] = useState(options1[0].value);

  const handleSelectChange = (e) => {
    
    if(e.target.name === "employeeDetails.isManager"){
      let value;
      e.target.value === "true" ? value = true : value = false;
      dispatch(
        updateEmployeeDetails({ value: value, path: e.target.name })
      );
    }else {
    dispatch(
      updateEmployeeDetails({ value: e.target.value, path: e.target.name })
    );}
  };

  const handleOptionSelect = (e) => {
    dispatch(
      updateEmployeeDetails({ value: e.target.value, path: e.target.name })
    );
    // setSelectedOption(e.target.value);
  };

  const options = ["MALE", "FEMALE", "OTHER"];

  const handleChange = (e) => {
    if (
      empState.professionalDetails.dateofJoining &&
      e.target.name == "employeeDetails.professionalDetails.probationPeriod"
    ) {
      if(e.target.value){

      
      let days=parseInt(e.target.value,10)
      const date = new Date(empState.professionalDetails.dateofJoining);
      
      
      date.setDate(date.getDate() + days);
      dispatch(
        updateEmployeeDetails({
          value: date.toISOString(),
          path: "employeeDetails.professionalDetails.confirmationDate",
        })
      );
      }
    }
    if (
      e.target.name == "employeeDetails.personalDetails.dob" ||
      e.target.name == "employeeDetails.professionalDetails.dateofJoining" ||
      e.target.name == "employeeDetails.professionalDetails.confirmationDate"
    ) {
      // dispatch(
      //   updateEmployeeDetails({
      //     value: empLatestId.employeeId,
      //     path: "employeeDetails.professionalDetails.employeeId",
      //   })
      // );
      dispatch(
        updateEmployeeDetails({
          value: new Date(e.target.value).toISOString(),
          path: e.target.name,
        })
      );
    } else if (
      e.target.name == "employeeDetails.personalDetails.aadharNumber"
    ) {
      // dispatch(
      //   updateEmployeeDetails({
      //     value: empLatestId.employeeId,
      //     path: "employeeDetails.professionalDetails.employeeId",
      //   })
      // );
      if (/^\d+$/.test(e.target.value) && e.target.value.length < 13) {
        dispatch(
          updateEmployeeDetails({ value: e.target.value, path: e.target.name })
        );
      }
      if (e.target.value.length == 0) {
        dispatch(
          updateEmployeeDetails({ value: e.target.value, path: e.target.name })
        );
      }
    } else if (
      e.target.name === "employeeDetails.professionalDetails.probationPeriod"
    ) {
      if (e.target.value > 366) {
        dispatch(updateEmployeeDetails({ value: 365, path: e.target.name }));
      } else if(e.target.value < 0){
        dispatch(updateEmployeeDetails({ value: 0, path: e.target.name }));
      } else {
        dispatch(
          updateEmployeeDetails({ value: e.target.value, path: e.target.name })
        );
      }
    } else if (
      e.target.name === "employeeDetails.professionalDetails.noticePeriod"
    ) {
      if(e.target.value < 0){
        dispatch(updateEmployeeDetails({ value: 0, path: e.target.name }));
      } else {
        dispatch(
          updateEmployeeDetails({ value: e.target.value, path: e.target.name })
        );
      }
    }else if (
      e.target.name === "employeeDetails.professionalDetails.totalExperience" || e.target.name === "employeeDetails.professionalDetails.currentCompanyExperience"
    ) {
      if(e.target.value < 0){
        dispatch(updateEmployeeDetails({ value: 0, path: e.target.name }));
      } else {
        dispatch(
          updateEmployeeDetails({ value: e.target.value, path: e.target.name })
        );
      }
    }
     else {
      dispatch(
        updateEmployeeDetails({ value: e.target.value, path: e.target.name })
      );
    }
  };

  useEffect(() => {
    isRefetchEmpLatestId();
    isRefetchEmpList();
    isRefetchDepartments();
    isRefetchDesignation();
    isRefetchEmployeeTypes();
    isRefetchHolidayCalender();
    isRefetchWorklocations();
  }, []);
  return (
    <>
      <h1 className="text-2xl mb-10">Step 1:PROFESSIONAL DETAILS</h1>

      <div className="flex justify-around">
        <div className="flex flex-col justify-center items-end ">
          <TextField
            name="employeeDetails.professionalDetails.employeeId"
            label="Employee Number"
            type="text"
            value={empState.professionalDetails.employeeId}
            onChange={handleChange}
            isMandatory={true}
            disabled={feildDisabled}
            className = {`${empState.professionalDetails.employeeId === "" && errorMessageState === true && "border border-red-500"}`}
          />
          <TextField
            name="employeeDetails.firstName"
            label="First Name"
            type="text"
            value={empState.firstName}
            onChange={handleChange}
            isMandatory={true}
            error={errorState.step0.firstName ? errorState.step0.firstName : ""}
            disabled={feildDisabled}
            onBlur={() => {
              dispatch(updateErrorState({ activeTab: 0, field: "firstName" }));
            }}
            className = {`${empState.firstName === "" && errorMessageState === true  && "border border-red-500"}`}
          />
          <TextField
            name="employeeDetails.lastName"
            label="Last Name"
            type="text"
            value={empState.lastName}
            onChange={handleChange}
            isMandatory={true}
            error={errorState.step0.lastName ? errorState.step0.lastName : ""}
            disabled={feildDisabled}
            onBlur={() => {
              dispatch(updateErrorState({ activeTab: 0, field: "lastName" }));
            }}
            className = {`${empState.lastName === "" && errorMessageState === true  && "border border-red-500"}`}
          />

          <TextField
            name="employeeDetails.professionalDetails.companyEmail"
            label="Company Email"
            type="email"
            onChange={handleChange}
            isMandatory={false}
            value={empState.professionalDetails.companyEmail}
            error={
              errorState.step0.companyEmail ? errorState.step0.companyEmail : ""
            }
            disabled={feildDisabled}
            // 
            onBlur={() => {
              dispatch(
                updateErrorState({ activeTab: 0, field: "companyEmail" })
              );
            }}
            //className = {`${empState.professionalDetails.companyEmail === "" && errorMessageState === true && "border border-red-500"}`}
          />
          <Select
            label="Location"
            name="employeeDetails.professionalDetails.workLocation"
            options={allWorkLocationOptions}
            value={empState.professionalDetails.workLocation}
            onChange={handleSelectChange}
            disabled={feildDisabled}
            isMandatory={true}
            className = {`${empState.professionalDetails.workLocation === "" && errorMessageState === true && "border border-red-500"}`}
          />

          <Select
            label="Department"
            options={allDeapartmentsOptions}
            name="employeeDetails.professionalDetails.department"
            value={empState.professionalDetails.department}
            onChange={handleSelectChange}
            disabled={feildDisabled}
            isMandatory={true}
            className = {`${empState.professionalDetails.department === "" && errorMessageState === true && "border border-red-500"}`}
          />
          <Select
            label="Designation"
            name="employeeDetails.professionalDetails.currentDesignation"
            options={designationOptions}
            value={empState.professionalDetails.currentDesignation}
            onChange={handleSelectChange}
            disabled={feildDisabled}
            isMandatory={true}
            className = {`${empState.professionalDetails.currentDesignation === "" && errorMessageState === true  && "border border-red-500"}`}
          />

          <Select
            label="Holiday Category"
            name="employeeDetails.professionalDetails.holidayCalender"
            options={holidayCalenderOptions}
            value={empState.professionalDetails.holidayCalender}
            onChange={handleSelectChange}
            disabled={feildDisabled}
            isMandatory={true}
            className = {`${empState.professionalDetails.holidayCalender === "" && errorMessageState === true  && "border border-red-500"}`}
          />

          <Select
            name="employeeDetails.professionalDetails.managerId"
            label="Reporting Manager"
            options={empListOptions}
            onChange={handleSelectChange}
            value={empState.professionalDetails.managerId}
            disabled={feildDisabled}
            isMandatory={true}
            className = {`${empState.professionalDetails.managerId === "" && errorMessageState === true  && "border border-red-500"}`}
          />

          <Select
            name="employeeDetails.professionalDetails.employeeType"
            label="Employee Type"
            options={allEmployeeTypesOptions}
            onChange={handleSelectChange}
            value={empState.professionalDetails.employeeType}
            disabled={feildDisabled}
            isMandatory={true}
            className = {`${empState.professionalDetails.employeeType === "" && errorMessageState === true  && "border border-red-500"}`}
          />
        </div>
        <div className="flex flex-col justify-center items-end ">
          <Select
            label="Manager"
            name={"employeeDetails.isManager"}
            options={managerOptions}
            onChange={handleSelectChange}
            value={empState.isManager}
            disabled={feildDisabled}
            isMandatory={true}
            className = {`${empState.isManager === "" && errorMessageState === true && "border border-red-500"}`}
          />
          <Select
            label="Status"
            name={"employeeDetails.professionalDetails.status"}
            options={options1}
            onChange={handleSelectChange}
            value={empState.professionalDetails.status}
            disabled={feildDisabled}
            isMandatory={true}
            className = {`${empState.professionalDetails.status === "" && errorMessageState === true  && "border border-red-500"}`}
          />

          <TextField
            name="employeeDetails.professionalDetails.dateofJoining"
            label="Date of Joining"
            type="date"
            onChange={handleChange}
            value={empState.professionalDetails.dateofJoining?.split("T")[0]}
            disabled={feildDisabled}
            isMandatory={true}
            className = {`${empState.professionalDetails.dateofJoining?.split("T")[0] === "" && errorMessageState === true  && "border border-red-500"}`}
            max={new Date().toISOString()?.split("T")[0]}
          />
          <TextField
            name="employeeDetails.professionalDetails.probationPeriod"
            label="Probation Period"
            type="number"
            onChange={handleChange}
            placeHolder="Days"
            value={empState.professionalDetails.probationPeriod}
            disabled={feildDisabled}
            isMandatory={true}
            min={0}
            className = {`${(empState.professionalDetails.probationPeriod === "" || empState.professionalDetails.probationPeriod === 0 ) && errorMessageState === true  && "border border-red-500"}`}
          />
          <TextField
            name="employeeDetails.professionalDetails.confirmationDate"
            label="Confirmation Date"
            type="date"
            onChange={handleChange}
            value={empState.professionalDetails.confirmationDate?.split("T")[0]}
            disabled={feildDisabled}
          />
          <TextField
            name="employeeDetails.professionalDetails.noticePeriod"
            label="Notice Period"
            type="number"
            onChange={handleChange}
            placeHolder="Days"
            value={empState.professionalDetails.noticePeriod}
            disabled={feildDisabled}
            isMandatory={true}
            min={0}
            className = {`${(empState.professionalDetails.noticePeriod === "" ||empState.professionalDetails.noticePeriod === 0 ) && errorMessageState === true  && "border border-red-500"}`}
          />
          <Select
            label="Shift"
            name={"employeeDetails.professionalDetails.shift"}
            options={shiftOptions}
            onChange={handleSelectChange}
            value={empState.professionalDetails.shift}
            disabled={feildDisabled}
            isMandatory={true}
            className = {`${empState.professionalDetails.shift === "" && errorMessageState === true  && "border border-red-500"}`}
          />
          <TextField
            name="employeeDetails.professionalDetails.totalExperience"
            label="Total Experience"
            type="number"
            onChange={handleChange}
            placeHolder="Years"
            disabled={feildDisabled}
            value={empState.professionalDetails.totalExperience}
            min={0}
          />
          <TextField
            name="employeeDetails.professionalDetails.currentCompanyExperience"
            label="Current Company Experience"
            type="number"
            onChange={handleChange}
            disabled={feildDisabled}
            placeHolder="Years"
            min={0}
            value={empState.professionalDetails.currentCompanyExperience}
          />
        </div>
      </div>
    </>
  );
};

export default BasicInfo;
