import React, { useState } from "react";
import { useEffect } from "react";
import "../newLetterTemplate/style.css"; // Import a CSS file for styling
import { useSelector,useDispatch } from "react-redux";
import { updateTableData } from "../../../store/reducers/genrateLetterSlice";

const CustomTable = ({ setFormData, formData }) => {
  const tableDataStore = useSelector((state)=>state.generateLetter.tableData);
  const dispatch = useDispatch();
  //const [tableData, setTableData] = useState();

  const handleInputChange = (id, field, value) => {
    // Update the field's value in the state
    const updatedData = tableDataStore.map((row) => {
      if (row.id === id) {
        return { ...row, [field]: value };
      }
      return row;
    });
    const modifiedData = updatedData.map(({ id, ...rest }) => rest);
    setFormData({ ...formData, customFields: modifiedData });
    dispatch(updateTableData(updatedData))
    //setTableData(updatedData);
  };

  const toggleRequired = (id) => {
    // Toggle the "required" value
    const updatedData = tableDataStore.map((row) => {
      if (row.id == id) {
        return { ...row, isRequired: !row.isRequired };
      }
      return row;
    });
    setFormData({ ...formData, customFields: updatedData });
    dispatch(updateTableData(updatedData))
    //setTableData(updatedData);
  };

  useEffect(() => {

    if (formData && !(tableDataStore.length > 0)) {
      console.log(tableDataStore,"inside if");
      const data =
        formData.customFields &&
        formData.customFields.length > 0 &&
        formData.customFields.map((line, index) => ({
          id: index + 1,
          fieldPlaceHolder: line.fieldPlaceHolder,
          fieldDisplayName: line.fieldDisplayName,
          isRequired: false,
          fieldValue: "",
        }));
       dispatch(updateTableData(data)) 
      //setTableData(data);
    }
  }, []);

  return (
    <div>
      <table className="custom-field-table">
        <thead>
          <tr>
            <th>Field Placeholder</th>
            <th>Field Name</th>
            <th>Required</th>
            <th>Field Values</th>
          </tr>
        </thead>
        <tbody>
          {tableDataStore && tableDataStore.map((row) => (
            <tr key={row.id}>
              <td>
                <input
                  disabled
                  type="text"
                  value={row.fieldPlaceHolder}
                  onChange={(e) =>
                    handleInputChange(
                      row.id,
                      "fieldPlaceHolder",
                      e.target.value
                    )
                  }
                />
              </td>

              <td>
                <input
                  disabled
                  type="text"
                  value={row.fieldDisplayName}
                  onChange={(e) =>
                    handleInputChange(
                      row.id,
                      "fieldDisplayName",
                      e.target.value
                    )
                  }
                />
              </td>
              <td>
                <div className="flex justify-center">
                  <input
                    disabled
                    type="checkbox"
                    checked={row.isRequired}
                    onChange={() => toggleRequired(row.id)}
                  />
                </div>
              </td>
              <td>
                <input
                  type="text"
                  value={row.fieldValue}
                  onChange={(e) =>
                    handleInputChange(row.id, "fieldValue", e.target.value)
                  }
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default CustomTable;
