import React, { useState, useEffect } from "react";
import Select from "../../customComponents/customSelect";
import { serviceUrl, genericHeaders } from "../../../utils/apiConfig";
import axios, { all } from "axios";
import CustomRadioSelect from "../../customComponents/customRadioSelection";
import { useGetEmployeesListQuery } from "../../../api/getEmployeeList";
import { v4 as uuidv4 } from "uuid";
import { useSelector,useDispatch } from "react-redux";
import { updateTableData } from "../../../store/reducers/genrateLetterSlice";


const GeneralStep = ({ setFormData, formData, allLetters, setAllLetters,setUrl }) => {
  const options = ["Single"];
  const { data: employeeListData, isSuccess: employeeListSuccess } =
    useGetEmployeesListQuery();
  const [isSingleSelected, setIsSingleSelected] = useState();

  const tableDataStore = useSelector((state)=>state.generateLetter.tableData);
  const dispatch = useDispatch();

  const getAllLetters = async () => {
    try {
      const url = `${serviceUrl}/api/letterManagement/getAllLetterManagements`;
      let headers = { ...genericHeaders() };
      const response = await axios.get(url, {
        headers: headers,
      });
      setAllLetters(response.data);
    } catch (err) {
      console.error("An error occurred during file upload:", err);
    }
  };

  useEffect(() => {
    getAllLetters();
  }, []);

  const letterTemplatesOptions = allLetters
    ? allLetters
        .filter((each) => each.isActive)
        .map((item) => {
          return { value: item._id, label: item.templateName };
        })
    : [allLetters];

  const handleSelectChange = (e) => {
    const { name, value } = e.target;
    dispatch(updateTableData([]))
    if (name == "templateId") {
      let data = { ...formData };
      let res = {};
      if (allLetters && allLetters.length > 0 && value) {
        res = allLetters && allLetters.find((each) => each._id == value);
      }

      if (res && res.document && res.customFields) {
        data["customFields"] = res.customFields;
        data["document"] = res.document;
        data["id"] = uuidv4();
        data["templateId"] = value;
        setUrl(res.document.resourceName);
      }
      setFormData(data);
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleOptionSelect = (e) => {};

  return (
    <>
      <h3 className="text-xl my-2 pb-2 font-semibold">Step 1: General</h3>
      <div className="flex">
        <div className="flex flex-col justify-center">
          <Select
            label="Letter Template"
            name="templateId"
            options={letterTemplatesOptions}
            value={formData?.templateId}
            onChange={handleSelectChange}
            isMandatory={true}
          />
          {/* <div className="mb-4 flex items-center">
            <label className="block mb-4 text-sm font-medium text-gray-700 mr-5">
              Generate For
            </label>
            <CustomRadioSelect
              name="canBeRequestedByEmployee"
              options={options}
              isMandatory={false}
              value={formData.canBeRequestedByEmployee}
              onSelect={handleOptionSelect}
            />{" "}
          </div> */}
          <div className="flex mb-4 items-center">
            <label className="block text-sm font-medium text-gray-700 mr-5">
              {"Employee "} <span className="text-red-500 text-xl">*</span>
            </label>
            <select
              name={"employeeId"}
              value={formData?.employeeId}
              onChange={handleSelectChange}
              className="border rounded-md py-2 px-3 w-60"
            >
              <option value="">Select an Employee...</option>
              {employeeListData?.map((option) => (
                <option key={option.id} value={option.id}>
                  {option.name}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
    </>
  );
};

export default GeneralStep;
