import React from "react";
import dayjs from "dayjs";

const HolidayDialog = ({ isOpen, onClose, holidays, month, year }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-6 rounded-lg max-w-md w-full max-h-[80vh] overflow-y-auto">
        <h2 className="text-2xl font-bold mb-4">
          {month} {year} Holidays
        </h2>
        {holidays.map((holiday, index) => (
          <div key={index} className="mb-4 border-b pb-2">
            <div className="flex items-center">
              <div className="w-1/4">
                <p className="font-semibold">
                  {dayjs(holiday.date).format("DD")}
                </p>
                <p className="text-sm text-gray-600">
                  {dayjs(holiday.date).format("ddd")}
                </p>
              </div>
              <div className="w-3/4">
                <p className="font-medium">{holiday.occasion}</p>
                <p className="text-sm text-gray-600">{holiday.leaveType}</p>
              </div>
            </div>
          </div>
        ))}
        <div className="flex justify-end">
          <button
            className="mt-4 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
            onClick={onClose}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default HolidayDialog;
