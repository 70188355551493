import React, { useState } from 'react'
import Loader from "../Components/customComponents/customLoader" 
import axios from 'axios';
import { serviceUrl } from '../utils/apiConfig';
import { Alert, Snackbar } from "@mui/material";
import { useNavigate } from 'react-router-dom';

const ResetScreen = () => {

  const [empId,setEmpId] = useState("");
  const [isLoading,setIsLoading] = useState(false);
  const [error,setError] = useState(false);
  const [snack,setSnack] = useState({
    open:false,
    error:false,
    message:""
  })
  const history = useNavigate();

  const handleSnackOpen = (open, error, message) => {
    setSnack({
      open: open,
      error: error,
      message: message,
    });
  };
  const handleSnackClose = () => {
    setSnack({
      open: false,
      error: false,
      message: "",
    });
  };
  const handleChange = (evt) => {
    if(evt.target.name = "employeeId"){
        setEmpId(evt.target.value);
    }
  }
  const handleSubmit = async() => {
    try{
      setIsLoading(true);
      let response = await axios.post(`${serviceUrl}/api/auth/resetPWD?employeeShortId=${empId}`)
      if(response.status === 200){
        setIsLoading(false);
        handleSnackOpen(true,false,"Email sent Successfully");
      }
      console.log(empId);
    }catch (error) {
        setIsLoading(false);
        handleSnackOpen(true,true,"Something Went Wrong")
        console.error(error);
    }
  }
  const handleConfirmPasswordKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSubmit();
    }
  };
  const handleEmailCheck = (evt) => {
    let emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    if (!emailPattern.test(evt.target.value)) {
      setError(true)
    }else{
      setError(false);
    }
  }

  return (
    <>
     {isLoading && <Loader/>}
        <Snackbar
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            open={snack.open}
            autoHideDuration={2000}
            onClose={handleSnackClose}
          >
            <Alert
              severity={snack.error ? 'error' : 'success'}
              variant="filled"
              onClose={handleSnackClose}
            >
              {snack.message}
            </Alert>
          </Snackbar>
      <div className="flex justify-center items-center h-[100vh]">
        <section className="w-[40%] bg-blue-50 rounded-xl shadow-md">
          
            
          <div className="mt-10 flex flex-col">
            <h2 className="text-3xl font-normal mx-auto">Password reset</h2>
            <p className="text-lg font-semibold mx-5 my-3 text-center">
              Please enter your email address and we'll send you a link to reset your password
            </p>
          </div>
          <div className="w-full mt-10 flex flex-col">
            <input
              name="employeeId"
              type="text"
              placeholder='Enter your email Id'
              onChange={(e) => handleChange(e)}
              onBlur={handleEmailCheck}
              value={empId}
              className={`w-1/2 p-2 mx-auto border-2 border-blue-400 ${ error ? 'border border-red-500' : ''}`}
              onKeyDown={handleConfirmPasswordKeyDown}
            />
            {error ? <p className='w-1/2 mx-auto text-[10px] text-red-500'>Please enter valid email address</p> : <></>}
            <button
              className={`mt-10  mx-auto rounded-lg bg-blue-400 w-1/2 p-2 ${
                empId === "" ? `bg-slate-300` : ``
              }`}
              disabled={empId === ""}
              onClick={handleSubmit}
            >
              Submit
            </button>
            <p className="text-blue-500 my-5 cursor-pointer mx-auto" onClick={() => history("/login")}>Back to Login</p>
            
          </div>
        </section>
      </div>
    </>
  );
}

export default ResetScreen