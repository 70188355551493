import React, { useEffect, useState } from 'react'
import { useSelector,useDispatch } from 'react-redux'
import { updateCalendar,updateErrorCheck,updateHoliday } from '../../store/reducers/calendarSlice';
import CustomHoliday from './CustomHoliday';
import { cloneDeep } from 'lodash';
import { Alert, Snackbar } from '@mui/material';
import { genericHeaders, serviceUrl } from '../../utils/apiConfig';
import axios from 'axios';
import Loader from '../customComponents/customLoader';


const AddCalendar = (props) => {

    const [snack, setSnack] = useState({
      open: false,
      error: false,
      message: "",
    });
    const [loader,setLoader] = useState();

    const calendarState = useSelector( state => state.calendar.calendar);
    const errorState = useSelector( state => state.calendar.errorCheck);
    const dispatch = useDispatch();

    const handleSnackOpen = (open,error,message) => {
      let snackData = {...snack};
      snackData.open = open;
      snackData.error = error;
      snackData.message = message;
      setSnack(snackData);
    };

    const handleSnackClose = () => {
      let data = {
        open: false,
        error: false,
        message: "",
      }
      setSnack(data);
    }

    const handleChange = (e) => {
      if(e.target.name === "yearOfCalender"){
        const year = new Date(e.target.value, 0, 1, 0, 0, 0);
        year.setMinutes(year.getMinutes() - year.getTimezoneOffset());
        const yearISO = year.toISOString();
        dispatch(updateCalendar({ path: e.target.name, value: yearISO }))
      }else if (e.target.name === "calenderName") {
        const value = e.target.value;
        const regex = /^[a-zA-Z0-9\s]*$/;
        if (regex.test(value)) {
          dispatch(updateCalendar({ path: e.target.name, value: value}))
        }
      }else{
        dispatch(updateCalendar({ path: e.target.name, value: e.target.value }))
      }
    }

    const handleFeildChange = (e,index) => {
      let newData = cloneDeep(calendarState);
      let holiday = newData.holidays[index];
      if (e.target.name === "date") {
        let value = new Date(e.target.value).toISOString();
        holiday["date"] = value;
        console.log(value);
      } else if (e.target.name === "occasion") {
        const value = e.target.value;
        const regex = /^[a-zA-Z\s]*$/;
        if (regex.test(value)) {
          holiday["occasion"] = value
        }
      } else {
        holiday[e.target.name] = e.target.value;
      }
      dispatch(updateCalendar({path: "holidays", value : newData.holidays }))
    }

    const yearOptions = () => {
      const currentYear = new Date().getFullYear();
      const years = [];
      for (let year = currentYear; year <= currentYear + 2; year++) {
        years.push(year);
      }
      return years
    }
    
    const handleAddHoliday = () => {
      let holidayCalendar = {
        "isActive": true,
        "date": "",
        "leaveType": "",
        "occasion": ""
      }
      dispatch(updateHoliday(holidayCalendar));
    }

    const handleSave = async() => {
      let payload = cloneDeep(calendarState);
      dispatch(updateErrorCheck(true));
      let errorFeilds = false;
      let holidays = false;
      if(payload.calenderName === "" || payload.yearOfCalender === ""){
        errorFeilds = true;
      }
      payload.holidays.forEach((holiday, index) => {
        Object.entries(holiday).forEach(([key, value]) => {
          if (key !== "isActive") {
            if (!value || value.trim() === '') {
              errorFeilds = true;
            }
          }
        });
      });
      if(payload.holidays.length === 0){
        holidays = true;
      }
      if (errorFeilds === false && holidays === false) {
        setLoader(true);
        try {
          let url = `${serviceUrl}/api/holidayCalender/create`
          let response = await axios.post(url,payload,{ headers: genericHeaders() })
          if(response.status === 201){
            setLoader(false);
            handleSnackOpen(true,false,"Calendar added successfully")//snackbar
            setTimeout(() => {
              props.refetch(); 
            }, 2000);
          }
        } catch (err) {
          setLoader(false);
          handleSnackOpen(true,true,"Something Went Wrong")//
          console.error(err)//snackbar
        }
      }else if(holidays === true && errorFeilds === false){
        handleSnackOpen(true,true,"Please try to add holidays")
      } else {
        handleSnackOpen(true,true,"Please enter all the mandatory fields")
      }
    }

  return (
    <>
     {loader && <Loader/>}
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={snack.open}
        autoHideDuration={2000}
        onClose={handleSnackClose}
      >
        <Alert
          severity={snack.error ? "error" : "success"}
          variant="filled"
          onClose={handleSnackClose}
        >
          {snack.message}
        </Alert>
      </Snackbar>
      <h2 className='ml-[10%] text-3xl'>Add a calendar</h2>
    <div className='w-[80%] mx-auto border border-blue-400 rounded-xl p-10'>
      <section className='flex justify-between'>
        <div className='flex space-x-10'>
          <div>
            <h4 className="text-slate-600">Calendar Name<span className="text-red-500 text-xl">*</span></h4>
            <input
              type="text"
              name="calenderName"
              value={calendarState.calenderName || ""}
              className={`border rounded-md my-2 px-2 py-1 w-60 h-10 ${calendarState.calenderName === "" && errorState ? 'border-red-500' : ''}`}
              onChange={handleChange}
            />
          </div>
          <div>
            <h4 className="text-slate-600">Year of Calendar<span className="text-red-500 text-xl">*</span></h4>
            <select
              name='yearOfCalender'
              value={new Date(calendarState.yearOfCalender).getFullYear() || ""}
              className={`border rounded-md my-2 px-2 py-1 w-60 h-10 ${calendarState.yearOfCalender === "" && errorState ? 'border-red-500' : ''}`}
              onChange={handleChange}
            >
              <option disabled={true} value="">-- Select an option --</option>
              {yearOptions().map((item) => (<option>{item}</option>))}
            </select>
          </div>
        </div>
        <div>
          <button className={`text-lg my-auto border py-2 px-4 rounded border-blue-400 mt-5 ${calendarState.calenderName && calendarState.yearOfCalender ? '' : 'bg-slate-300'}`} disabled={!(calendarState.calenderName && calendarState.yearOfCalender)} onClick={handleAddHoliday}>Add Holiday</button>
        </div>
      </section>

      <section>
        <p className='text-red-500 text-sm'>Note: Adding a calendar is permanent; please add with care as editing isn't available later.</p>
      </section>

      <section className='space-y-5 mt-5'>
        {calendarState.holidays.map((item,i) => (<CustomHoliday index={i} year={calendarState.yearOfCalender || ""} handleChange={handleFeildChange} date={item.date || ""}  leaveType={item.leaveType} occasion={item.occasion}/>))}
      </section>
         
      <section className='flex flex-col justify-end items-end mt-10'>
        <div className='flex'>
          <button className='py-1 px-3 rounded cursor-pointer border border-blue-500 mx-2' onClick={handleSave}>Save</button>
          <button className='py-1 px-3 rounded cursor-pointer border border-blue-500 mx-2' onClick={props.cancel}>Cancel</button>
        </div>
      </section>   
      
    </div>
    </>
  )
}

export default AddCalendar