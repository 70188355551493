import api from "./index";

const fileUploadApi = api.injectEndpoints({
  endpoints: (builder) => ({
    uploadFile: builder.mutation({
      query: () => ({
        url: "/gen/extract-text",
        method: "post",
      }),
    }),
  }),
});


export default fileUploadApi
export const {useUploadFileMutation} = fileUploadApi