const CustomButton = ({ label, onClick, className,...props }) => {
    const buttonStyle = {
     // backgroundColor: '#007bff',
      color: 'white',
      padding: '10px 20px',
      borderRadius: '5px',
      cursor: 'pointer',
    };
  
    return (
      <button
        className={`py-2 px-5 text-blue-primary rounded-md	border border-blue-primary hover:bg-blue-secondery-1  ${
          props.disabled ? "bg-gray-300 text-gray-500 cursor-not-allowed" : ""
        } ${className}`}
        onClick={onClick}
        {...props}
      >
        {label}
      </button>
    );
  };

  export default CustomButton;