import { isValidPhoneNumber } from "react-phone-number-input";
import axios from "axios";
import { cloudApplicationUrl, cloudServiceUrl } from "./utils/apiConfig";
import jwtDecode from "jwt-decode";
import { toast } from "react-toastify";

export const makeid_alphanumeric = (length) => {
  const characters = "abcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  let result = "";

  const array = new Uint8Array(length);
  window.crypto.getRandomValues(array);

  for (let i = 0; i < length; i++) {
    result += characters.charAt(array[i] % charactersLength);
  }

  return result;
};

export const upload_file_to_s3 = async (file) => {
  let tokenExpired = cloudTokenExpiry();
  let cloudServiceToken;
  if (tokenExpired) {
    cloudServiceToken = await loginToCloudService();
  }

  const url = `${cloudServiceUrl}s3/upload`;
  let payload = { file: file };
  return await axios.post(url, payload, {
    headers: {
      Authorization: `Bearer ${
        cloudServiceToken || localStorage.getItem("cloudToken")
      }`,
      Accept: "*/*",
      applicationurl: cloudApplicationUrl,
      "Content-Type": "multipart/form-data",
    },
  });
};

export const get_file_from_s3 = async (file_name) => {
  let tokenExpired = cloudTokenExpiry();
  let cloudServiceToken;
  if (tokenExpired) {
    cloudServiceToken = await loginToCloudService();
  }

  const url = `${cloudServiceUrl}s3/generate/presigned/url`;
  let payload = {
    objectKey: file_name,
  };
  let response = await axios.post(url, payload, {
    headers: {
      Authorization: `Bearer ${
        cloudServiceToken || localStorage.getItem("cloudToken")
      }`,
      applicationurl: cloudApplicationUrl,
    },
  });
  if (response.status === 200) {
    return response?.data?.data;
  }
  toast.error('Failed to fetch the file')
  return "";
};
export const loginToCloudService = async () => {
  try {
    // const url = `${cloudServiceUrl}auth/login`;
    const url = `${cloudServiceUrl}auth/get/token`;
    const payload = {
      userName: "aWN0c3lzdGVtc0BtYWlsaW5hdG9yLmNvbQ==",
      password: "MTIzNDU2Nzg5",
    };
    const response = await axios.post(url, payload);
    if (response.status === 200) {
      localStorage.setItem("cloudToken", response.data?.data);
      return response.data?.data;
    }
  } catch (err) {
    toast.error("cloud Service login failed");
    console.error(err);
  }
};
export const cloudTokenExpiry = () => {
  let cloudToken = localStorage.getItem("cloudToken");
  let decodedToken = null;

  if (cloudToken) {
    decodedToken = jwtDecode(cloudToken);
  } else {
    return true;
  }

  const currentTime = Math.floor(Date.now() / 1000);
  const tokenExpired =
    decodedToken?.exp !== undefined ? decodedToken.exp < currentTime : true;
  return tokenExpired;
};

export const validateMobileNumber = (number) => {
  //if this function returns true, mobile number is valid or else invalid.
  if (!isValidPhoneNumber(number)) {
    return false;
  } else {
    return true;
  }
};
