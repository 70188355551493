import React, { useEffect, useState, useRef } from "react";
import { getToken } from "../services/Token";
import { useCreateProfileQuery } from "../api/profileApi";
import dayjs from "dayjs";
import { clone, cloneDeep } from "lodash";
import {
  useGetAllDapartmentQuery,
  useGetAllDesignationsIdQuery,
  useGetAllHolidayCalenderQuery,
  useGetWorkLocationsQuery,
  useGetAllDesignationsQuery,
} from "../api/addEmployeeApi";

const EditSingleEmployee = () => {
  const Id = getToken("Id");
  const { data, isSuccess } = useCreateProfileQuery({
    employeeId: `${Id}`,
  });
  const [formData, setFormData] = useState({});
  const { data: allDeapartments, isSuccess: isSuccessAllDepartments } =
    useGetAllDapartmentQuery();
  const { data: workLocations, isSuccess: isSuccessWorkLocation } =
    useGetWorkLocationsQuery();
  const { data: allDesignation, isSuccess: isSuccessAllDesignation } =
    useGetAllDesignationsQuery();
  const { data: allHolidayCalender, isSuccess: isSuccessAllHolidayCalender } =
    useGetAllHolidayCalenderQuery();
  const { data: designationIdData, isSuccess: isSuccessDesignationsId } =
    useGetAllDesignationsIdQuery();

  console.log(data, "EMPLOYEEDATA");
  const [department, setDepartment] = useState([]);
  const [designations, setDesignations] = useState([]);
  const [location, setLocation] = useState([]);
  const [holidayCalender, setHolidayCalender] = useState([]);
  const [designationsId, setdesignationsId] = useState([]);

  useEffect(() => {
    if (isSuccessAllDepartments) {
      let newData = cloneDeep(allDeapartments);
      setDepartment(newData);
    }
    if (isSuccessWorkLocation) {
      let newData = cloneDeep(workLocations);
      setLocation(newData);
    }
    if (isSuccessAllDesignation) {
      let newData = cloneDeep(allDesignation);
      setDesignations(newData);
    }
    if (isSuccessAllHolidayCalender) {
      let newData = cloneDeep(allHolidayCalender);
      setHolidayCalender(newData);
    }
    if (isSuccessDesignationsId) {
      let newData = cloneDeep(designationIdData);
      setdesignationsId(newData);
    }
  }, [
    allDeapartments,
    allDesignation,
    workLocations,
    allHolidayCalender,
    designationIdData,
  ]);

  useEffect(() => {
    if (data && department && location && designations && holidayCalender) {
      let emp = cloneDeep(data);
      let newData = matchData(
        emp,
        department,
        location,
        designationsId,
        holidayCalender
      );
      setFormData(newData);
    }
  }, [department, location, designations, holidayCalender, data]);

  function matchData(
    item,
    departmentData,
    workLocationData,
    designationData,
    calendar
  ) {
    const matchedDepartment = departmentData.find(
      (dept) => dept._id === item?.professionalDetails?.department || ""
    );
    if (matchedDepartment) {
      item.professionalDetails.departmentName =
        matchedDepartment.departmentName;
    }
    const matchedWorkLocation = workLocationData.find(
      (loc) => loc._id === item?.professionalDetails?.workLocation || ""
    );
    if (matchedWorkLocation) {
      item.professionalDetails.locationName = matchedWorkLocation.location;
    }
    const matchedDesignation = designationData.find(
      (desig) =>
        desig._id === item?.professionalDetails?.currentDesignation || ""
    );
    if (matchedDesignation) {
      item.professionalDetails.designation = matchedDesignation.designation;
    }
    for (const key in calendar) {
      if (calendar[key] === item?.professionalDetails?.holidayCalender || "") {
        item.professionalDetails.calender = key;
        break;
      }
    }
    return item;
  }

  return (
    <>
      <div className=" mb-10 flex flex-col w-full space-y-5">
        <div>
          <div className="flex flex-col space-y-5">
           <div className="w-[95%] bg-white rounded-xl mx-auto p-3">
              <div>
                <h3 className="text-xl my-2 p-2 border-l-2 border-purple-300 font-semibold">
                  Current Position
                </h3>

                <div className="grid grid-cols-4 p-2 gap-2">
                  <div>
                    <h4 className="text-slate-600">Designation</h4>
                    <p className="my-1 text-lg">
                      {formData?.professionalDetails?.designation
                        ? formData?.professionalDetails?.designation
                        : "-"}
                    </p>
                  </div>
                  <div>
                    <h4 className="text-slate-600">Location</h4>
                    <p className="my-1 text-lg">
                      {formData?.professionalDetails?.locationName
                        ? formData?.professionalDetails?.locationName
                        : "-"}
                    </p>
                  </div>
                  <div>
                    <h4 className="text-slate-600">Department</h4>
                    <p className="my-1 text-lg">
                      {formData?.professionalDetails?.departmentName
                        ? formData?.professionalDetails?.departmentName
                        : "-"}
                    </p>
                  </div>
                  <div>
                    <h4 className="text-slate-600">Holiday Calender</h4>
                    <p className="my-1 text-lg">
                      {formData?.professionalDetails?.calender
                        ? formData?.professionalDetails?.calender
                        : "-"}
                    </p>
                  </div>
                  <div>
                    <h4 className="text-slate-600">Shift</h4>
                    <p className="my-1 text-lg">
                      {formData?.professionalDetails?.shift
                        ? formData?.professionalDetails?.shift
                        : "-"}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            </div>
        </div>
      </div>
    </>
  );
};

export default EditSingleEmployee;
