import React,{useState,useEffect} from 'react'
import axios from 'axios';
import { genericHeaders, serviceUrl } from '../utils/apiConfig';
import { Alert, Snackbar } from "@mui/material";
import NoContent from '../Icons/No-Content-Laptop.svg'  
import LeaveComponent from './LeaveComponent';
import Loader from '../Components/customComponents/customLoader'

const ActiveLeaves = () => {

    const [snack, setSnack] = useState({
      open : false,
      error : false,
      message : ""
    });
    const [loading, setLoading] = useState(false);
    const [leaveData,setLeaveData] = useState([]);
    const [reviewData,setReviewData] = useState([]);
    const [employees,setEmployees] = useState([]);
    const [data,setData] = useState([]);

    const getActiveLeaves = async () => {
      setLoading(true);
      try {
        let response = await axios.get(
          `${serviceUrl}/api/leaveTransaction/leaveTransactionReview`,
          { headers: genericHeaders() }
        );
        if (response.status === 200) {
          setData(response.data);
          setLoading(false);
        }
      } catch (err) {
        setLoading(false);
        handleSnackOpen(true,true,"Something Went Wrong")
        console.error(err);
      }
    };
    const getLeaveData = async() => {
        try{
          let response = await axios.get(`${serviceUrl}/api/leaveCategory/getAllLeaveCategories`,{headers: genericHeaders()});
          if(response.status === 200){
            setLeaveData(response.data);
          }
        }catch(err){
            console.error(err)
        }
    }
    const getEmployees = async() => {
      try{
        let response = await axios.get(`${serviceUrl}/api/employee/getEmployeesList`,{headers: genericHeaders()})
        if(response.status === 200){
          setEmployees(response.data);
        }
      } catch(err){
        console.error(err);
      }
    }
    useEffect(() => {
        getActiveLeaves(); 
        getLeaveData();
        getEmployees();
    }, [])
    useEffect( () => {
        if(data && leaveData){
         let newData = matchData(data,leaveData,employees);
         setReviewData(newData);
        }
     },[data,leaveData,employees])
     const matchData = (employeeLeaves, leaveData, empData) => {
       let data = [];
       if (employeeLeaves && leaveData) {
         let matchedData = employeeLeaves.map( (item) => {
             let matchedLeave = leaveData.find( (leave) => leave._id === item.leaveType);
             if(matchedLeave){
               item.leaveCategory = matchedLeave.leaveCategory;
             }
             let matchedEmployee = empData.find( (emp) => emp.id === item.employeeId);
             if(matchedEmployee){
              item.employeeName = matchedEmployee.name;
             }
             item.role = "Manager"
             item.button = "Approve";
             data.push(item);
         })
       }
       return data;
     };
    const handleSnackOpen = (snackopen,err,msg) => {
      setSnack({
        open : snackopen,
        error : err,
        message : msg
      });
    };
    const handleSnackClose = () => {
      setSnack({
        open : false,
        error : false,
        message : ""
      });
    };
    const handleApprove = async (transId) => {
      setLoading(true);
      try {
        let response = await axios.put(
          `${serviceUrl}/api/leaveTransaction/leaveAccepted?leaveTransactionId=${transId}`,
          null,
          { headers: genericHeaders() }
        );
        if (response.status === 204) {
          setLoading(false);
          getActiveLeaves();
          handleSnackOpen(true,false,"Leave Approved Succesfully");
        }
      } catch (err) {
        setLoading(false);
        handleSnackOpen(true,true,"Something Went Wrong");
        console.error(err);
      }
    };
    const handleReject = async (transId) => {
      setLoading(true);
      try{
        let response = await axios.put(`${serviceUrl}/api/leaveTransaction/leaveRejected?leaveTransactionId=${transId}`,null,{ headers: genericHeaders() });
        if(response.status === 204){
          getActiveLeaves();
          handleSnackOpen(true,false,"Leave Rejected Successfully");
          setLoading(false);
        }
      }catch(err){
         setLoading(false);
         handleSnackOpen(true,true,"Something Went Wrong");
        console.error(err);
      }
    }
  return (
    <div>
      {loading && (<Loader/>)}
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        open={snack.open}
        autoHideDuration={3000}
        onClose={handleSnackClose}
      >
        <Alert severity={snack.error ? "error" : "success"} variant="filled" onClose={handleSnackClose}>
          {snack.message}
        </Alert>
      </Snackbar>
      {reviewData.length > 0 ? (
          reviewData.map((item) => (
            <LeaveComponent data={item} approve={handleApprove} reject={handleReject}/>
          ))
        ) : (
          <div className="mt-[15%] flex flex-col items-center justify-center">
            <img src={NoContent} />
            <p className='text-xl'>There are no pending records of any leave transaction</p>
          </div>
        )}
    </div>
  );
}

export default ActiveLeaves