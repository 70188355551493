import React, { useEffect } from "react";
import {
  BrowserRouter,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import ManageEmployee from "./Components/Manage-Employees";
import Login from "./Components/Login";
import AddEmployee from "./Components/AddEmployee";
import Layout from "./Components/Layout";
import ManageLookups from "./Components/ManageLookups";
import { Provider,useDispatch } from "react-redux";
import store from "./store";
import Profile from "./Components/Profile";
import { Buffer } from "buffer";
import Posts from "./Components/redux-toolkit-examples/Posts";
import Dashboard from "./Components/Dashboard";
import { clearCahce } from "./services/Token";
import RegistrationScreen from "./Components/registration";
import LeaveBalance from "./Components/LeaveBalance";
import AddEmployee0 from "./pages/addEmployee";
import LeavesPending from "./Components/LeavesPending";
import LeavesHistory from "./Components/LeavesHistory";
import Leavepolicy from "./pages/Leave/Leavepolicy";
import LeaveTabs from "./Components/LeaveTabs";
import LeaveReview from "./Components/LeaveReview";
import NavList from "./pages/EmployeeDetails";
import EditEmployee from "./Components/EditEmployee";
import LetterTemplateMain from "./Components/letterTemplates";
import GenerateLetter from "./Components/letterTemplates/generateLetter";
import EmployeesAllLetters from "./Components/letterTemplates/employeesAllLetters";
import EmployeeLetters from "./Components/letterTemplates/individualEmpLetters";
import TrainingModules from "./Components/TrainingModules";
import { clearErrorState, updateEmployeeJson,updateFeildsDisabled , clearErrorStateWork } from "./store/reducers/addEmployeeSlice";
import employeeJson from "./pages/addEmployee/addEmployee.json"
import ResetScreen from "./Components/ResetScreen";
import ResetPassword from "./Components/ResetPassword"
import TenatDashboard from "./Components/SuperAdmin/TenatDashboard";
import { updateErrorMessage } from "./store/reducers/errorMessageSlice";
import { getToken } from "./services/Token";
import HolidayCalendar from "./Components/holidayCalendar";
import { clearCalendar } from "./store/reducers/calendarSlice";
import WorkLog from "./Components/workLog/WorkLog3";
import AdminWorkLog from "./Components/workLog/AdminWorkLog";
import ProjectsAssignForManager from "./Components/workLog/ProjectsAssignForManager";
import EmployeeCalendar from "./Components/holidayCalendar/EmployeeCalendar";
import Payroll from "./Components/Payroll";
import ProcessPayroll from "./Components/ProcessPayroll";
import PaySlipScreen from "./Components/PaySlipScreen";
import AttendanceInfo from "./Components/Attendance/AttendanceInfo";      
import ManagerAttendaceCard from "./Components/Attendance/ManagerAttendaceCard";
import EmployeeTracking from "./Components/EmployeePunchTracking/EmployeeTracking";
import CambriForm from "./CambriForm/CambriForm";
import FormData from "./CambriForm/FormData";
import jwtDecode from "jwt-decode";

  const timeStringToTimestamp = (timeString) => {
    const [hours, minutes, seconds] = timeString?.split(":").map(Number) || [
      0, 0, 0,
    ];
    return (hours * 3600 + minutes * 60 + seconds) * 1000;
  };

  const LayoutBar = ({ component: Component, ...rest }) => {
    const navigate = useNavigate();
    const isLogin = localStorage.getItem("isLogin");
    const expiryTimeMs = timeStringToTimestamp(
      localStorage.getItem("expiryTime")
    );
    const currentDate = new Date();
    // Create a new date object for the start of the current day
    const startOfDay = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate()
    );
    // Calculate the elapsed time for the current day in milliseconds
    const currentTimeMs = currentDate.getTime() - startOfDay.getTime();
    useEffect(() => {
      if (!isLogin) {
        navigate("/login");
        clearCahce();
        return;
      }
    }, []);
    const dispatch = useDispatch();
    const location = useLocation();

    useEffect(() => {
      if(location.pathname !== '/addEmployee0'){
        dispatch(updateEmployeeJson(employeeJson));
        dispatch(clearErrorState({ step0: {}, step1: {}, step2: {}, step4:{} }));
        dispatch(clearErrorStateWork({ step0: {}, step1: {}, step2: {}, step3:{} }));
        dispatch(updateErrorMessage(false));
      }
      if (!location.pathname.includes('/view/')) {
        dispatch(updateFeildsDisabled(false));
      }
      if(location.pathname !== '/holidayCalendar'){
        dispatch(clearCalendar());
      }
    })

    let token = getToken('Token');
  
    const checkExpiry = () => {
      let timeRef = null;
      if(token){
        const decoded = jwtDecode(token);
        const expiryTime = decoded.exp ? (new Date(decoded.exp * 1000)).getTime() : 0;
        const currentTime = (new Date()).getTime();
        const timeout = expiryTime - currentTime;
        const onExpiry = () => {
          navigate("/login?sessionExpired=true");
          localStorage.clear();
        }
        if(timeout > 0){
          timeRef = setTimeout(onExpiry,timeout)
        }else{
          onExpiry()
        }
        return () => {
          if (timeRef) clearTimeout(timeRef);
        };
      }
    }

    checkExpiry();

    return (
      <div>
        <Layout>
          <Component {...rest} />
        </Layout>
      </div>
    );
  };

  const AuthGuardSuperAdmin = ({ children }) => {
    const navigate = useNavigate();
    const role = getToken("roles");
    
    useEffect(() => {
      if (!(role === "SUPERADMIN")) {
        navigate("/login");
      }
    }, [role, navigate]);
  
    return role === "SUPERADMIN" ? children : null;
  };

  function App() {
    window.Buffer = Buffer;
    return (
      <Provider store={store}>
        <BrowserRouter>
          <Routes>
            <Route index element={<Login />} />
            <Route path="/login" element={<Login />} />
            <Route
              path="/manage"
              element={<LayoutBar component={ManageEmployee} />}
            />
            <Route path="/registration" element={<RegistrationScreen />} />
            <Route
              path="/addemployee"
              element={<LayoutBar component={AddEmployee} />}
            />
            <Route
              path="/managelookups"
              element={<LayoutBar component={ManageLookups} />}
            />
            <Route
              path="/editprofile"
              element={<LayoutBar component={Profile} />}
            />
            <Route path="/posts" element={<LayoutBar component={Posts} />} />
            <Route
              path="/dashboard"
              element={<LayoutBar component={Dashboard} />}
            />
            <Route
              path="/leavebalance"
              element={<LayoutBar component={LeaveBalance} />}
            />
            <Route
              path="/addEmployee0"
              element={<LayoutBar component={AddEmployee0} />}
            />
            <Route
              path="/pending"
              element={<LayoutBar component={LeavesPending} />}
            />
            <Route
              path="/history"
              element={<LayoutBar component={LeavesHistory} />}
            />
            <Route
              path="/leavepolicy"
              element={<LayoutBar component={Leavepolicy} />}
            />
            <Route
              path="/leaves"
              element={<LayoutBar component={LeaveTabs} />}
            />
            <Route
              path="/review"
              element={<LayoutBar component={LeaveReview} />}
            />
            <Route
              path="/profile"
              element={<LayoutBar component={NavList} />}
            />
            <Route
              path="/edit"
              element={<LayoutBar component={EditEmployee} />}
            />
            <Route
              path="/edit/:employeeId"
              element={<LayoutBar component={EditEmployee} />}
            />
            <Route
              path="/view/:empId"
              element={<LayoutBar component={AddEmployee0} />}
            />
            <Route
              path="/lettertemplate"
              element={<LayoutBar component={LetterTemplateMain} />}
            />
            <Route
              path="/generate"
              element={<LayoutBar component={GenerateLetter} />}
            />
            <Route
              path="/letters"
              element={<LayoutBar component={EmployeesAllLetters} />}
            />
            <Route
              path="/employeeletters"
              element={<LayoutBar component={EmployeeLetters} />}
            />
            <Route
              path="/trainingModules"
              element={<LayoutBar component={TrainingModules} />}
            />
            <Route
              path="/holidayCalendar"
              element={<LayoutBar component={HolidayCalendar} />}
            />
            <Route
              path="/userHolidayCalendar"
              element={<LayoutBar component={EmployeeCalendar} />}
            />
            <Route
              path="/workLog"
              element={<LayoutBar component={WorkLog} />}
            />
            <Route
              path="/worklogadmin"
              element={<LayoutBar component={AdminWorkLog} />}
            />
            <Route
              path="/assignprojects"
              element={<LayoutBar component={ProjectsAssignForManager} />}
            />
            <Route path="/reset" element={<ResetScreen />} />
            <Route path="/resetpwd" element={<ResetPassword />} />
            <Route
              path="/superadmin"
              element={
                <AuthGuardSuperAdmin>
                  <TenatDashboard />
                </AuthGuardSuperAdmin>
              }
            />
            <Route
              path="/payrollProcessing"
              element={<LayoutBar component={ProcessPayroll} />}
            />
            <Route
              path="/payslip"
              element={<LayoutBar component={PaySlipScreen} />}
            />
            <Route
              path="/payroll"
              element={<LayoutBar component={Payroll} />}
            />
            <Route
              path="/attendance"
              element={<LayoutBar component={AttendanceInfo} />}
            />
            <Route
              path="/attendanceinfo"
              element={<LayoutBar component={ManagerAttendaceCard} />}
            />
            <Route
              path={'/employeetracking'}
              element={<LayoutBar component={EmployeeTracking}/>}
            />
            <Route
              path={'/cambriregistration'}
              element={<CambriForm/>}
            />
            {/* <Route
              path={'/cambriregistration/data'}
              element={<FormData/>}
            /> */}
          </Routes>
        </BrowserRouter>
      </Provider>
    );
  }

  export default App;
